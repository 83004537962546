import { useQuery } from '@tanstack/react-query';
import { getEstimatedRepairCosts } from '../../../api/EstimatedRepairCosts/getEstimatedRepairCosts';

export default function useGetEstimatedRepairCosts(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getEstimatedRepairCosts', propertyId ],
    queryFn: () => getEstimatedRepairCosts(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    console.error('There was an error in useGetEstimatedRepairCosts ', getQuery.error.message);
  }

  return {
    estimatedRepairCosts: getQuery.data?.data || null,
    errorEstimatedRepairCosts: getQuery.isError ? getQuery.error : null,
    isLoadingEstimatedRepairCosts: getQuery.isLoading,
  };
}