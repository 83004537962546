import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AvmMapState {
  focusedIndex: {index: number, isActive: boolean};
  maintainFocus: boolean;
}

const initialState: AvmMapState = {
  focusedIndex: null,
  maintainFocus: false
};

const avmMapSlice = createSlice({
  name: 'avmMap',
  initialState,
  reducers: {
    setFocusedIndex: (state, action: PayloadAction<{index: number, isActive: boolean}>) => {
      state.focusedIndex = action.payload;
    },
    maintainFocus: (state) => {
      state.maintainFocus = true;
    },
    abandonFocus: (state) => {
      state.maintainFocus = false;
    }
  }
});

export const { setFocusedIndex, maintainFocus, abandonFocus } = avmMapSlice.actions;

export default avmMapSlice.reducer;