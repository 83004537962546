import './common/SideBySideDivs.css'; // Import CSS file for styling
import React, { type FC, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import PropertiesVisualizer from './PropertiesVisualizer';
import SearchCriteria from './propertySearch/SearchCriteria';
import { PropertiesViewAppProps } from '../utility/types';
import { PropertyViewerContextProvider } from '../contexts/PropertyViewer';
import SortingSummary from './summaries/SortingSummary';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Button } from '@mui/material';
import { AppSettingsProvider, useAppSettingsContext } from '../contexts/AppSettings';
import { FilteringProvider, useFilteringContext } from '../contexts/Filtering';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { twMerge } from 'tailwind-merge';
import { AppDispatch, store } from '../redux/store';
import { Provider } from 'react-redux';
import { enableMapSet } from 'immer';
import { useDispatch } from 'react-redux';
import { fetchFavoritesThunk } from '../redux/state/favoriteSlice';
import { fetchLspjs } from '../redux/state/lspjSlice';
import { fetchColumns } from '../redux/state/columnsSlice';
import { setMapKey } from '../redux/state/mapSlice';
enableMapSet();

// Create a client
const queryClient = new QueryClient();

const PropertiesViewApp: FC<PropertiesViewAppProps> = ({ mapKey, listSortId }) => {
  return (
    <Provider store={store}>
      <PrimeReactProvider value={{ unstyled: false, pt: Tailwind, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge } }}>
        <QueryClientProvider client={queryClient}>
          <PropertyViewerContextProvider listSortId={listSortId}>
            <FilteringProvider>   {/* <-- Handle search filtering criteria */}
              <AppSettingsProvider>
                <PropertiesViewAppChild mapKey={mapKey} listSortId={listSortId} />
              </AppSettingsProvider>
            </FilteringProvider>
          </PropertyViewerContextProvider>
        </QueryClientProvider>
      </PrimeReactProvider>
    </Provider>
  );
};

const PropertiesViewAppChild: FC<PropertiesViewAppProps> = ({ mapKey, listSortId }) => {
  const [ showSortSummary, setShowSortSummary ] = useState(false);
  const [ drawerToggle, setDrawerToggle ] = useState({ right: false });
  const { isFilteringActive } = useFilteringContext();
  const { isMapView, setIsMapView } = useAppSettingsContext();
  const [ tabName, setTabName ] = useState('Set Search Criteria');
  const dispatch = useDispatch<AppDispatch>();
  const toggleView = () => {
    setIsMapView(prev => !prev);
  };

  useEffect(() => {
    // run initial fetch favorites to populate redux store
    dispatch(fetchFavoritesThunk());
    dispatch(fetchLspjs(listSortId));
    dispatch(fetchColumns());
    dispatch(setMapKey(mapKey));
  }, []);

  const toggleDrawer = (anchor: any, open: boolean, tabName: string = 'Set Search Criteria') =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerToggle({ ...drawerToggle, [anchor]: open });
      setTabName(tabName);
    };

  return (
    <>
      <header id='PropertiesViewApp-header' className="relative isolate z-10 bg-white">
        <nav className="w-full flex items-center justify-between px-6 pb-6 lg:pl-0 pr-16" aria-label="Global">
          <div>
            <button
              type="button"
              className="rounded bg-indigo-600 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={toggleView}
            >
                Switch to {isMapView ? 'List' : 'Map'} View
            </button>
            { listSortId &&
              <a href={`/list-sorts/decision-making?id=${listSortId}`} className="text-sm font-semibold ml-2 leading-6 text-gray-900">
                Go to decision making <span aria-hidden="true">→</span>
              </a>
            }
          </div>

          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <Popover>
              {({ open }) => (
                <>
                  <Button onClick={toggleDrawer('right', true, 'Search Criteria')}>
                    { isFilteringActive ? 'Change Search Criteria' : 'Set Search Criteria' }
                  </Button>
                  <Button onClick={toggleDrawer('right', true, 'Table Settings')}>
                    {/*  Helen TODO REIX-273 -> (TODO set correct tab to open). Also make second click, close this box */}
                    Table Settings
                  </Button>
                  <Router>
                    <SearchCriteria
                      tabName={tabName}
                      drawerToggle={drawerToggle}
                      toggleDrawer={toggleDrawer}
                    />
                  </Router>
                </>)}
            </Popover>

            <Button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={() => setShowSortSummary((prev) => !prev)}>
                Sorting Summary
            </Button>
          </Popover.Group>
        </nav>
      </header>
      <div id="PropertiesViewApp-container-summary-and-viewer" className="flex border border-gray-300">
        {showSortSummary && <div id='PropertiesSortSummary-container' className='w-1/2'>
          <SortingSummary showListSortSortSummary={showSortSummary} setShowListSortSortSummary={setShowSortSummary} listSortId={listSortId}/> :
        </div>}
        <div className={showSortSummary ? 'w-1/2' : 'w-full'}>
          {/* Note: Will most likely need to change how this works in the future. Discuss with team */}
          <PropertiesVisualizer isMapView={isMapView} listSortId={listSortId}/>
        </div>
      </div>
    </>
  );

};

export default PropertiesViewApp;
