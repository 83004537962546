import React from 'react';
import { Button } from '@mui/material';
import { MultiSelect } from 'primereact/multiselect';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { resetColumns, toggleColumnVisibility } from '../../../../redux/state/columnsSlice';
import { useDispatch } from 'react-redux';

const ColumnsToggle: React.FC<any> = ({}) => {
  const { columns, originalColumns } = useSelector((state: RootState) => state.columns);
  const dispatch = useDispatch<AppDispatch>();

  const onColumnToggle = (event) => {
    dispatch(toggleColumnVisibility(event.value.map(c => c.field)));
  };

  return (
    <div className="rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
      <div >Visible Columns Toggle</div>
      <Button onClick={()=>dispatch(resetColumns())}>Reset</Button>
      <MultiSelect
        value={columns.filter(c => c.visible && c.field !== 'property_id')}
        options={originalColumns.filter(c => c.field !== 'property_id')}
        optionLabel="header"
        onChange={onColumnToggle}
        className="!w-full "
        display="chip"
        filter
      />
    </div>
  );
};

export default ColumnsToggle;
