
import React, { useState, useEffect, type FC } from 'react';
import SearchDropdown from '../common/SearchDropdown';
import RadioButtonRowSelect from '../common/RadioButtonRowSelect';
import { PropertyMapperProps } from '../../utility/types';

const PropertyMapper: FC<PropertyMapperProps> = ({ csvHeaders, existingMapping, mappableFieldsJson, formId }) => {
  const [ existingMappingObj, _setExistingMapping ] = useState(JSON.parse(existingMapping));
  const [ mappingObject, setMappingObject ] = useState(existingMappingObj);
  const [ csvHeaderObject, _setCSV ] = useState(JSON.parse(csvHeaders));
  const mappableFields = JSON.parse(mappableFieldsJson);
  const addressTypes = [ 'Seperate', 'Full Street', 'Complete Address' ];

  const setSelectedAddressType = () => {
    if (existingMappingObj.street) {
      return 'Full Street';
    }
    if (existingMappingObj.complete_address) {
      return 'Complete Address';
    }
    return 'Seperate';
  };

  const [ addressType, setAddressType ] = useState(setSelectedAddressType());
  const seperateSkippableKeys = [ 'complete_address', 'street' ];
  const fullStreetSkippableKeys = [ 'complete_address', 'street_number', 'direction', 'street_name', 'unit_number' ];
  const completeAddressSkippableKeys = [ 'street', 'street_number', 'direction', 'street_name', 'unit_number', 'street_line_two', 'city', 'state', 'zipcode', 'county', 'country' ];

  const filteredMappableKeys = Object.keys(mappableFields).filter((key) => {
    if (addressType == 'Seperate') {
      return !seperateSkippableKeys.includes(key);
    }
    if (addressType == 'Complete Address') {
      return !completeAddressSkippableKeys.includes(key);
    }
    if (addressType == 'Full Street') {
      return !fullStreetSkippableKeys.includes(key);
    }
  });

  const updateMapping = (mapping) => {
    const filteredMapping = {};
    filteredMappableKeys.forEach(key => {
      filteredMapping[key] = mapping[key];
    });
    const hiddenInput = document.getElementById(formId) as HTMLInputElement;
    hiddenInput.value = JSON.stringify(filteredMapping);
  };

  useEffect(() => { updateMapping(mappingObject); }, [ mappingObject ]);

  return (
    <>
      <div className='flex my-6 justify-center'>
        <RadioButtonRowSelect
          title='Address Type'
          subtitle='Select the type of address used in your csv'
          options={addressTypes}
          selected={addressType}
          onSelect={(selectedType) => {
            setAddressType(selectedType);
          }}
        />
      </div>
      {filteredMappableKeys.map(key =>
        <>
          { key === 'bedrooms' &&
            <div className='border-t border-gray-300 mt-10 pt-5 pb-3'>
              <h3 className='block text-sm font-medium leading-6 text-gray-900'>Property Details</h3>
              <p className="mt-1 text-sm leading-6 text-gray-600">Add property detail mappings for non mls properties</p>
            </div>
          }
          { key === 'auction_number' &&
            <div className='border-t border-gray-300 mt-10 pt-5 pb-3'>
              <h3 className='block text-sm font-medium leading-6 text-gray-900'>Other Property Details</h3>
              <p className="mt-1 text-sm leading-6 text-gray-600">Add property details specific to this list</p>
            </div>
          }
          <div className='flex justify-between items-baseline'>
            <h3 className='block text-sm font-medium leading-6 text-gray-900'>{mappableFields[key]}</h3>
            <SearchDropdown key={key} initialValue={existingMappingObj[key]} selections={Object.keys(csvHeaderObject).map(key => ({ key: key, subtitle: csvHeaderObject[key] }))} onSelect={(selected: string) => {
              if (selected || selected === '') {
                setMappingObject(mappingObject => {
                  mappingObject[key] = selected;
                  if (selected === '') { delete mappingObject[key]; }
                  return {
                    ...mappingObject
                  };
                });
              }
            }} />
          </div>
        </>
      )}
    </>
  );
};

export default PropertyMapper;
