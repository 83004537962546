import React, { type FC } from 'react';
import WantedUnwantedToggle from './WantedUnwantedToggle';
import { DocumentTextIcon, PencilSquareIcon, PresentationChartBarIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'primereact/tooltip';
import FavoriteToggle from '../common/FavoriteToggle';

export type DecisionMakingPropertyHeaderProps = {
  propertyId: string;
  savedWantedStatus: boolean;
  setIsQuickNetModalOpen: (boolean) => void;
  setIsPropertyCommentsModalOpen: (boolean) => void;
  onWantedStatusChange: (boolean) => void;
  promptSaveChanges: boolean;
}

const DecisionMakingPropertyHeader: FC<DecisionMakingPropertyHeaderProps> = ({
  propertyId,
  setIsQuickNetModalOpen,
  setIsPropertyCommentsModalOpen,
  promptSaveChanges,
  savedWantedStatus,
  onWantedStatusChange
}) => {
  return (
    <div id='DecisionMakingPropertyHeader-container' className="mb-6 mt-4 px-3 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 items-center">
        <div className="flex flex-col lg:flex-row gap-x-5 items-center align-middle justify-center md:justify-start w-full">
          <div className='sm:mt-3'>
            <WantedUnwantedToggle
              savedWantedStatus={savedWantedStatus} 
              onWantedStatusChange={onWantedStatusChange} 
              propertyId={propertyId}
            />
          </div>
        </div>
        <div className='flex items-center align-middle lg:justify-end w-full justify-center md:mt-3 mr-5'>
          {promptSaveChanges && (<div id='save-changes-button-container'
            onClick={() => setIsQuickNetModalOpen(true)}
            className='flex z-10 items-center cursor-pointer mr-1 px-2'
          >
            <Tooltip target='.doc-icon' className='bg-grey-500 border border-solid border-1 border-indigo-900 px-2 rounded-lg m-5 text-xs' />
            <PresentationChartBarIcon
              data-pr-tooltip="Save Changes"
              data-pr-position="bottom"
              data-pr-at="left+1 bottom"
              data-pr-my="bottom center-2"
              className='doc-icon w-7'
            />
            <div>Save Changes</div>
          </div>)}
          <div id='DecisionMakingPropertyHeader-favorite-toggle' className='cursor-pointer mr-3 items-center'>
            <FavoriteToggle
              propertyId={propertyId}
            />
          </div>
          <div id='DecisionMakingPropertyHeader-quick-net-sheet-button' onClick={() => setIsQuickNetModalOpen(true)} className='flex z-10 items-center cursor-pointer mr-1'>
            <Tooltip target='.doc-icon' className='bg-grey-500 border border-solid border-1 border-indigo-900 px-2 rounded-lg m-5 text-xs' />
            <DocumentTextIcon
              data-pr-tooltip="Quick Net Sheet"
              data-pr-position="bottom"
              data-pr-at="left+1 bottom"
              data-pr-my="bottom center-2"
              className='doc-icon w-7'
            />
          </div>
          <div id='DecisionMakingPropertyHeader-property-comments-button' onClick={() => setIsPropertyCommentsModalOpen(true)} className='flex z-10 items-center cursor-pointer ml-1'>
            <Tooltip target='.pencil-icon' className='bg-grey-500 border border-solid border-1 border-indigo-900 px-2 rounded-lg m-5 text-xs' />
            <PencilSquareIcon
              data-pr-tooltip="Property Notes"
              data-pr-position="bottom"
              data-pr-at="left+1 bottom"
              data-pr-my="bottom center-2"
              className='doc-icon w-7'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DecisionMakingPropertyHeader;
