import React, { type FC } from 'react';

interface ViewHeaderProps {
  title: string;
}

const ViewHeader: FC<ViewHeaderProps> = ({ title }) => {
  return (
    <div className='flex whitespace-nowrap items-center px-3'>
      <div>
        <p className='uppercase text-2xl text-bold tracking-wide text-indigo-500'>{title}</p>
      </div>
      <div className='w-full h-fit ml-5'>
        <hr />
      </div>
    </div>
  );
};

export default ViewHeader;
