import React, { FC, Fragment } from 'react';
import { classNames } from '../../../utility/utility';
import useGetPropertyListUploadsProperties from '../../../hooks/api/Property/useGetPropertyListUploadsProperties';
import { Loader } from '../../icons/OurIcons';

const locations = [
  {
    list_name: 'December 2023 Sold Data',
    people: [
      { column: 'Auction Number', data: '3' }
    ],
  },
  {
    list_name: 'Super small properties test',
    people: [
      { column: 'Auction Number', data: 'a1' }
    ],
  }
];

export type CustomListDataViewerProps = {
  propertyId: string;
};

const CustomListDataViewer: FC<CustomListDataViewerProps> = ({ propertyId }) => {
  const { listUploadsProperties, refetch: refetchGetListUploadsProperties, errorListUploadsProperties, isLoading: loadingListUploadsProperties } = useGetPropertyListUploadsProperties(propertyId);

  const listUploadMapping = {
    'Auction Number': 'auction_number',
    'Order Number': 'order_number'
  };

  const renderListUploadsProperties = () => {
    if (loadingListUploadsProperties) {
      return <Loader />;
    }

    if (listUploadsProperties.length === 0) {
      return (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <p className='text-sm text-gray-700'>No list upload data associated with this property</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Column Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Data
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {listUploadsProperties.map((list_join: any) => {
                  return (
                    <Fragment key={list_join.list_upload.list_name}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          List: {list_join.list_upload.list_name}
                        </th>
                      </tr>
                      {Object.keys(listUploadMapping).map((mapKey, mapIdx) => (
                        <tr
                          key={mapKey}
                          className={classNames(mapIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {mapKey}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{list_join[listUploadMapping[mapKey]]}</td>
                        </tr>
                      ))}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-24 sm:px-30 lg:px-32">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">My Lists Data</h1>
          <p className="mt-2 text-sm text-gray-700">
            Data uploaded and mapped via list uploads
          </p>
        </div>
      </div>
      { renderListUploadsProperties() }
    </div>
  );
};

export default CustomListDataViewer;
