import React, { type FC } from 'react';
import Table from '../common/Table';
import { ListUploadTableProps } from '../../utility/types';

const ListUploadTable: FC<ListUploadTableProps> = ({ listUploadsJson }) => {
  const listUploads = JSON.parse(listUploadsJson);

  return (
    <Table
      objectName='List Uploads'
      subtitle='Upload lists of properties that you would like to run through the sorting process'
      tableData={listUploads.map(upload => ({
        'List Name': upload.list_name,
        'id': upload.id,
        'Number of rows': upload.number_of_rows,
        'Finished parsing': upload.parsed,
        'Status': upload.status,
        'Last sync\'d': upload.last_sync_date
      }))}
      basePath='/list-uploads'
      returnTitle='List Sorts'
      returnPath='/list-sorts'
    />
  );
};

export default ListUploadTable;
