import { useEffect, useState, type FC } from 'react';
import React from 'react';
import { classNames } from '../../utility/utility';

export type WantedUnwantedToggleProps = {
  savedWantedStatus: any;
  propertyId: string;
  onWantedStatusChange: (boolean) => void;
}

const WantedUnwantedToggle: FC<WantedUnwantedToggleProps> = ({ savedWantedStatus, onWantedStatusChange, propertyId }) => {
  const [ selectedWantedStatus, setSelectedWantedStatus ] = useState(null);

  useEffect(() => {
    if (savedWantedStatus === true || savedWantedStatus === false) {
      setSelectedWantedStatus(savedWantedStatus);
    } else {
      setSelectedWantedStatus(null);
    }
  }, [ savedWantedStatus, propertyId ]);

  useEffect(() => {
    onWantedStatusChange(selectedWantedStatus);
  }, [ selectedWantedStatus ]);

  return (
    <div className="relative mt-1">
      <div className="flex justify-between items-center">
        <div>
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              onClick={() => {
                setSelectedWantedStatus(true);
              }}
              type="button"
              className={classNames(
                'relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold border-r-none ring-1 ring-inset ring-gray-300 focus:z-10 hover:cursor-default',
                selectedWantedStatus ? 'bg-indigo-600 text-white' : 'bg-white text-gray-900 hover:bg-gray-100 hover:ring-indigo-600 hover:cursor-pointer'
              )}
            >
              Wanted
            </button>
            <button
              onClick={() => {
                setSelectedWantedStatus(false);
              }}
              type="button"
              className={classNames(
                'relative inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10',
                selectedWantedStatus === false ? 'bg-indigo-600 text-white' : 'bg-white text-gray-900 hover:bg-gray-100 hover:ring-indigo-600 hover:cursor-pointer'
              )}
            >
              Unwanted
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default WantedUnwantedToggle;
