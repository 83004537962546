import React, { type FC, useState } from 'react';
import { deletePropertyComments } from '../../../../api/Property/deletePropertyComment';
import ConfirmationPage from '../../../configurations/approaches/ConfirmationPage';
import { Image } from 'primereact/image';

type EditPropertyCommentsViewProps = {
  propertyId: string;
  commentData: any;
  formData: any;
  setIsEdit: (boolean) => void;
  refetchCommentData: () => void;
  setIsPropertyCommentsModalOpen: (boolean) => void;
}

const EditPropertyCommentsView: FC<EditPropertyCommentsViewProps> = ({ setIsPropertyCommentsModalOpen, propertyId, setIsEdit, commentData, formData, refetchCommentData }) => {
  const [ showConfirmationPage, setShowConfirmationPage ] = useState(false);

  const handleDeletePropertyComment = async () => {
    try {
      await deletePropertyComments(propertyId, commentData?.comment?.id);
      await refetchCommentData();
      setIsPropertyCommentsModalOpen(false);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  if (showConfirmationPage) {
    return (
      <ConfirmationPage
        onCancel={() => {setShowConfirmationPage(false);}}
        onConfirm={() => {
          handleDeletePropertyComment();
          setShowConfirmationPage(false);
        }}
        confirmationText={`Confirm you want to delete this property comment: ${commentData?.comment?.title}`}
        confirmationButtonText='Delete'
      />
    );
  }

  return (
    <div>
      <div className='flex flex-wrap sm:items-center justify-between gap-2 mb-5'>
        <span className='font-bold text-xl whitespace-nowrap'>My Property Comments</span>
        <div className='flex-none mt-2 sm:mt-0'>
          <button type='button' onClick={() => setIsEdit(true)} className='rounded bg-indigo-600 py-1 px-4 font-semibold text-white shadow-md hover:bg-indigo-500 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-indigo-100'>
            {commentData?.comment?.id ? 'Edit' : 'Add Comment'}
          </button>
        </div>
      </div>
      <hr className='mb-5' />
      <div className='card justify-center overflow-y-scroll'>
        <div className='justify-center'>
          <div className='mb-5 rounded-xl px-3 justify-center'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-8 w-contain align-middle justify-center rounded-xl'>
              <div className='row-span-1'>
                <div className='pt-2'>
                  <label className='font-semibold'>Title</label>
                </div>
                <div>
                  <p>{formData?.title}</p>
                </div>
                <div className='w-contain mt-3 whitespace-pre-line text-wrap whitespace-normal text-pretty'>
                  <label className='font-semibold'>Notes</label>
                  <p className='text-base'>{formData?.notes}</p>
                </div>
              </div>
              <div className='row-span-3 mt-3 md:mt-0'>
                <h3 className='font-semibold'>My Property Photos</h3>
                {formData?.photos?.length > 0 && (
                  <div className='mt-2 grid grid-cols-3 gap-3 card flex justify-content-center overflow-y-scroll max-h-80'>
                    {formData?.photos?.map((file, index) => (
                      <Image className='w-contain h-full' key={index} src={file?.image_url} zoomSrc={file?.image_url} preview />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center py-8 sticky bottom-0 bg-white'>
        <button onClick={() => setIsPropertyCommentsModalOpen(false)} className='rounded bg-indigo-600 mr-3 px-2 py-1 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
          Cancel
        </button>
        {commentData?.comment?.id && 
          <button onClick={() => setShowConfirmationPage(true)} className='rounded bg-indigo-600 mr-3 px-2 py-1 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            Delete
          </button>
        }
      </div>
    </div>
  );
};

export default EditPropertyCommentsView;
