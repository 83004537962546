import React from 'react';
import { BooleanTriStateSelectorComponentProps } from './utility';

// The generic TriStateSelectorType component
const BooleanTriStateSelectorComponent: React.FC<BooleanTriStateSelectorComponentProps> = ({
  id,
  values,
  defaultValues,
  title,
  icons,
  onUpdate,
}) => {
  const handleClick = () => {
    // Calculate new values.state based on current values.state
    let newValue: true | false | null;
    switch (values.state) {
    case true:
      newValue = false;
      break;
    case false:
      newValue = null;
      break;
    default:
      newValue = true;
      break;
    }
    onUpdate(id, { state: newValue });
  };

  // Function to determine which icon to display
  const renderIcon = () => {
    switch (values.state) {
    case true:
      return icons.active;
    case false:
      return icons.inactive;
    default:
      return icons.indeterminate;
    }
  };

  return (
    <div key={`BooleanTriStateSelectorComponent-${id}`} className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md flex justify-content-center">
      <button onClick={handleClick} aria-label={`Toggle ${title}`}>
        {renderIcon()}
      </button>
    </div>
  );
};

export default BooleanTriStateSelectorComponent;
