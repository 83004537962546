
import React from 'react';
import { useFilteringContext } from '../../../contexts/Filtering';
import FilterFactory from './filters/FilterFactory';

const FullAddressSearch = ({ }) => {
  const { state } = useFilteringContext();
  const id = 'fullAddress';
  const filter = state.filters[id];
  if (!filter) {
    return null;
  }

  return (
    <div className="flex justify-center items-center">
      <FilterFactory id={id} filterData={filter} />
    </div>
  );
};

export default FullAddressSearch;
