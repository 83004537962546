
import React, { useState, useEffect } from 'react';
import { useAppSettingsContext } from '../../contexts/AppSettings';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { SettingsCard } from './Components/SettingsCard';

export function ShouldShowGridLines() {
  const { shouldShowGridLines: checked, setShouldShowGridLines: setChecked } = useAppSettingsContext();

  return (
    <SettingsCard title="Grid Lines">
      <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
      <label htmlFor="shouldShowGridLines" className="ml-2">Show Grid Lines</label>
    </SettingsCard>
  );
}

export function ShouldShowStripedRows() {
  const { shouldShowStripedRows: checked, setShouldShowStripedRows: setChecked } = useAppSettingsContext();

  return (
    <SettingsCard title="Striped Rows">
      <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
      <label htmlFor="shouldShowStripedRows" className="ml-2">Show Striped Rows</label>
    </SettingsCard>
  );
}

export type PaginationPerPageOptions = 10 | 25 | 50 | 100 | 500;

export function PaginationPerPage() {
  const { paginationPerPageCount, setPaginationPerPageCount } = useAppSettingsContext();

  const options = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '500', value: 500 },
  ];

  const handleSelectionChange = (e: { value: PaginationPerPageOptions }) => {
    setPaginationPerPageCount(e.value);
  };

  return (
    <SettingsCard title="Select Items per Page:">
      <Dropdown
        value={paginationPerPageCount}
        options={options}
        onChange={handleSelectionChange}
        style={{ width: '10em' }}
      />
    </SettingsCard>
  );
};

export function LockAddressColumn() {
  const { isFirstFewColumnsLocked, setFirstFewColumnsLocked } = useAppSettingsContext();

  const handleCheckChange = (e) => {
    setFirstFewColumnsLocked(e.checked);
  };

  return (
    <SettingsCard title="Lock Address Column">
      <Checkbox onChange={handleCheckChange} checked={isFirstFewColumnsLocked}></Checkbox>
      <label htmlFor="lockAddressColumn" className="ml-2">Lock Address Column</label>
    </SettingsCard>
  );
}
