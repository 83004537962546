import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createListSort } from '../../../api/ListSort/createListSort';
import { CreateListSortProps } from '../../../utility/types';

export function useCreateListSort() {
  const queryClient = useQueryClient();

  const createListSortMutation = useMutation({
    mutationFn: (body: CreateListSortProps) => createListSort(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getListSorts' ] });
    },
  });

  return createListSortMutation;
}
