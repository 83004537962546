import { useQuery } from '@tanstack/react-query';
import { getApproaches } from '../../../api/Approaches/approaches';

export function useGetApproaches() {
  const getQuery = useQuery({
    queryKey: [ 'getApproaches' ],
    queryFn: () => getApproaches()
  });

  return {
    data: getQuery.data?.data.results || null, // Access response.data
    error: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    isPending: getQuery.isPending,
    refetch: getQuery.refetch
  };
}
