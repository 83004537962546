import React, { FC, useState, ChangeEvent, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { updatePropertyComments } from '../../../../api/Property/updatePropertyComments';
import { createPropertyComments } from '../../../../api/Property/createPropertyComments';
import { IconX, RedCheckMark, TrashIcon } from '../../../icons/OurIcons';
import { convertHeicToJpeg } from '../../../../utility/utility_functions';

type PropertyCommentsFormProps = {
  propertyId: string;
  commentData: any;
  isEdit: boolean;
  formData: any;
  existingCommentId: string;
  setFormData: any;
  setIsEdit: (boolean) => void;
  setIsPropertyCommentsModalOpen: (boolean) => void;
}

const PropertyCommentsForm: FC<PropertyCommentsFormProps> = ({ setIsPropertyCommentsModalOpen, propertyId, setIsEdit, commentData, formData, existingCommentId, setFormData }) => {
  const [ photoPreviews, setPhotoPreviews ] = useState([]);
  const [ removedPhotoIndices, setRemovedPhotoIndices ] = useState<number[]>([]);

  const toast = useRef(null);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target as HTMLInputElement;
  
    if (input?.type === 'file' && input?.files) {
      const newFiles: File[] = Array.from(input?.files);
      const updatedPhotos: File[] = []; // Array to store processed files (HEIC to JPEG)
      const newPreviews: string[] = [];
  
      for (const file of newFiles) {
        if (file?.type === 'image/heic') {
          const resizedFile = await convertHeicToJpeg(file); // Convert HEIC to JPEG
          if (resizedFile instanceof File) {
            updatedPhotos.push(resizedFile); // Add converted JPEG to updated photos
            newPreviews.push(URL.createObjectURL(resizedFile));
          } else {
            console.error('The resized file is not a valid Blob or File:', resizedFile);
          }
        } else {
          updatedPhotos.push(file);
          newPreviews.push(URL.createObjectURL(file));
        }
      }
  
      setFormData(prevFormData => ({
        ...prevFormData,
        photos: [ ...prevFormData.photos, ...updatedPhotos ],
      }));
  
      setPhotoPreviews(prevPreviews => [ ...prevPreviews, ...newPreviews ]); // Set new previews
    } else if (input.tagName.toLowerCase() === 'textarea') {
      const value = input.value;
      setFormData(prevFormData => ({
        ...prevFormData,
        [input.name]: value,
      }));
    }
  };  

  const handleRemoveAttachedPhoto = (index) => {
    const updatedPhotos = [ ...formData.photos ];
    updatedPhotos.splice(index, 1);
    setFormData({ ...formData, photos: updatedPhotos });

    const updatedPreviews = [ ...photoPreviews ];
    updatedPreviews.splice(index, 1);
    setPhotoPreviews(updatedPreviews);
  };

  const handleRemoveExistingPhoto = (index: number) => {
    const isIndexRemoved = removedPhotoIndices.includes(index);
    
    if (isIndexRemoved) {
      setRemovedPhotoIndices(removedPhotoIndices.filter(i => i !== index));
    } else {
      setRemovedPhotoIndices([ ...removedPhotoIndices, index ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    showToastSubmit();
    setIsPropertyCommentsModalOpen(false);
  
    const comment = new FormData();
    comment.append('comment[title]', formData?.title);
    comment.append('comment[notes]', formData?.notes);
  
    // Append new photos
    formData?.photos?.forEach((photo, index) => {
      if (photo instanceof File) {
        comment.append('comment[photos][]', photo);
      }
    });
  
    // Remove selected existing photos
    commentData?.comment?.photos?.forEach((photo, index) => {
      // Check if the photo at this index should be removed
      if (removedPhotoIndices.includes(index)) {
        // Append the ID of the photo to be removed
        comment.append('comment[remove_photos][]', photo.id);
      }
    });
  
    try {
      if (!commentData?.comment?.id) {
        await createPropertyComments(propertyId, comment);
      } else {
        await updatePropertyComments(propertyId, existingCommentId, comment);
      }
    } catch (error) {
      console.error('Error during comment submission:', error);
    }
  };  
  
  const showToastSubmit = () => {
    toast.current.show({ detail: 'Property Notes successfuly updated!' });
  };

  return (
    <>
      <div>
        <div className='absolute float-right right-12 mb-20 cursor-pointer' onClick={() => setIsPropertyCommentsModalOpen(false)}>
          <IconX />
        </div>
        <div className="inline-flex align-items-center justify-content-center gap-2 mb-5">
          <span className="font-bold text-xl white-space-nowrap">My Property Comments</span>
        </div>
        <hr className='mb-5' />
      </div>
      <div className='card justify-content-center'>
        <div className='justify-content-center'>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-8 mb-5 rounded-xl justify-center'>
            <form encType="multipart/form-data">
              <div>
                <h3 className='font-semibold'>Title</h3>
                <input
                  type='text'
                  name='title'
                  placeholder='Add property title'
                  value={formData.title}
                  onChange={handleInputChange}
                  className='rounded-lg w-full focus:outline-none focus:ring focus:ring-indigo-300'
                />
              </div>
              <div className='my-3'>
                <h3 className='font-semibold'>Notes</h3>
                <textarea
                  name='notes'
                  placeholder='Add property notes'
                  value={formData.notes}
                  onChange={handleInputChange}
                  className='rounded-lg w-full h-40 focus:outline-none focus:ring focus:ring-indigo-300'
                />
              </div>
              <div className='mt-3'>
                <div className='relative hover:cursor-pointer'>
                  <input type="file" accept='.heic, .png, .jpg, .jpeg' multiple className='inset-0 border absolute w-full h-full hover:cursor-pointer opacity-0' onChange={(e) => handleInputChange(e)} />
                  <button className='rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-indigo-600 hover:bg-indigo-500 hover:cursor-pointer'>Add Images</button>
                </div>
                <div className='grid grid-cols-3'>
                  {photoPreviews?.map((preview, index) => {
                    // Check if it's a PDF
                    const file = formData?.photos[index];
                    const isPdf = file && file.type === 'application/pdf';
                    return (
                      <div key={index} className='relative inline-block mt-3 mr-10'>
                        {isPdf ? (
                          <div>
                            <iframe 
                              src={file.preview} 
                              className='w-full h-[500px] min-w-[300px] rounded-md'
                            />
                          </div>
                        ) : (
                          <img src={preview} alt={`Preview ${index}`} className='w-26 h-24 rounded-md' />
                        )}
                        <button
                          className='absolute top-0 right-0 text-xs'
                          onClick={() => handleRemoveAttachedPhoto(index)}
                        >
                          <IconX />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
            <div className='overflow-y-scroll mt-3 md:mt-0'>
              <h3 className='font-semibold pb-2'>My Property Photos</h3>
              <div className='overflow-y-scroll max-h-80 grid grid-cols-3'>
                {commentData?.comment?.photos?.map((photo, index) => (
                  <div key={index} className='inline-flex items-center mt-2 mr-6'>
                    <p className="text-sm mr-2 whitespace-nowrap text-nowrap">{photo.filename}</p>
                    <button className='text-xs w-10' onClick={() => handleRemoveExistingPhoto(index)}>
                      {removedPhotoIndices.includes(index) ? 
                        <RedCheckMark height={16} width={16} /> :
                        <TrashIcon height={16} width={16} />
                      }
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center py-8 sticky bottom-0 bg-white'>
        <button onClick={() => setIsEdit(false)} className='rounded bg-indigo-600 mr-3 px-2 py-1 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            Cancel
        </button>
        <>
          <Toast ref={toast} className='bg-indigo-900 text-white rounded-lg z-50' />
          <button type='submit' onClick={handleSubmit} className='rounded bg-indigo-600 px-2 py-1 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            Submit
          </button>
        </>
      </div>
    </>
  );
};

export default PropertyCommentsForm;
