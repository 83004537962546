import React, { useState } from 'react';
import SaveCriteriaModal from './Components/modals/SaveCriteriaModal';
import { BookmarkSquareIcon } from '@heroicons/react/20/solid';
import { useCreateSavedSearchCriteria } from '../../hooks/api/SearchCriteria/useCreateSavedSearchCriteria';
import { useFilteringContext } from '../../contexts/Filtering';

const SaveSearchCriteria = (ransackKeys: any) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ newSearchCriteriaName, setNewSearchCriteriaName ] = useState('');

  const { appliedFiltersString } = useFilteringContext();
  const { createSavedSearchCriteria, errorCreateSavedSearchCriteria, pendingCreateSavedSearchCriteria } = useCreateSavedSearchCriteria();

  // Callback to capture the name of the new Search Criteria (possible refactor to decrease re-renders?)
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSearchCriteriaName(event.target.value);
  };

  // Hanlde closing the 'Save Search' Modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // When the user saves a new search criteria
  const confirmSaveSearchCriteria = async () => {
    // do we want to close even if there is an error? Or just show an error modal
    try {
      await createSavedSearchCriteria({ newSearchCriteriaName });
      setIsModalOpen(false);
    } catch (e) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <button
        key="save_criteria_button"
        className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
        onClick={() => setIsModalOpen(true) }
      >
        <BookmarkSquareIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        Save Search Criteria
      </button>
      {isModalOpen && (
        <SaveCriteriaModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={confirmSaveSearchCriteria}
          handleNameChange={handleNameChange}
          inputString={appliedFiltersString.split('&')}
        />
      )}
    </>
  );
};
export default SaveSearchCriteria;
