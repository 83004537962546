import React, { type FC } from 'react';

type TableProps = {
  objectName: string;
  subtitle?: string;
  tableData: Array<Map<string, string>>;
  basePath: string;
  showID?: boolean;
  returnTitle?: string;
  returnPath?: string;
}

const Table: FC<TableProps> = ({ objectName, subtitle, tableData, basePath, showID = false, returnTitle = null, returnPath = null }) => {
  const firstRow = tableData[0] ?? {};
  const toTitleCase = (word?: string) => word ? `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}` : `No ${objectName}`;
  const headers = [];
  const headerKeys = Object.keys(firstRow);
  headers.push(
    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
      {toTitleCase(headerKeys[0])}
    </th>
  );
  for (let i = 1; i < headerKeys.length; i++) {
    if (headerKeys[i] == 'id' && !showID) { continue; }
    headers.push(
      <th key={headerKeys[i]} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        {toTitleCase(headerKeys[i])}
      </th>
    );
  }
  return (
    <div className="px-0 lg:px-0 xs:px-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{objectName}</h1>
          {subtitle &&
            <p className="mt-2 text-sm text-gray-700">
              {subtitle}
            </p>
          }
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 flex sm:flex-none">
          {returnPath && <a
            href={returnPath}
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {returnTitle}
          </a>}
          <a
            type="button"
            className="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            href={`${basePath}/new`}
          >
            Add {objectName}
          </a>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {headers}
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData.map((data) => {
                    const keys = Object.keys(data);
                    const components = [];
                    components.push(
                      <td key={data[keys[0]]} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {data[keys[0]]}
                      </td>
                    );
                    for (let i = 1; i < keys.length; i++) {
                      if (keys[i] == 'id' && !showID) {
                        continue;
                      }
                      components.push(
                        <td key={String(data[keys[i]])} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{String(data[keys[i]])}</td>
                      );
                    }
                    const rowId = data['id'];
                    return (
                      <tr key={rowId}>
                        {components}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a href={`${basePath}/${rowId}`} className="text-indigo-600 hover:text-indigo-900">
                            Show<span className="sr-only">, {rowId}</span>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
