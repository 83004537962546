import React, { useEffect, useState, type FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { RangeSelectorComponentProps } from './utility';

const TieredRangeSelectorComponent: FC<RangeSelectorComponentProps> = ({
  id,
  min,
  max,
  title,
  defaultMin,
  defaultMax,
  onUpdate
}) => {
  const handleInputChange = (event: SelectChangeEvent<number>) => {
    event.preventDefault();
    const value  = Number(event.target.value);
    // correct value received on click
    let newMin = min;
    let newMax = max;
    // If the value is 0, set to orig min/max
    if (value <= 0 ){
      newMin = defaultMin; // reset min
      newMax = defaultMax; // reset max
    }
    else if (value > 0 && value < 10) {
      newMin = value;
      newMax = value;
    }
    else if (value >= 10) {
      newMin = value % 10;
      newMax = defaultMax; // reset max
    }
    onUpdate(id, { min: newMin, max: newMax });
  };

  const formatValue = () => {
    if (min == defaultMin && max == defaultMax) {
      return 0;
    }
    else if (min == max) {
      return min;
    }
    else {
      return min + 10;
    }
  };

  return (
    <Box>
      <FormControl variant='filled' sx={{ minWidth: 200 }}>
        <Select
          labelId="demo-simple-select-label"
          id="value-select"
          value={formatValue()}
          onChange={(e) => handleInputChange(e)}
          label={title}
        >
          <ChevronDownIcon className="w-5" />
          <MenuItem value={0}>Any</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={11}>1+</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={12}>2+</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={13}>3+</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={14}>4+</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TieredRangeSelectorComponent;
