import React, { createContext, useContext, useState, type FC, ReactNode, useEffect } from 'react';
import { useGetListSorts } from '../hooks/api/ListSort/useGetListSorts';

type DecisionMakingContextType = {
  activeListSortId: string;
  setActiveListSortId: (newValue: string) => void;
}

type DecisionMakingContextProviderProps = {
  children: ReactNode;
  listSortId?: string;
}

const DecisionMakingContext = createContext<DecisionMakingContextType | undefined>(undefined);

export function DecisionMakingContextProvider({ listSortId, children }: DecisionMakingContextProviderProps) {
  const [ activeListSortId, setActiveListSortId ] = useState<string>(listSortId);
  const { data: listSorts } = useGetListSorts();

  useEffect(() => {
    const getMostRecentListSortId = () => {
      if (listSorts && listSorts.length > 0) {
        return listSorts[listSorts.length - 1]['id'];
      }
    };

    // if user doesn't link to DMP from a list sort
    // get & use the most recent list sort as a default
    if (!activeListSortId) {
      const mostRecentListSortId = getMostRecentListSortId();
      if (mostRecentListSortId) {
        setActiveListSortId(mostRecentListSortId);
      }
    }
  }, [ listSorts, activeListSortId ]);

  return (
    <DecisionMakingContext.Provider value={{ activeListSortId, setActiveListSortId }}>
      {children}
    </DecisionMakingContext.Provider>
  );
};

// Custom hook to access the context
export function useDecisionMakingContext() {
  const context = useContext(DecisionMakingContext);
  if (!context) {
    throw new Error('useDecisionMakingContext must be used within a DecisionMakingContext');
  }
  return context;
}

export default DecisionMakingContext;
