import React, { useEffect, useState, type FC } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Grid } from '@mui/material';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { FilterDescriptor, FilterMetadata, RangeValuesType } from '../../contexts/Filtering';
import { RangeSelectorComponentProps } from '../propertySearch/Components/filters/utility';

const Input = styled(MuiInput)`
  width: 40px;
  text-align: center!;
`;

function valuetext(value: number) {
  return `${value}°C`;
}

const RangeSliderComponent: FC<RangeSelectorComponentProps> = ({
  id,
  min,
  max,
  defaultMin,
  defaultMax,
  title,
  unit,
  description,
  onUpdate
}) => {
  const [ rangeValues, setRangeValues ] = useState<number[]>([ min, max ]);
  const [ debouncedRangeValues, setDebouncedRangeValues ] = useState<number[]>([ min, max ]);

  const minValue = defaultMin;
  const maxValue = defaultMax;
  const currentValue1 = min;
  const currentValue2 = max;

  useEffect(()=> {
    setRangeValues([ currentValue1, currentValue2 ]);
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedRangeValues(rangeValues);
    }, 10000);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [ rangeValues ]);

  useEffect(() => {
    const [ value1, value2 ] = debouncedRangeValues;
    if (value1 !== currentValue1 || value2 !== currentValue2) {
      onUpdate(id, { min: value1, max: value2 });
    }
  }, [ debouncedRangeValues ]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setRangeValues(newValue as number[]);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    if (event.target.value !== '' ){
      let newVal = Number(event.target.value);
    }
    let newValue = rangeValues[index];

    if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }

    if (newValue !== rangeValues[index]) {
      const newRangeValues = [ ...rangeValues ];
      newRangeValues[index] = newValue;
      setRangeValues(newRangeValues);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    if (event.target.value !== '' ){
      let newVal = Number(event.target.value);
      if (newVal < minValue) {
        newVal = minValue;
      } else if (newVal > maxValue) {
        newVal = maxValue;
      }

      // make sure this works for flipping them!!!
      const newRangeValues = [ ...rangeValues ];
      newRangeValues[index] = newVal;
      setRangeValues(newRangeValues);
    }
  };

  return (
    <Box key={`RangeSliderComponent-${id}`} className="p-5 border border-solid rounded-lg border-slate-200 shadow-xl shadow-slat-200">
      <h4 id="input-slider">
        {title}
      </h4>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Input
            value={rangeValues[0]}
            size="small"
            onChange={(event) => handleInputChange(event, 1)}
            onBlur={(event) => handleBlur(event, 0)}
            className="w-40 text-center"
            inputProps={{
              step: 10,
              min: { minValue },
              max: { maxValue },
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
        <Grid item xs>
          <Slider
            getAriaLabel={() => description}
            value={rangeValues}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            min={minValue} max={maxValue}
          />
        </Grid>
        <Grid item>
          <Input
            value={rangeValues[1]}
            size="small"
            onChange={(event) => handleInputChange(event, 1)}
            onBlur={(event) => handleBlur(event, 1)}
            className="w-40 text-center"
            inputProps={{
              step: Math.floor((maxValue - minValue) / 10),
              min: { minValue },
              max: { maxValue },
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      <p className="mt-4 text-gray-800">Current Min: {currentValue1}</p>
      <p className="text-gray-800">Current Max: {currentValue2}</p>
    </Box>
  );
};

export default RangeSliderComponent;
