import React, { FC, useEffect, useState } from 'react';
import ApproachConditionRow from './ApproachConditionRow';
import { ApproachCondition } from '../approachTypes';

export type ApproachConditionsTableProps = {
  approachConditions: ApproachCondition[];
  onChange: (approachConditions: ApproachCondition[]) => void;
};

const ApproachConditionsTable: FC<ApproachConditionsTableProps> = ({ approachConditions, onChange }) => {
  const [ approachConditionsState, setApproachConditionState ] = useState(approachConditions);

  const handleRemove = (conditionToRemove: ApproachCondition) => {
    setApproachConditionState(prevConditions =>
      prevConditions.filter((condition, _) => condition.id !== conditionToRemove.id)
    );
  };

  const handleCreate = () => {
    const newCondition: ApproachCondition = {
      id: crypto.randomUUID(),
      comparison_attribute: null,
      comparison_type: '<',
      comparison_value: null,
      order: approachConditionsState.length
    };
    setApproachConditionState(prevConditions => [ ...prevConditions, newCondition ]);
  };

  const handleUpdate = (updatedCondition: ApproachCondition) => {
    setApproachConditionState(prevConditions => {
      var updatedConditions = prevConditions.filter((condition, _) => condition.id !== updatedCondition.id);
      updatedConditions = [ ...updatedConditions, updatedCondition ];
      updatedConditions.sort((a, b) => a.order - b.order);
      return updatedConditions;
    });
  };

  useEffect(() => {
    onChange(approachConditionsState);
  }, [ approachConditionsState ]);

  const renderCreateNewConditionButton = () => {
    const conditionText = approachConditionsState.length > 0 ? 'Add another condition' : 'Add a condition';
    return (
      <>
        <tr>
          <td colSpan={4} className="bg-white py-4 px-8">
            <div className="text-center justify-center items-center  text-sm font-medium">
              <a
                className="text-indigo-600 hover:text-indigo-900 hover:cursor-pointer"
                onClick={handleCreate}
              >
                {conditionText}<span className="sr-only">, {'new_condition'}</span>
              </a>
            </div>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <div className="mt-3 flow-root overflow-hidden">
        <div className="mx-auto max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="relative isolate pr-1 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell">
                  Comparison Attribute
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                >
                  Comparison Value
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {approachConditionsState.map((condition) => (
                <ApproachConditionRow
                  key={condition.id}
                  condition={condition}
                  onRemove={handleRemove}
                  onChange={handleUpdate}
                />
              ))}
              {renderCreateNewConditionButton()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApproachConditionsTable;
