import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import DualValueRangeComponent from '../../../common/DualValueRangeComponent';
import { RangeSelectorComponentProps } from './utility';

const DualValueRangeCard: React.FC<RangeSelectorComponentProps> = ({
  id,
  title,
  unit,
  min,
  max,
  defaultMin,
  defaultMax,
  description,
  onUpdate
}) => {
  return (
    <div key={`DualValueRangeCard-${id}`} className="rounded-lg text-sm hover:bg-gray-50 shadow-md">
      <DualValueRangeComponent
        title={title}
        unit={unit}
        min={min}
        max={max}
        id={id}
        onValuesChange={(min: number, max: number) => onUpdate(id, { min, max })}
      />
    </div>
  );
};

export default DualValueRangeCard;
