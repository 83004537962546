import React from 'react';

type SettingsCardProps = {
  title: string;
  children: React.ReactNode;
};

export function SettingsCard({ title, children }: SettingsCardProps) {
  return (
    <div className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
      <h1 className="py-2">{title}</h1>
      <div className="flex align-items-center">
        {children}
      </div>
    </div>
  );
}
