import React, { type FC, Fragment } from 'react';

export type TopMlsNumbersProps = {
  compsSixSold: Array<object>;
  compsFifteenSold: any;
}

const smallTxtStyling = 'text-xs pt-1 pb-2 whitespace-nowrap';

const TopMlsNumbers: FC<TopMlsNumbersProps> = ({ compsSixSold, compsFifteenSold }) => {

  return (
    <div className='place-content-start m-5 text-left'>
      <h6 className='text-md mb-2 font-semibold'>Top 6 Sold Comps Last MLS #</h6>
      <div className='flex'>
        {compsSixSold?.map((comp: any, index: number) => {
          if (comp?.property?.last_mls_id) {
            return (
              <Fragment key={comp?.property?.id}>
                {index !== (compsSixSold?.length - 1) ? <p key={index} className={smallTxtStyling}>{`${comp?.property?.last_mls_id},\u00A0`}</p> : <p key={index} className={smallTxtStyling}>{`${comp?.property?.last_mls_id}`}</p>}
              </Fragment>
            );
          }
        })}
      </div>
      <h6 className='text-md mt-5 mb-2 font-semibold'>Top 15 Sold Comps Last MLS #</h6>
      <div className='flex'>
        {compsFifteenSold?.map((comp, index) => {
          if (comp?.property?.last_mls_id) {
            return (
              <Fragment key={comp?.property?.id}>
                {index !== (compsFifteenSold?.length - 1) ? <p key={index} className={smallTxtStyling}>{`${comp?.property?.last_mls_id},\u00A0`}</p> : <p key={index} className={smallTxtStyling}>{`${comp?.property?.last_mls_id}`}</p>}
              </Fragment>
            );
          }
        })}
      </div>
    </div>
  );
};
export default TopMlsNumbers;
