import { useQuery } from '@tanstack/react-query';
import { getListUploads } from '../../../api/ListUpload/getListUploads';

export function useGetListUploads() {
  const getQuery = useQuery({
    queryKey: [ 'getListUploads' ],
    queryFn: () => getListUploads()
  });

  return {
    data: getQuery.data?.data || null, // Access response.data
    error: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    isPending: getQuery.isPending,
  };
}
