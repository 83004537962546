import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import React, { useEffect, type FC } from 'react';

export type TriStateCheckboxComponentProps = {
  title: string;
  id: string;
  itemTemplate?: any;
  onValuesChange: (id: string, newValue: any) => void;
  value: true|false|null;
}

const TriStateCheckboxComponent: FC<TriStateCheckboxComponentProps> = ({
  title,
  id,
  onValuesChange,
  itemTemplate,
  value
}) => {
  useEffect(()=> {
    onValuesChange(id, value);
  }, [ value ]);

  return (

    <div className="flex align-items-center ">
      <TriStateCheckbox
        id={`${id}-filter`}
        value={value}
        onChange={(e) => {
          onValuesChange(id, e.value);
        }}
      />
      {itemTemplate ? itemTemplate(value, value) : <label>-{String(value)}</label>}
    </div>
  );
};

export default TriStateCheckboxComponent;
