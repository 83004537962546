import React, { FC, useEffect, useState } from 'react';
import { ApproachCondition, ComparisonConditions } from '../approachTypes';
import { capitalizeString } from '../../../../utility/utility';

export type ApproachConditionRowProps = {
  condition: ApproachCondition;
  onRemove: (condition: ApproachCondition) => void;
  onChange: (condition: ApproachCondition) => void;
};

const ApproachConditionRow: FC<ApproachConditionRowProps> = ({ condition, onRemove, onChange }) => {
  const [ conditionState, setConditionState ] = useState(condition);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setConditionState({ ...conditionState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    onChange(conditionState);
  }, [ conditionState ]);

  const comparisonConditions = new ComparisonConditions();
  const comparisonAttributesKeys = Object.keys(comparisonConditions.comparisonAttributes);
  const comparisonTypesKeys = Object.keys(comparisonConditions.comparisonTypes);

  return (
    <>
      <tr key={condition.id}>
        <td className="relative py-4 pr-1 text-sm font-medium text-gray-500 table-cell">
          <select
            id="comparison_attribute"
            name="comparison_attribute"
            autoComplete="comparison_attribute"
            onChange={handleInputChange}
            value={conditionState.comparison_attribute}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value={null}>Select an attribute</option>
            {comparisonAttributesKeys.map((attributeKey) => (
              <option value={attributeKey}>{comparisonConditions.comparisonAttributes[attributeKey].display}</option>
            ))}
          </select>
          <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
          <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
        </td>
        <td className="px-1 py-4 text-sm text-gray-500 table-cell">
          <select
            id="comparison_type"
            name="comparison_type"
            autoComplete="comparison_type"
            onChange={handleInputChange}
            value={conditionState.comparison_type}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            {comparisonTypesKeys.map((comparisonTypeKey) => (
              <option value={comparisonTypeKey}>{comparisonConditions.comparisonTypes[comparisonTypeKey].display}</option>
            ))}
          </select>
        </td>
        <td className="px-3 py-4 text-sm text-gray-500 table-cell">
          <input
            id="comparison_value"
            name="comparison_value"
            type="number"
            placeholder="2000.0"
            onChange={handleInputChange}
            value={condition.comparison_value}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </td>
        <td className="relative py-4 pl-3 text-right text-sm font-medium">
          <a onClick={() => onRemove(condition)} className="text-indigo-600 hover:text-indigo-900 hover:cursor-pointer">
            Remove<span className="sr-only">, {condition.id}</span>
          </a>
        </td>
      </tr>
    </>
  );
};

export default ApproachConditionRow;
