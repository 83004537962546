import React, { useEffect, useState } from 'react';
import { DateRangeSelectorProps } from './Components/filters/utility';
import { Button } from 'primereact/button';

export function formatDateYYYY_MM_DD(date: Date) {
  const [ month, day, year ] = [
    date.getMonth() || 1,
    date.getDate() || 1,
    date.getFullYear() || 1970,
  ];
  return `${year}-${month}-${day}`;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  id,
  title,
  description,
  defaultStartDate,
  defaultEndDate,
  onUpdate
}) => {
  const [ start, setStart ] = useState(defaultStartDate.toString());
  const [ end, setEnd ] = useState(defaultEndDate.toString());

  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStart(e.target.value);
  };

  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(e.target.value);
  };

  const handleApply = () => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    onUpdate(id, { startDate: formatDateYYYY_MM_DD(startDate), endDate: formatDateYYYY_MM_DD(endDate) });
  };

  return (
    <div className="card">
      <h1>{title}</h1>
      <div className="flex justify-content-center">
        <input type="date" value={start.toString()} onChange={handleStartChange} />
        <input type="date" value={end.toString()} onChange={handleEndChange} />
      </div>
      <div className="flex justify-content-center">
        <Button onClick={handleApply}>Apply</Button>
      </div>
    </div>
  );
};

export default DateRangeSelector;

