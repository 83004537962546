import React, { useState, type FC, useEffect, SyntheticEvent } from 'react';

import { ArrowIcon, LoadingSpinner } from '../icons/OurIcons';
import placeholder from 'images/placeholder.webp';
import PropertyGalleria from '../property-detail-page/PropertyGalleria';

type MlsImagesViewerProps = {
  propertyImages: any;
  galleriaRef: any;
}

const MlsImagesViewer: FC<MlsImagesViewerProps> = ({ propertyImages, galleriaRef }) => {
  const [ image, setImage ] = useState(null);
  const [ photoIndex, setPhotoIndex ] = useState(0);
  const [ showArrows, setShowArrows ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    if (propertyImages) {
      setImage(propertyImages[0] || { image_url: placeholder });
      setIsLoading(false);
    }
  }, [ propertyImages ]);

  const toggleArrowDisplay = (enable: boolean) => {
    const multipleImages = propertyImages?.length > 1;
    setShowArrows(enable && multipleImages);
  };

  const nextPhoto = (event) => {
    setIsLoading(true);
    event.stopPropagation();
    getPhoto(photoIndex + 1, 0);
    setIsLoading(false);
  };

  const prevPhoto = (event) => {
    setIsLoading(true);
    event.stopPropagation();
    getPhoto(photoIndex - 1, propertyImages?.length - 1);
    setIsLoading(false);
  };

  const getPhoto = (index, reset) => {
    setIsLoading(true);
    let nextIndex = index;
    const propertyImage = propertyImages[nextIndex];

    if (propertyImage) {
      setImage(propertyImage);
    } else {
      nextIndex = reset;
      setImage(propertyImages[nextIndex] || { image_url: placeholder });
    }
    setPhotoIndex(nextIndex);
    setIsLoading(false);
  };

  return (
    <div onMouseEnter={() => toggleArrowDisplay(true)} onMouseLeave={() => toggleArrowDisplay(false)}>
      <div className="relative overflow-y-hidden h-96 rounded-xl pt-64 sm:px-12 lg:max-w-full lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
        {
          isLoading ?
            <div role="status">
              <LoadingSpinner />
              <span className="sr-only">Loading...</span>
            </div>
            : <img
              className="absolute inset-0 flex h-full w-full"
              src={image.image_url}
              alt="Property Images"
              onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement;
                target.src = placeholder;
              }}
              onClick={() => galleriaRef.current.show()}
            />
        }
        {
          image?.date && <p className={'absolute bottom-5 right-5 text-xl text-white bg-slate-700/60'}>{image.date}</p>
        }
        <div className={`absolute w-8 top-24 right-5 cursor-pointer ${showArrows ? 'visible' : 'invisible'}`} onClick={nextPhoto}>
          <ArrowIcon color='white' />
        </div>
        <div className={`absolute w-8 top-24 left-5 rotate-180 cursor-pointer ${showArrows ? 'visible' : 'invisible'}`} onClick={prevPhoto}>
          <ArrowIcon color='white' />
        </div>
      </div>
      <PropertyGalleria
        ref={galleriaRef}
        propertyImages={propertyImages}
      />
    </div>
  );
};

export default MlsImagesViewer;
