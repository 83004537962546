import React, { type FC } from 'react';
import { formatFloat } from '../../../utility/utility_functions';
import { Loader } from '../../icons/OurIcons';

type ValuationsTableProps = {
  propertyDetails: any;
  soldAvmResult: any;
  activeAvmResult: any;
  selectedInput: string;
  inputValue: number;
  soldCompsSix: any[];
  activeRow: string;
  userEstimate?: number;
  userEstimateSqft?: number;
  setUserEstimate: (number) => void;
  setUserEstimateSqft: (number) => void;
  setActiveRow: (string) => void;
  setArvEstimate: (number) => void;
  setArvSqft: (number) => void;
}

const ValuationsTable: FC<ValuationsTableProps> = ({
  propertyDetails,
  soldAvmResult,
  activeAvmResult,
  selectedInput,
  inputValue,
  soldCompsSix,
  setArvEstimate,
  setArvSqft,
  activeRow,
  setActiveRow,
  setUserEstimate,
  setUserEstimateSqft,
  userEstimate,
  userEstimateSqft
}) => {

  const onRowClick = (arvEst, arvSf, rowName) => {
    setArvEstimate(arvEst);
    setArvSqft(arvSf);
    setActiveRow(rowName);

    if (rowName === 'userInput') {
      setUserEstimate(arvEst);
      setUserEstimateSqft(arvSf);
    }
  };

  // User input
  const getUserInputValAmount = (value: number): number | string => {
    if ((selectedInput === 'Valuation Amount' || selectedInput === '') && value) {
      return value;
    }

    if (selectedInput === '$/Sq Ft' && value) {
      let valAmount = value * propertyDetails?.sqft;
      return valAmount;
    }

    return null;
  };

  const getUserInputSqFtAmount = (value: number): number | string => {
    if ((selectedInput === '$/Sq Ft' || selectedInput === '') && value) {
      return value;
    }

    if (selectedInput === 'Valuation Amount' && value) {
      let dollarPerSqFt = value / propertyDetails?.sqft;
      return dollarPerSqFt;
    }

    return null;
  };

  const getUserInputArvPercent = (value: number): number | string => {
    if ((selectedInput === 'Valuation Amount' || selectedInput === '') && value) {
      return ((value / soldAvmResult?.result?.estimated_value) * 100).toFixed(2);
    }

    if (selectedInput === '$/Sq Ft' && value) {
      let valAmount = (value * propertyDetails?.sqft);
      return ((valAmount / soldAvmResult?.result?.estimated_value) * 100).toFixed(2);
    }

    return null;
  };

  // 6 & 15 comps
  const avmFifteen = soldAvmResult?.comps?.slice(0, 15);

  const getAvmSixCompAvgSqFt = (): any => {
    let sqFtArr = soldCompsSix?.map((a) => a?.property.sqft).filter((a) => a != null);

    return (sqFtArr?.reduce((accumulator, currentVal) => accumulator + currentVal) / 6).toFixed(0);
  };

  const getAvgSoldPrice = (avmCompsArr): any => {
    let soldPriceArr = avmCompsArr?.map((a) => a?.property_value_at_time_of_calculation).filter((a) => a != null);

    if (soldPriceArr?.length === 15) {
      return (soldPriceArr?.reduce((accumulator, currentVal) => accumulator + currentVal) / 15).toFixed(0);
    }

    if (soldPriceArr?.length === 6) {
      return (soldPriceArr?.reduce((accumulator, currentVal) => accumulator + currentVal) / 6).toFixed(0);
    }

    return null;
  };

  const getAvgDollarPerSqFt = (avmCompsArr): any => {
    let dollarPerSqFtArr = avmCompsArr?.map((a) => a?.sold_dollar_per_sqft).filter((a) => a != null);

    if (dollarPerSqFtArr?.length === 15) {
      return (dollarPerSqFtArr?.reduce((accumulator, currentVal) => accumulator + currentVal) / 15);
    }

    if (dollarPerSqFtArr?.length === 6) {
      return (dollarPerSqFtArr?.reduce((accumulator, currentVal) => accumulator + currentVal) / 6);
    }

    return null;
  };

  const getValAmountFromSqFt = (avmCompsArr): any => {
    if (avmCompsArr?.length === 15) {
      return soldAvmResult?.result?.all_comp_dollar_per_sqft * soldAvmResult?.result?.avg_comp_sqft;
    }

    if (avmCompsArr?.length === 6) {
      return getAvgDollarPerSqFt(avmCompsArr) * getAvmSixCompAvgSqFt();
    }

    return null;
  };

  const getPercentOfArvFromAvgSoldPrice = (avmCompsArr): number | string => {
    const CompEstimatedValue = getAvgSoldPrice(avmCompsArr);
    const estimatedValue = soldAvmResult?.result?.estimated_value;

    if (estimatedValue !== 0) {
      return (CompEstimatedValue / estimatedValue) * 100;
    }

    return null;
  };

  const getPercentOfArvFromAvgSqFt = (avmCompsArr): number | string => {
    const CompEstimatedValue = getValAmountFromSqFt(avmCompsArr);
    const estimatedValue = soldAvmResult?.result?.estimated_value;

    if (estimatedValue !== 0) {
      return (CompEstimatedValue / estimatedValue) * 100;
    }

    return null;
  };

  if (!soldAvmResult) {
    return (
      <div className='w-screen content-center px-10'>
        <div className='mx-auto mt-10 w-96'>
          <Loader />
        </div>
      </div>
    );
  }

  const thHorizontalStyling = 'py-2 px-1';
  const thVerticalStyling = 'font-medium text-gray-900 py-2';
  const trStylingInactive = 'hover:cursor-pointer hover:bg-gray-100 text-gray-500 py-2';
  const trStylingActive = 'bg-indigo-600 bg-opacity-25 text-gray-500 py-2';

  return (
    <div className='mx-2 border rounded-lg '>
      <table className='min-w-full divide-y divide-gray-300 whitespace-nowrap text-center'>
        <thead className='divide-y divide-gray-300 rounded-t-lg'>
          <tr className='bg-indigo-600 text-white whitespace-nowrap divide-x divide-gray-300'>
            <th className='rounded-tl-lg w-fit'>
              {/* leave empty */}
            </th>
            <th className={thHorizontalStyling}>
            Valuation
            </th>
            <th className={thHorizontalStyling}>
            $/Sq FT
            </th>
            <th className={`${thHorizontalStyling} rounded-tr-lg`}>
            % of ARV
            </th>
          </tr>
        </thead>
        <tbody className='rounded-lg text-center whitespace-nowrap divide-y divide-gray-300'>
          <tr
            className={`${activeRow === 'userInput' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => {
              onRowClick(getUserInputValAmount(userEstimate), getUserInputSqFtAmount(userEstimateSqft), 'userInput');
            }}
          >
            <th className={thVerticalStyling}>
              User Input
            </th>
            <td>
              {inputValue ? `$${formatFloat(getUserInputValAmount(inputValue), 0)}` : (userEstimate ? `$${formatFloat(getUserInputValAmount(userEstimate), 0)}` : '-')}
            </td>
            <td>
              {inputValue ? `$${formatFloat(getUserInputSqFtAmount(inputValue), 0)}` : (userEstimateSqft ? `$${formatFloat(getUserInputSqFtAmount(userEstimateSqft), 0)}` : '-')}
            </td>
            <td>
              {inputValue ? `${formatFloat(getUserInputArvPercent(inputValue), 0)}%` : (userEstimate ? `${formatFloat(getUserInputArvPercent(userEstimate), 0)}%` : '-')}
            </td>
          </tr>
          <tr
            className={`${activeRow === 'activeAvm' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => onRowClick(activeAvmResult?.result?.estimated_value, activeAvmResult?.result?.estimated_dollar_per_sqft, 'activeAvm')}
          >
            <th className={thVerticalStyling}>
              REIX Active AVM Estimate
            </th>
            <td>
              ${formatFloat(activeAvmResult?.result?.estimated_value, 0) ?? '-'}
            </td>
            <td>
              ${formatFloat(activeAvmResult?.result?.estimated_dollar_per_sqft, 2) ?? '-'}
            </td>
            <td>
              100%
            </td>
          </tr>
          <tr
            className={`${activeRow === 'inactiveAvm' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => onRowClick(soldAvmResult?.result?.estimated_value, soldAvmResult?.result?.estimated_dollar_per_sqft, 'inactiveAvm')}
          >
            <th className={thVerticalStyling}>
              REIX Sold AVM Estimate
            </th>
            <td>
              ${formatFloat(soldAvmResult?.result?.estimated_value, 0) ?? '-'}
            </td>
            <td>
              ${formatFloat(soldAvmResult?.result?.estimated_dollar_per_sqft, 2) ?? '-'}
            </td>
            <td>
              100%
            </td>
          </tr>
          <tr
            className={`${activeRow === '15Comp' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => onRowClick(soldAvmResult?.result?.all_comp_avg_sold_price, soldAvmResult?.result?.all_comp_avg_sold_price / propertyDetails?.sqft, '15Comp')}
          >
            <th className={thVerticalStyling}>
              15 Sold Comp Avg.
            </th>
            <td>
              ${formatFloat(soldAvmResult?.result?.all_comp_avg_sold_price, 0) ?? '-'}
            </td>
            <td>
              ${formatFloat(soldAvmResult?.result?.all_comp_avg_sold_price / propertyDetails?.sqft, 2) ?? '-'}
            </td>
            <td>
              {formatFloat(getPercentOfArvFromAvgSoldPrice(avmFifteen), 2) ?? '-'}%
            </td>
          </tr>
          <tr
            className={`${activeRow === '15CompSqFt' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => onRowClick(getValAmountFromSqFt(avmFifteen), getValAmountFromSqFt(avmFifteen) / propertyDetails?.sqft, '15CompSqFt')}
          >
            <th className={thVerticalStyling}>
              15 Sold Comp $/Sq Ft Avg.
            </th>
            <td>
              ${formatFloat(getValAmountFromSqFt(avmFifteen), 0) ?? '-'}
            </td>
            <td>
              ${formatFloat(getValAmountFromSqFt(avmFifteen) / propertyDetails?.sqft, 2) ?? '-'}
            </td>
            <td>
              {formatFloat(getPercentOfArvFromAvgSqFt(avmFifteen), 2) ?? '-'}%
            </td>
          </tr>
          <tr
            className={`${activeRow === '6Comp' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => onRowClick(soldAvmResult?.result?.closest_avg_sold_price, soldAvmResult?.result?.closest_avg_sold_price / propertyDetails?.sqft, '6Comp')}
          >
            <th className={thVerticalStyling}>
              6 Sold Comp Avg.
            </th>
            <td>
              ${formatFloat(soldAvmResult?.result?.closest_avg_sold_price, 0) ?? '-'}
            </td>
            <td>
              ${formatFloat(soldAvmResult?.result?.closest_avg_sold_price / propertyDetails?.sqft, 2) ?? '-'}
            </td>
            <td>
              {formatFloat(getPercentOfArvFromAvgSoldPrice(soldCompsSix), 2) ?? '-'}%
            </td>
          </tr>
          <tr
            className={`${activeRow === '6CompSqFt' ? `${trStylingActive}` : `${trStylingInactive}`}`}
            onClick={() => onRowClick(getValAmountFromSqFt(soldCompsSix), getValAmountFromSqFt(soldCompsSix) / propertyDetails?.sqft, '6CompSqFt')}
          >
            <th className={thVerticalStyling}>
              6 Sold Comp $/Sq Ft Avg.
            </th>
            <td>
              ${formatFloat(getValAmountFromSqFt(soldCompsSix), 2) ?? '-'}
            </td>
            <td>
              ${formatFloat(getValAmountFromSqFt(soldCompsSix) / propertyDetails?.sqft, 2) ?? '-'}
            </td>
            <td>
              {formatFloat(getPercentOfArvFromAvgSqFt(soldCompsSix), 2) ?? '-'}%
            </td>
          </tr>
          {/* ! not yet available */}
          <tr>
            <th className={`${thVerticalStyling} rounded-bl-lg`}>
              Tax Value
            </th>
            <td>
              -
            </td>
            <td>
              -
            </td>
            <td className='rounded-br-lg'>
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default ValuationsTable;
