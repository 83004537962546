// @ts-ignore
import { AnyLayout } from 'mapbox-gl';
import { FilterDescriptor, FilterValuesType } from './Filtering';

function convertCriteriaToFilterDescriptor(criteria: AnyLayout, filters: Record<string, FilterDescriptor>):
 {
  newActiveFilterIds: string[],
  newFilters: Record<string, FilterDescriptor>
 } {
  // for tests, get the output of this and compare the correct output!!!!!
  const newActiveFilterIds: string[] = [];

  let newFilters = { ...filters }; // Initialize with the current state of filters
  for (const filterId in criteria) {
    if (criteria[filterId] === null) {
      continue;
    }

    if (filterId.startsWith('max_')) {
      const key = filterId.substring(4);
      newActiveFilterIds.push(key);

      newFilters[key] = {
        ...newFilters[key],
        values: {
          ...newFilters[key]?.values,
          max: criteria[filterId]
        } as FilterValuesType,
      };
      continue;
    }

    if (filterId.startsWith('min_')) {
      const key = filterId.substring(4);
      newActiveFilterIds.push(key);

      newFilters[key] = {
        ...filters[key],
        values: {
          ...newFilters[key].values,
          min: criteria[filterId]
        } as FilterValuesType,
      };
      continue;
    }

    switch (filterId) {
    case 'title': {
      // NEED TO HANDLE THIS ONE
      break;
    }
    case 'status' || 'pool' || 'data_source' || 'how_sold' || 'property_class' || 'recent_rehab' || 'sale_or_rent': [];
      {
        const options = filters[filterId]?.options;
        let newValues = [];
        for (const s in criteria[filterId]) {
          for (const option of options) {
            if (option.name === s) {
              newValues.push({ name: option.name, code: option.code });
            }
          }
        }
        if (newValues.length === 0) {
          continue;
        }
        newActiveFilterIds.push(filterId);
        newFilters[filterId] = {
          ...newFilters[filterId],
          values: {
            state: newValues
          } as FilterValuesType,
        };
        break;
      }
    case 'title': {

      break;
    }
    case 'property_id' || 'clerk_number' || 'county_code' || 'street' || 'subdivision' || 'tax_id' || 'zillow_url' || 'last_mls_id' || 'fullAddress': {
      newActiveFilterIds.push(filterId);

      newFilters[filterId] = {
        ...newFilters[filterId],
        values: {
          query: criteria[filterId]
        } as FilterValuesType,
      };
      break;
    }
    default:
      console.error('***not handling:', filterId);
      break;
    }
  }

  const ret = { newActiveFilterIds, newFilters };
  return ret;
};

export { convertCriteriaToFilterDescriptor };

//todo write a test that makes sure all of these behave as expected!!!!!

