import React, { FC } from 'react';
import { LoadingSpinner } from '../icons/OurIcons';
import { DecisionMakingListProperty } from './LitePropertyList';
import useDebouncedIncrementor from '../../hooks/useDebouncedIncrement';

export interface DMPropertyIncrementorProps {
  setActivePropertyId: (propertyId: string) => void;
  paginationPerPageCount: number;
  propertiesCache: DecisionMakingListProperty[];
  page: number;
  setPage: (page: number) => void;
  totalPropertyRecordsFiltered: number;
  loadingProperties: boolean;
  first: number;
  setFirst: (first: number) => void;
}

const DMPropertyIncrementor: FC<DMPropertyIncrementorProps> = ({
  setActivePropertyId,
  paginationPerPageCount,
  propertiesCache,
  page,
  setPage,
  totalPropertyRecordsFiltered,
  loadingProperties,
  first: index,
  setFirst
}) => {
  const onIncrementorClick = (direction: 'next' | 'prev') => {
    const isNextPage = direction === 'next';
    const incrementValue = isNextPage ? 1 : -1;

    debouncedIncrementor(incrementValue);
  };

  const debouncedIncrementor = useDebouncedIncrementor((accumulatedClicks: number) => {
    let newIndex = index;
    let newPage = page;
    // For now, only increment one at a time (no matter how many times the button is clicked)
    if (accumulatedClicks < 0) {
      if (index > 0) {
        newIndex = index - 1;
      } else if (page > 0) {
        newPage = page - 1;
        newIndex = paginationPerPageCount - 1; // Move to the last property of the previous page
      }
    } else if (accumulatedClicks > 0) {
      if (index < paginationPerPageCount - 1 && index < propertiesCache?.length - 1) {
        newIndex = index + 1;
      } else if ((page + 1) * paginationPerPageCount < totalPropertyRecordsFiltered) {
        newPage = page + 1; // Fetch next page
        newIndex = 0; // Reset index to the first property of the new page
      }
    }
    if (propertiesCache && propertiesCache.length >= newIndex) {
      setActivePropertyId(propertiesCache[newIndex].id);
      setPage(newPage);
      setFirst(newIndex);
    }
  }, 300);

  const isNextDisabled = () => {
    return ((page + 1) * paginationPerPageCount >= totalPropertyRecordsFiltered) && (index === propertiesCache?.length - 1);
  };

  return (
    <div className={'flex justify-between absolute p-0 left-6'}>
      <button
        className={`p-button p-component p-button-text
              ${page === 0 && index === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:bg-gray-200' }`}
        onClick={() => onIncrementorClick('prev')}
        disabled={page === 0 && index === 0}
      >
        {'<'}
      </button>
      {loadingProperties ? <LoadingSpinner /> : (
        <span className="text-sm px-2">
              Property {page * paginationPerPageCount + index + 1} of {totalPropertyRecordsFiltered}
        </span>
      )}
      <button
        className={`p-button p-component p-button-text
              ${isNextDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:bg-gray-200' }`}
        onClick={() => onIncrementorClick('next')}
        disabled={isNextDisabled()}
      >
        {'>'}
      </button>
    </div>
  );
};

export default DMPropertyIncrementor;
