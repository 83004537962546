import React, { type FC } from 'react'
import BasicTable from '../common/BasicTable';
import { ValidateShowListUploadProps } from '../../utility/types';

const ShowListUpload: FC<ValidateShowListUploadProps> = ({ listUploadJson, validRowCount, errorRowCount, errorRowsJson, allRowsJson, mappableFieldsJson, basePath }) => {
  const listUpload = JSON.parse(listUploadJson);
  const errorRows = JSON.parse(errorRowsJson);
  const errorRowsKeys = Object.keys(errorRows);
  const allRows = JSON.parse(allRowsJson);
  const allRowsKeys = Object.keys(allRows);
  const columnMappingKeys = Object.keys(listUpload.column_mapping);
  const mappableFields = JSON.parse(mappableFieldsJson);
  const columnMappingObj = columnMappingKeys.map(key => {
    return ({
      "REIX Key": mappableFields[key],
      "CSV Key": listUpload.column_mapping[key]
    });
  });
  const allRowsObject = allRowsKeys.map(key => {
    return ({
      "CSV Row": key,
      "Existing Address": allRows[key]['address_exists'],
      "Valid": allRows[key]['valid'],
      "Formatted Address": allRows[key]['formatted_address'],
      "Errors": JSON.stringify(allRows[key]['errors']),
    });
  });
  const errorRowsObject = errorRowsKeys.map(key => {
    return ({
      "CSV Row": key,
      "Existing Address": errorRows[key]['address_exists'],
      "Valid": errorRows[key]['valid'],
      "Formatted Address": errorRows[key]['formatted_address'],
      "Errors": JSON.stringify(errorRows[key]['errors']),
    });
  });

  return (
    <div className="overflow-hidden">
      <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-base font-semibold leading-7 text-gray-900">Validate Parsing Results</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Do these addresses with your mappings look correct?</p>
        </div>
        <div className="flex items-center gap-x-4 sm:gap-x-6">
          <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Submit for parsing
          </button>
        </div>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">List name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{listUpload.list_name}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Column Mapping</dt>
            <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <BasicTable tableData={columnMappingObj as any} />
              <div className='w-full flex justify-end p-2'>
                <a href={`${basePath}/${listUpload.id}/edit`} className="text-indigo-600 hover:text-indigo-900">
                  Edit Mapping<span className="sr-only">, {listUpload.id}</span>
                </a>
              </div>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Valid row count</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{validRowCount}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Error row count</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{errorRowCount}</dd>
          </div>
          {Object.keys(errorRows).length > 0 &&
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Error rows</dt>
              <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <BasicTable tableData={errorRowsObject as any} />
              </dd>
            </div>
          }
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">All rows</dt>
            <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <BasicTable tableData={allRowsObject as any} />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default ShowListUpload;
