import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteListUpload } from '../../../api/ListUpload/deleteListUpload';

export function useDeleteListUpload() {
  const queryClient = useQueryClient();

  const deleteListUploadMutation = useMutation({
    mutationFn: (listUploadId: string) => deleteListUpload(listUploadId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getListUploads' ] });
    },
  });

  return deleteListUploadMutation;
}
