import React, { type FC } from 'react';
import { formatFloat } from '../../../utility/utility_functions';

export type QuickNetSheetTableProps = {
  propertyDetails: any;
  decisionFormData: any;
};

const tdStyling = 'py-2 px-5 text-center text-xs';
const thStyling = 'py-3 px-3 text-semibold bg-indigo-600 text-xs text-white whitespace-nowrap text-center';

const QuickNetSheetTable: FC<QuickNetSheetTableProps> = ({ propertyDetails, decisionFormData }) => {
  let sqFt = propertyDetails?.sqft;
  let fixedCosts = decisionFormData?.fix_costs;

  const getFixedCostsPerSqFt = () => {
    if (fixedCosts != null && sqFt != null) {
      return (fixedCosts / sqFt);
    }
  };

  const getTotalGrossPerSqFt = () => {
    let totalGross = decisionFormData?.estimated_profit;
    if (totalGross != null && sqFt != null) {
      return (totalGross / sqFt);
    }
  };

  const getCashAllIn = () => {
    let maxBid = decisionFormData?.max_bid;
    if (maxBid != null && sqFt != null && fixedCosts != null) {
      return (maxBid + fixedCosts);
    }
  };

  const getCashAllInPerSqFt = () => {
    if (getCashAllIn() != null && sqFt != null) {
      return (getCashAllIn() / sqFt);
    }
  };

  return (
    <div className='h-full justify-center mx-auto rounded-lg border'>
      <table className='w-fit text-center rounded-lg'>
        <thead className='divide-y divide-gray-300'>
          <tr>
            <th className={`${thStyling} rounded-tl-lg`}>
              Expected Sale Amount
            </th>
            <td className={tdStyling}>
              {decisionFormData?.estimated_value != null ? `\$${formatFloat(decisionFormData?.estimated_value, 2)}` : '-'}
            </td>
            <td className={tdStyling}>
              {decisionFormData?.estimated_value_per_sqft != null ? `\$${formatFloat(decisionFormData?.estimated_value_per_sqft, 2)} / Sqft` : '-'}
            </td>
          </tr>
          <tr>
            <th className={thStyling}>
              Purchase Price (Investment)
            </th>
            <td className={tdStyling}>
              {decisionFormData?.max_bid != null ? `\$${formatFloat(decisionFormData?.max_bid, 2)}` : '-'}
            </td>
            <td className={tdStyling}>
              {decisionFormData?.max_bid_per_sqft != null ? `\$${formatFloat(decisionFormData?.max_bid_per_sqft, 2)} / Sqft` : '-'}
            </td>
          </tr>
          <tr>
            <th className={thStyling}>
              Fix Costs Estimate
            </th>
            <td className={tdStyling}>
              {decisionFormData?.fix_costs != null ? `\$${formatFloat(decisionFormData?.fix_costs, 0)}` : '-'}
            </td>
            <td className={tdStyling}>
              {getFixedCostsPerSqFt() != null ? `\$${formatFloat(getFixedCostsPerSqFt(), 2)} / Sqft` : '-'}
            </td>
          </tr>
          <tr>
            <th className={thStyling}>
              Closing Costs
            </th>
            <td className={tdStyling}>
              -
            </td>
            <td className={tdStyling}>
              -
            </td>
          </tr>
          <tr>
            <th className={thStyling}>
              Estimated Prorated Taxes
            </th>
            <td className={tdStyling}>
              -
            </td>
            <td className={tdStyling}>
              -
            </td>
          </tr>
          <tr>
            <th className={thStyling}>
              Cash All-In
            </th>
            <td className={tdStyling}>
              {getCashAllIn() != null ? `\$${formatFloat(getCashAllIn(), 0)}` : '-'}
            </td>
            <td className={tdStyling}>
              {getCashAllInPerSqFt() != null ? `\$${formatFloat(getCashAllInPerSqFt(), 2)} / Sqft` : '-'}
            </td>
          </tr>
          <tr>
            <th className={thStyling}>
              Total Gross
            </th>
            <td className={tdStyling}>
              {decisionFormData?.estimated_profit != null ? `\$${formatFloat(decisionFormData?.estimated_profit, 0)}` : '-'}
            </td>
            <td className={tdStyling}>
              {getTotalGrossPerSqFt() != null ? `\$${formatFloat(getTotalGrossPerSqFt(), 2)} / Sqft` : '-'}
            </td>
          </tr>
          <tr>
            <th className={`${thStyling} rounded-bl-lg`}>
              Gross ROI
            </th>
            <td className={tdStyling}>
              {decisionFormData?.roi != null ? `${formatFloat(decisionFormData?.roi, 2)}\%` : '-'}
            </td>
            <td className={tdStyling}>
              -
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default QuickNetSheetTable;
