
import React from 'react';
import { dateConverterYYYYMMDD } from '../../utility/utility_functions';
import { ListSortProps } from '../../utility/types';

export type ListSortLineItemProps = {
  listSort: ListSortProps;
  handleEditListSortButtonClick: (idParam, listSortToEditName, listSortToEditNotes, listSortToEditUploadName) => void;
  handleDeleteListSortButtonClick: (idParam, listSortToDeleteName) => void;
  handleContinueListSortButtonClick: (idParam) => void;
}

const ListSortLineItem: React.FC<ListSortLineItemProps> = ({ listSort, handleContinueListSortButtonClick, handleEditListSortButtonClick, handleDeleteListSortButtonClick }) => {
  const wantedSorted = listSort.list_sort_property_joins.filter((lj)=> lj.wanted === true).length;
  const notWantedSorted = listSort.list_sort_property_joins.filter((lj)=> lj.wanted === false).length;
  const totalSorted = wantedSorted + notWantedSorted;
  const totalUnsorted = listSort.total_properties - totalSorted;

  const onEditClick = (event) => {
    event.stopPropagation();
    handleEditListSortButtonClick(listSort.id, listSort.title, listSort.notes, listSort.list_upload_name);
  };

  const onDeleteClick = (event) => {
    event.stopPropagation();
    handleDeleteListSortButtonClick(listSort.id, listSort.title);
  };

  const generateListName = (listSort): string => {
    if (listSort.list_upload_name) {
      return listSort.list_upload_name;
    }

    if (listSort?.data_source && listSort?.data_source !== 'NONE') {
      return `${listSort.data_source} MLS List`;
    }

    if (listSort?.data_source && listSort?.data_source === 'NONE') {
      return listSort.data_source;
    }

    return 'All Properties';
  };

  return (
    <tr key={listSort.id} onClick={()=> handleContinueListSortButtonClick(listSort.id)} className='hover:bg-gray-100 hover:cursor-pointer'>
      <td className="whitespace-wrap px-3 py-5 text-sm text-gray-500 max-w-xs min-w-28 whitespace-normal">
        <div className="font-medium text-gray-900">{listSort.title}</div>
        <div className="mt-1 text-gray-500">List: {generateListName(listSort)}</div>
        <div className="mt-1 text-gray-500">{dateConverterYYYYMMDD(listSort.created_at)}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{totalUnsorted}</td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{wantedSorted}</td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notWantedSorted}</td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">N/A</td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">N/A</td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-center text-gray-500 hover:cursor-auto" onClick={(event) => event.stopPropagation()}>
        <button onClick={onEditClick} className="text-indigo-600 hover:text-indigo-200 px-2">Edit</button>
        <button onClick={onDeleteClick} className="text-indigo-600 hover:text-indigo-200 px-2">Delete</button>
      </td>
    </tr>
  );
};

export default ListSortLineItem;
