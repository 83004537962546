
import '../common/SideBySideDivs.css'; // Import CSS file for styling
import React, { useMemo, type FC } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { usePropertyViewerContext } from '../../contexts/PropertyViewer';
import { SortingSummaryProps } from '../../utility/types';

const SortingSummary: FC<SortingSummaryProps> = ({
  showListSortSortSummary,
  setShowListSortSortSummary,
  listSortId
}) => {
  const {
    activeListSortId,
    totalPropertiesCount,
    criteriaMatchedPropertiesCount,
    // propertyDecisionCounts, //for testing the object vs the next 4 states
    propertyDecisionCounts,
    mockReadyPropertiesCount,
    completedPropertiesCount
  } = usePropertyViewerContext();

  // Should i wrap this in something to optimize
  const propertyTotalsSorted = useMemo(() => ([
    { id: 'undecidedPropertiesCount', name: 'Unsorted sorted', val: propertyDecisionCounts.undecidedPropertiesCount },
    { id: 'unwantedPropertiesCount', name: 'Unwanted Properties', val: propertyDecisionCounts.unwantedPropertiesCount },
    { id: 'wantedPropertiesCount', name: 'Wanted Properties', val: propertyDecisionCounts.wantedPropertiesCount },
    { id: 'decidedPropertiesCount', name: 'Finished Sorting', val: propertyDecisionCounts.decidedPropertiesCount },
    { id: 'mockReadyPropertiesCount', name: 'Mock Ready', val: mockReadyPropertiesCount },
    { id: 'completedPropertiesCount', name: 'Completed', val: completedPropertiesCount },
  ]), [ propertyDecisionCounts ]);

  return (
    <>
      {showListSortSortSummary && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            border:'1px solid red',
            flex:1,
          }}
          className="left-div"
        >
          <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl min-w-400px">
            <div className="bg-indigo-700 px-4 py-6 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="text-base font-semibold leading-6 text-white">
                  {listSortId ? 'Sorted Summary' : 'Properties Summary'}
                </div>
                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setShowListSortSortSummary(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="relative flex-1 px-4 py-2 sm:px-6">
              <>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Total Properties</dt>
                      <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <span className="flex-grow">{totalPropertiesCount}</span>
                      </dd>
                    </div>
                    <div className="px-4 sm:px-0">
                      <h3 className="text-base text-center font-semibold leading-7 text-gray-900 py-2">Unsorted</h3>
                    </div>
                    <div>

                      <div id="meetSearchCriteria" className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Meet Applied Search Criteria</dt>
                        <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <span className="flex-grow text-center">{criteriaMatchedPropertiesCount}</span>
                          <span className="ml-4 flex-shrink-0">
                            <button type="button" className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
                    View (todo)
                            </button>
                          </span>
                        </dd>
                      </div>
                      <div id="doNotMeetSearchCriteria" className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Do Not Meet Applied Search Criteria</dt>
                        <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <span className="flex-grow text-center">{totalPropertiesCount - criteriaMatchedPropertiesCount}</span>
                          <span className="ml-4 flex-shrink-0">
                            <button type="button" className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
                    View (todo)
                              {/*TODO If click this, switch search criteria ? */}
                            </button>
                          </span>
                        </dd>
                      </div>
                    </div>
                    {listSortId && (
                      <div>
                        <div className="px-4 sm:px-0">
                          <h3 className="text-base text-center font-semibold leading-7 text-gray-900 py-2">Sorted</h3>
                        </div>
                        {propertyTotalsSorted.map((elem)=>{
                          return (
                            <div key={elem.id} className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">{elem.name}</dt>
                              <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow text-center">{elem.val}</span>
                                <span className="ml-4 flex-shrink-0">
                                  <button type="button" className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
                      Update (todo)
                                  </button>
                                </span>
                              </dd>
                            </div>
                          );
                        })}
                      </div>)
                    }
                  </dl>
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SortingSummary;
