import { useRef, useState } from 'react';

const useDebouncedIncrementor = (callback: (accumulatedClicks: number) => void, delay: number) => {
  const [ accumulatedClicks, setAccumulatedClicks ] = useState(0);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const incrementClicks = (value: number) => {
    setAccumulatedClicks(prev => prev + value);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      callback(accumulatedClicks + value);
      setAccumulatedClicks(0);
    }, delay);
  };

  return incrementClicks;
};

export default useDebouncedIncrementor;
