
import { useEffect, useState } from 'react';
import { createFavorite } from '../../../api/Favorite/createFavorite';
import { deleteFavorite } from '../../../api/Favorite/deleteFavorite';
import { useMutation } from '@tanstack/react-query';

export const useDeleteFavorite = () => {
  const deleteFavoriteMutation = useMutation({
    mutationFn: (propertyId: string) => deleteFavorite(propertyId)
  });

  return deleteFavoriteMutation;
};

export const useCreateFavorite = () => {
  const createFavoriteMutation = useMutation({
    mutationFn: (propertyId: string) => createFavorite(propertyId)
  });

  return createFavoriteMutation;
};

export const useFavoriteApi = () => {
  const [ error, setError ] = useState(null);
  const [ pending, setPending ] = useState(null);
  const [ response, setResponse ] = useState(null);

  const { data: deleteFavoriteResponse, mutateAsync: deleteFavorite, isError: deleteFavoriteIsError, error: deleteFavoriteError, isPending: deleteFavoritePending } = useDeleteFavorite();
  const { data: createFavoriteResponse, mutateAsync: createFavorite, isError: createFavoriteIsError, error: createFavoriteError, isPending: createFavoritePending } = useCreateFavorite();

  useEffect(()=>{
    if (!deleteFavoriteIsError && !createFavoriteIsError) {
      setError(null);
    }
  }, [ deleteFavoriteIsError, createFavoriteIsError ] );

  useEffect(()=> {
    if (deleteFavoriteIsError) {
      setError(deleteFavoriteError);
    }
  }, [ deleteFavoriteIsError ]);

  useEffect(()=> {
    if (createFavoriteIsError) {
      setError(createFavoriteError);
    }
  }, [ createFavoriteIsError ]);

  // Pending
  useEffect(()=>{
    if (!deleteFavoritePending && !createFavoritePending) {
      setPending(null);
    }
  }, [ deleteFavoritePending, createFavoritePending ] );

  useEffect(()=> {
    if (deleteFavoritePending) {
      setPending(deleteFavoritePending);
    }
  }, [ deleteFavoritePending ]);

  useEffect(()=> {
    if (createFavoritePending) {
      setPending(createFavoritePending);
    }
  }, [ createFavoritePending ]);
  // End Pending

  useEffect(()=>{
    if (!deleteFavoriteResponse && !createFavoriteResponse) {
      setResponse(null);
    }
  }, [ deleteFavoriteResponse, createFavoriteResponse ] );

  useEffect(()=> {
    if (deleteFavoriteResponse) {
      setResponse(deleteFavoriteResponse?.data);
    }
  }, [ deleteFavoriteResponse ]);

  useEffect(()=> {
    if (createFavoriteResponse) {
      setResponse(createFavoriteResponse?.data);
    }
  }, [ createFavoriteResponse ]);

  return { deleteFavorite, createFavorite, errorFavorite: error, pendingFavorite: pending, responseFavorite: response };
};
