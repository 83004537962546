
import React, { useState, useEffect } from 'react';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { useAppSettingsContext } from '../../contexts/AppSettings';

interface SizeOption {
    label: string;
    value: string;
}

export default function SizeSettings() {
  const { gridSize, setGridSize } = useAppSettingsContext();

  const [ sizeOptions ] = useState<SizeOption[]>([
    { label: 'Small', value: 'small' },
    { label: 'Normal', value: 'normal' },
    { label: 'Large', value: 'large' }
  ]);
  return (
    <div className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
      <h1>Size</h1>
      <div className="flex justify-content-center mb-4">
        <SelectButton value={gridSize} onChange={(e: SelectButtonChangeEvent) => e.value && setGridSize(e.value)} options={sizeOptions} />
      </div>
    </div>
  );
}
