import React, { ChangeEvent, useState, type FC, useEffect } from 'react';
import { formatFloat } from '../../utility/utility_functions';
import { LoadingSpinner } from '../icons/OurIcons';

type RepairCostsProps = {
  estimatedRepairCosts: any;
  savedFixedCosts: number;
  savedUserFixedCosts: number;
  onRepairCostUpdate: (repairInputValue: number, userFixedCosts: number) => void;
};

const RepairCosts: FC<RepairCostsProps> = ({ onRepairCostUpdate, estimatedRepairCosts, savedFixedCosts, savedUserFixedCosts }) => {
  const [ repairInputValue, setRepairInputValue ] = useState<number>(null);
  const [ userFixedCosts, setUserFixedCosts ] = useState<number>(null);
  const [ finalFixedCosts, setFinalFixedCosts ] = useState<number>(null);
  const [ selectedValueType, setSelectedValueType ] = useState<string>('reix');

  const estimatedFixedCosts = estimatedRepairCosts?.results?.estimated_fixed_costs;

  useEffect(() => {
    if (savedFixedCosts) {
      setFinalFixedCosts(savedFixedCosts); 
    } else {
      setFinalFixedCosts(estimatedFixedCosts);
    }

    if (savedUserFixedCosts) {
      setUserFixedCosts(savedUserFixedCosts);
    }

    setRepairInputValue(null);
  }, [ savedFixedCosts, savedUserFixedCosts, estimatedFixedCosts ]);

  useEffect(() => {
    if (finalFixedCosts === estimatedFixedCosts) {
      setSelectedValueType('reix');
    } else if (finalFixedCosts === userFixedCosts) {
      setSelectedValueType('user');
    }
  }, [ finalFixedCosts, estimatedFixedCosts, userFixedCosts ]);

  useEffect(() => {
    onRepairCostUpdate(finalFixedCosts, userFixedCosts);
  }, [ finalFixedCosts, userFixedCosts ]);

  const handleRepairInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = Number(e.target.value);
    if (e.target.value != '' && !isNaN(inputNumber)) {
      setRepairInputValue(inputNumber);
      setUserFixedCosts(inputNumber);
      setFinalFixedCosts(inputNumber);
      setSelectedValueType('user');
    } else {
      setRepairInputValue(null);
    }
  };

  const handleSelect = (value: string) => {
    setSelectedValueType(value);

    if (value === 'reix') {
      setFinalFixedCosts(estimatedFixedCosts);
    } else if (value === 'user') {
      setFinalFixedCosts(userFixedCosts);
    }
  };

  const handleClearInput = () => {
    setRepairInputValue(null);
    setUserFixedCosts(null);
    setSelectedValueType('reix');
    setFinalFixedCosts(estimatedFixedCosts);
  };

  if (!estimatedRepairCosts) {
    return (
      <div role="status">
        <LoadingSpinner />
      </div>
    );
  }

  const activeStyling = 'flex justify-center items-center border-2 rounded-xl p-3 mb-5 bg-indigo-600 bg-opacity-25 text-gray-700 hover:cursor-pointer';
  const inactiveStyling = 'flex justify-center items-center border-2 rounded-xl p-3 mb-5 hover:cursor-pointer';

  return (
    <div className='w-contain justify-center m-3 pt-1 pb-7'>
      <div>
        <h3 className='whitespace-nowrap text-semibold text-lg text-indigo-500 text-left m-3'>Repair Costs</h3>
        <hr className='mt-3 mb-4 mx-3' />
      </div>
      <div className='flex justify-center items-center align-middle'>
        <span className='text-base'>Fixed Costs: </span>
        <input
          type='number'
          value={repairInputValue ?? ''}
          placeholder='Value'
          onChange={(e) => handleRepairInputChange(e)}
          className='valuations-user-input text-sm rounded-md border border-gray-300 shadow-x ml-3'
        />
        <button type='reset' onClick={handleClearInput} className="rounded bg-indigo-600 ml-2 px-2 py-1.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          X
        </button>
      </div>
      <div className='grid grid-cols-2 gap-x-5 text-center pt-10 px-10 align-center'>
        <div onClick={() => handleSelect('user')} className={`${selectedValueType === 'user' ? `${activeStyling}` : `${inactiveStyling}`}`}>
          <div>
            <p className='text-center'>User Input Fixed Costs</p>
            <p className='pt-2 text-base font-bold'>{userFixedCosts != null ? `\$${formatFloat(userFixedCosts, 2)}` : '-'}</p>
          </div>
        </div>
        <div onClick={() => handleSelect('reix')} className={`${selectedValueType === 'reix' ? `${activeStyling}` : `${inactiveStyling}`}`}>
          <div>
            <p className='whitespace-nowrap'>REIX AVM Fixed Costs</p>
            <p className='pt-2 text-base font-bold'>${Intl.NumberFormat('en-US').format(estimatedFixedCosts)}</p>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center border-2 rounded-xl p-3 mb-5 mx-20'>
        <div>
          <p className='whitespace-nowrap'>Final Fixed Costs</p>
          <p className='pt-2 text-base font-bold'>${formatFloat(finalFixedCosts, 2)}</p>
        </div>
      </div>
    </div>
  );
};

export default RepairCosts;
