import { configureStore } from '@reduxjs/toolkit';
import favoriteReducer from './state/favoriteSlice';
import lspjReducer from './state/lspjSlice';
import columnsReducer from './state/columnsSlice';
import mapReducer from './state/mapSlice';
import avmMapReducer from './state/avmMapSlice';

export const store = configureStore({
  reducer: {
    favorite: favoriteReducer,
    lspj: lspjReducer,
    columns: columnsReducer,
    map: mapReducer,
    avmMap: avmMapReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
