import React, { ChangeEvent, useState, type FC, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { formatFloat } from '../../../utility/utility_functions';

type ValuationsUserInputProps = {
  soldAvmResult: any;
  propertyDetails: any;
  selectedInput: string;
  inputValue: number;
  arvEstimate: number;
  arvSqft: number;
  setUserEstimate: (number) => void;
  setUserEstimateSqft: (number) => void;
  setArvEstimate: (number) => void;
  setArvSqft: (number) => void;
  setInputValue: Function;
  setSelectedInput: Function;
  setActiveRow: (string) => void;
}

const inputOptions = [
  'Valuation Amount',
  '$/Sq Ft'
];

const ValuationsUserInput: FC<ValuationsUserInputProps> = ({
  soldAvmResult,
  propertyDetails,
  selectedInput,
  setSelectedInput,
  setInputValue,
  inputValue,
  setArvEstimate,
  setArvSqft,
  arvEstimate,
  arvSqft,
  setActiveRow,
  setUserEstimate,
  setUserEstimateSqft
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ number, setNumber ] = useState(inputValue || 0);

  useEffect(() => {
    if (!soldAvmResult || !propertyDetails) {
      return;
    }

    // Calculate $/sqft when user provides val amount
    if (selectedInput === 'Valuation Amount') {
      setArvEstimate(inputValue);
      setUserEstimate(inputValue);

      let dollPerSqFt = (inputValue / propertyDetails?.sqft);
      setArvSqft(dollPerSqFt);
      setUserEstimateSqft(dollPerSqFt);
    }
    // Calculate val amount when user provides $/sq ft
    else if (selectedInput === '$/Sq Ft') {
      setArvSqft(inputValue);
      setUserEstimateSqft(inputValue);

      let valAmount = (inputValue * propertyDetails?.sqft);
      setArvEstimate(valAmount);
      setUserEstimate(valAmount);
    }
  }, [ selectedInput, inputValue, soldAvmResult, propertyDetails ]);

  const handleSelectChange = (value: string) => {
    setSelectedInput(value);
    setIsOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Only allow numbers
    if (!isNaN(Number(value))) {
      setNumber(Number(value));
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setInputValue(number);
    }, 300); // Debounce time is 300ms

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ number ]);

  const handleClearInput = () => {
    // Clear user inputs and reset highlighted values to REIX estimates
    setInputValue(null);
    setUserEstimate(null);
    setUserEstimateSqft(null);
    setActiveRow('');
    setSelectedInput('');
    setArvEstimate(soldAvmResult?.result?.estimated_value);
    setArvSqft(soldAvmResult?.result?.estimated_dollar_per_sqft);
  };

  const renderGridItem = (title: string, value: string) => {
    return (
      <div className='flex justify-center items-center border-2 rounded-xl p-3 mb-5'>
        <div>
          <p className='text-xs'>{title}</p>
          <p className='pt-1 text-base font-bold'>{value ?? '-'}</p>
        </div>
      </div>
    );
  };

  const renderDropdown = () => {
    return (
      <div>
        <Listbox
          as="div"
          className="flex space-y-1"
          value={selectedInput}
          onChange={(value) => handleSelectChange(value)}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <span className="inline-block rounded-md shadow-sm">
                  <Listbox.Button
                    className="hover:cursor-pointer relative w-44 rounded-md border border-gray-300 bg-white pl-2 pr-7 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-indigo-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className="block truncate">
                      {selectedInput ? `${selectedInput}` : 'Select Input Type'}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>
                {isOpen && (
                  <Listbox.Options
                    static
                    className="absolute bg-white max-h-60 w-full rounded-md py-1 text-base leading-6 shadow-xs focus:outline-none sm:text-sm sm:leading-5 border z-50 text-left"
                  >
                    {inputOptions.map((option) => {
                      return (
                        <Listbox.Option key={option} value={option}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? 'text-white bg-indigo-600'
                                  : 'text-gray-900'
                              } cursor-pointer select-none relative py-2 pl-8 pr-4 z-50`}
                            >
                              <span
                                className={`${
                                  selectedInput === option
                                    ? 'font-semibold'
                                    : 'font-normal'
                                } block truncate`}
                              >
                                {option}
                              </span>
                              {selectedInput === option && (
                                <span
                                  className={`${
                                    active ? 'text-white' : 'text-indigo-600'
                                  } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                >
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                )}
              </div>
            </>
          )}
        </Listbox>
      </div>
    );
  };

  return (
    <div className='pb-5'>
      <div className='flex align-middle items-center justify-center mx-2 my-5 z-50'>
        <div>
          {renderDropdown()}
        </div>
        <div id="valuations-input-container" className="flex align-middle items-center ml-3">
          <input
            type='number'
            placeholder='Value'
            value={number}
            onChange={handleChange}
            className='text-sm rounded-md border border-gray-300 shadow-xs w-28'
          />
          <button type='reset' onClick={handleClearInput} className="rounded bg-indigo-600 ml-2 px-2 py-1.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            X
          </button>
        </div>
      </div>
      <div className='grid grid-cols-2 mx-5 gap-x-5 text-center'>
        {arvEstimate ? renderGridItem('ARV', `\$${formatFloat(arvEstimate, 0)}`) : renderGridItem('ARV', '-')}
        {arvSqft ? renderGridItem('ARV $/Sqft', `\$${formatFloat(arvSqft, 2)}`) : renderGridItem('ARV $/Sqft', '-')}
      </div>
    </div>
  );
};

export default ValuationsUserInput;
