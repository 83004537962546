import React, { type FC } from 'react';
import BasicTable from '../common/BasicTable';
import LinkedPropertiesTable from './LinkedPropertiesTable';
import { ShowListUploadProps } from '../../utility/types';
import ListUploadOptionsDropdown from './ListUploadOptionsDropdown';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a client
const queryClient = new QueryClient();

const ShowListUpload: FC<ShowListUploadProps> = ({ listUploadJson, propertyCount, mappableFieldsJson, basePath }) => {
  const listUpload = JSON.parse(listUploadJson);
  const lastUpdated = new Date(listUpload.updated_at);
  const lastSyncd = new Date(listUpload.last_sync_date);
  const columnMappingKeys = Object.keys(listUpload.column_mapping);
  const columnMappingObj = columnMappingKeys.map(key => {
    const mappableFields = JSON.parse(mappableFieldsJson);
    return ({
      'REIX Key': mappableFields[key],
      'CSV Key': listUpload.column_mapping[key]
    });
  });
  const listingStatus = listUpload.status.toLowerCase();
  let isProcessing = false;
  [ 'parsing', 'updating' ].forEach(state => {
    if (listingStatus.includes(state)) {
      isProcessing = true;
    }
  });

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-base font-semibold leading-7 text-gray-900">List Upload</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Details for file upload</p>
        </div>
        <div className="flex items-center gap-x-4 sm:gap-x-6">
          <QueryClientProvider client={queryClient}>
            <ListUploadOptionsDropdown listUpload={listUpload} isProcessing={isProcessing} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">List name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className='flex'>
                <p>{listUpload.list_name}</p>
                <a href={`${basePath}/${listUpload.id}/edit`} className="text-indigo-600 hover:text-indigo-900 px-4">
                  Edit<span className="sr-only">, {listUpload.id}</span>
                </a>
              </div>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Status</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{listUpload.status}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">File name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{listUpload.file_name}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Total CSV Row Count</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{listUpload.number_of_rows}</dd>
          </div>
          {
            listUpload.last_sync_date &&
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Last sync'd</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{lastSyncd.toString()}</dd>
            </div>
          }
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Last updated</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{lastUpdated.toString()}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Column Mapping</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {Object.keys(columnMappingObj).length > 0 ?
                <BasicTable tableData={columnMappingObj as any} /> :
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Please provide column mapping</dd>
              }

              <div className='w-full flex justify-end p-2'>
                <a href={`${basePath}/${listUpload.id}/edit`} className="text-indigo-600 hover:text-indigo-900">
                  Edit Mapping<span className="sr-only">, {listUpload.id}</span>
                </a>
              </div>
            </dd>
          </div>
          {listUpload.parsing_results.error_details != '{}' &&
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Parsing Errors</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-h-[200px] overflow-y-auto">
                {listUpload.parsing_results.error_details}
              </dd>
            </div>
          }
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt>
              <h3 className="text-sm font-semibold leading-7 text-gray-900">List Properties</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Total of {propertyCount} linked properties</p>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <LinkedPropertiesTable basePath={basePath} listUploadId={listUpload.id} />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ShowListUpload;
