import React, { useState, type FC } from 'react';
import { MarkerProps } from '../../utility/types';

const MarkerPopup = () => {
  const [ showPopup, setShowPopup ] = useState(true);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <> {showPopup && (
      <div
        className="popup"
        style={{
          backgroundColor: 'yellow',
          width: '100px',
          height: '100px',
          padding: '10px',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <span>test test </span>
        <button onClick={handleClose}>X</button>
      </div>
    )}
    </>
  );
};

const Marker: FC<MarkerProps> = ({ text }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <div
        style={{
          position: 'relative',
          color: 'white',
          background: 'green',
          height: 40,
          width: 60,
          top: -20,
          left: -30,
          borderRadius: '20px 20px 0',
          transform: 'rotate(-45deg)',
          textAlign: 'center',
          fontSize: 12,
          fontWeight: 300,
        }}
        onClick={handleClick}
      >
        {text}

      </div>
      {isOpen && (
        <div onClick={() => setIsOpen(false)}>
          <MarkerPopup />
        </div>)}
    </div>
  );
};

export default Marker;
