/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { type FC } from 'react';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import GlobalPropertySearch from './GlobalPropertySearch';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reixLogo from 'images/reix_logo.png';

const queryClient = new QueryClient();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  headerLinks: Array<{id: string, title: string, route: string, active: boolean}>,
  profileLinks: Array<{id: string, title: string, route: string}>,
  profile: {id: string, name: string, email: string, avatar: string}
}

const NavBar: FC<Props> = ({ headerLinks, profileLinks, profile }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div id='nav-bar-parent' className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="/">
                      <img
                        className="block h-10 w-auto lg:hidden"
                        src={reixLogo}
                        alt="REIX"
                      />
                    </a>
                    <a href="/">
                      <img
                        className="hidden h-10 w-auto lg:block"
                        src={reixLogo}
                        alt="REIX"
                      />
                    </a>
                  </div>
                  <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                    { headerLinks.map((link) => {
                      let classes = 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium';
                      if (link.active) {
                        classes += ' text-gray-900 border-indigo-500';
                      } else {
                        classes += ' text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700';
                      }
                      return (
                        <a
                          id={link.id}
                          key={link.id}
                          href={link.route}
                          className={classes}
                        >
                          {link.title}
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div id='global-property-search-parent-container' className='flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end'>
                  <GlobalPropertySearch />
                </div>
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                  { false &&
                  <button
                    type="button"
                    className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  }
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={profile.avatar}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 min-w-[10rem] origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <div className="px-4">
                            <div className="text-base font-medium text-gray-800">{profile.name}</div>
                            <div className="text-sm font-medium text-gray-500">{profile.email}</div>
                          </div>
                        </Menu.Item>
                        { profileLinks.map(link => {
                          return (
                            <Menu.Item key={link.id + '-item'}>
                              {({ active }) => (
                                <a
                                  id={link.id}
                                  key={link.id}
                                  href={link.route}
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  {link.title}
                                </a>
                              )}
                            </Menu.Item>
                          );
                        })}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 pb-3 pt-2">
                { headerLinks.map((link) => {
                  let classes = 'block border-l-4 py-2 pl-3 pr-4 text-base font-medium';
                  if (link.active) {
                    classes += ' bg-indigo-50 text-indigo-700 border-indigo-500';
                  } else {
                    classes += ' border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800';
                  }
                  return (
                    <Disclosure.Button
                      as="a"
                      id={link.id}
                      key={link.id}
                      href={link.route}
                      className={classes}
                    >
                      {link.title}
                    </Disclosure.Button>
                  );
                })}
              </div>
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={profile.avatar}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{profile.name}</div>
                    <div className="text-sm font-medium text-gray-500">{profile.email}</div>
                  </div>
                  { false &&
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  }
                </div>
                <div className="mt-3 space-y-1">
                  { profileLinks.map(link => {
                    return (
                      <Disclosure.Button
                        as="a"
                        id={link.id}
                        key={link.id}
                        href={link.route}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        {link.title}
                      </Disclosure.Button>
                    );
                  })}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </QueryClientProvider>
  );
};

export default NavBar;
