import React, { useEffect, useState } from 'react';
import { SearchStringComponentProps } from './utility';
import { XMarkIcon } from '@heroicons/react/20/solid';

const SearchStringComponent: React.FC<SearchStringComponentProps> = ({
  id,
  query,
  defaultQuery,
  title,
  description,
  onUpdate,
  className
}) => {
  const [ inputValue, setInputValue ] = useState(query);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onUpdate(id, { query: inputValue });
    }, 300); // Debounce time is 300ms

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ inputValue ]);

  useEffect(() => {
    // This is triggered when the filter is reset from the filtering context
    if (query !== inputValue) {
      setInputValue(query);
    }
  }, [ query ]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div id="search-string-container" className="relative w-80">
      <label htmlFor="input-group-search" className="sr-only">Search</label>
      <div id='label-and-input-container' className="relative">
        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <input
          type="text"
          value={inputValue}
          onChange={handleSearchChange}
          placeholder="Search list"
          className="block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
        />
        { (inputValue.length > 0) && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
            <XMarkIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" onClick={() => setInputValue('')} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchStringComponent;
