import { useQuery } from '@tanstack/react-query';
import { getPropertyAvmResults } from '../../../api/AvmResults/getPropertyAvmResults';

export default function useGetPropertyAvmResults(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getPropertyAvmResults', propertyId ],
    queryFn: () => getPropertyAvmResults(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    console.error('There was an error in useGetPropertyAvmResults ', getQuery.error.message);
  }

  return {
    avmResults: getQuery.data?.data || null,
    errorAvmResults: getQuery.isError ? getQuery.error : null,
    isLoadingAvmResults: getQuery.isLoading,
  };
}