import React, { useEffect, type FC  } from 'react';
import useGetEstimatedRepairCosts from '../../../hooks/api/EstimatedRepairCosts/useGetEstimatedRepairCosts';

type EstimatedRepairCostsProps = {
  propertyDetails: any;
};

const EstimatedRepairCosts: FC<EstimatedRepairCostsProps> = ({ propertyDetails }) => {

  const { estimatedRepairCosts, errorEstimatedRepairCosts, isLoadingEstimatedRepairCosts } = useGetEstimatedRepairCosts(propertyDetails.id);

  useEffect(() => {
  }, [ estimatedRepairCosts ]);

  if (!estimatedRepairCosts) {
    return (
      <div className='shadow-xl text-center py-10 px-10 rounded-xl bg-blend-darken'>
        <p className='font-bold'>Estimated Repair Costs for this property are unavailable at this time</p>
      </div>
    );
  } else {
    return (
      <div className='grid grid-cols-2 bg-blend-darken mx-80 shadow-xl text-center pt-10 pb-5 px-10 align-center rounded-xl'>
        <span>
          <hr />
          <h6 className='pt-4 '>Estimated Repair Costs</h6>
          <h5 className='py-5 text-3xl font-extrabold'>${Intl.NumberFormat('en-US').format(parseInt(estimatedRepairCosts?.results?.estimated_fixed_costs))}</h5>
        </span>
        <span className='pl-20'>
          <hr />
          <h6 className='pt-4 text-nowrap'>Estimated Repair Costs $/Sq Ft</h6>
          <h5 className='py-5 text-3xl font-extrabold'>${parseInt(Intl.NumberFormat('en-US').format(estimatedRepairCosts?.results?.estimated_fixed_costs_dollar_per_sqft))}</h5>
        </span>
      </div>
    );
  }
};
export default EstimatedRepairCosts;
