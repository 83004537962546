import React, { FC } from 'react';

import { XCircleIcon } from '@heroicons/react/20/solid';

export type ErrorBannerProps = {
  errors: Record<string, string[]>;
}

const ErrorBanner: FC<ErrorBannerProps> = ({ errors }) => {
  const errorKeys = Object.keys(errors);
  const errorCount = errorKeys.length;
  const renderErrorTitleText = () => {
    var errorText = 'was 1 error';
    if (errorCount > 1) {
      errorText = `were ${errorCount} errors`;
    }
    return `There ${errorText} with your submission`;
  };

  const formatErrors = () => {
    const errorStrings = [];

    errorKeys.forEach((errorKey) => {
      const errorMessages = errors[errorKey];
      errorMessages.forEach((errorMessage) => {
        const errorString = `The ${errorKey} ${errorMessage}`;
        errorStrings.push(errorString);
      });
    });

    return errorStrings;
  };

  if (errorCount == 0) {
    return <></>;
  }

  return (
    <>
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{renderErrorTitleText()}</h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                {formatErrors().map((errorMessage) => <li>{errorMessage}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorBanner;
