import React from 'react';
import { FavoriteIcon_active, FavoriteIcon_inactive } from '../icons/OurIcons';
import { FavoriteTriStateSelectorProps } from './Components/filters/utility';
import TriStateCheckboxComponent from '../common/TriStateCheckboxComponent';

export const renderFavoriteStatusIcon = (value) => {
  switch (value) {
  case true:
    return <FavoriteIcon_active />;
  case false:
    return <FavoriteIcon_inactive />;
  default:
    return <FavoriteIcon_active />;
  }
};

export const favoriteStatusItemTemplate = (iconValue, title) => {
  return (
    <div className="gap-2 pl-2" >
      {renderFavoriteStatusIcon(iconValue)}
    </div>
  );
};

const favoriteData =   {
  title: 'Favorite Status',
  id: 'favorite',
  itemTemplate: favoriteStatusItemTemplate,
};

const FavoriteTriStateSelector: React.FC<FavoriteTriStateSelectorProps> = ({
  id,
  title,
  description,
  state,
  onUpdate,
}) => {
  const handleValuesChange = (id, newState) => {
    onUpdate(id, { state: newState });
  };

  return (
    <div key={favoriteData.id} className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
      <div className="flex justify-content-center" style={{ padding: '0 !important' }}>
        <TriStateCheckboxComponent
          title={title}
          id={id}
          onValuesChange={handleValuesChange}
          itemTemplate={favoriteData.itemTemplate}
          value={ state }
        />
      </div>
    </div>
  );
};

export default FavoriteTriStateSelector;
