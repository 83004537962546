import React from 'react';
import { IconHouse, IconHouseFill } from '../../icons/OurIcons';

const PropertyDetailsButton = ({ rowData, onClick, isActive }) => {
  return (
    <button
      className="whitespace-nowrap py-2 text-sm text-gray-500 cursor-pointer"
      onClick={() => onClick(rowData)}
    >
      {isActive ? <IconHouseFill/ > : <IconHouse />}
    </button>
  );
};
export default PropertyDetailsButton;
