import { api } from '../configs/axiosConfigs';

export const getApproaches = async () => {
  return await api('/approaches');
};

export type approachProps = {
  approach: {
    name: string,
    purchase_percent_of_value: number,
    sale_cost_percent: number,
    fixed_cost_calculation: string,
    priority_liens_calculation: string
  }
};

export const createApproach = async (requestBody: approachProps) => {
  return await api.post('/approaches', requestBody);
};

export const updateApproach = async (approachId: string, requestBody: approachProps) => {
  return await api.put(`/approaches/${approachId}`, requestBody);
};

export const deleteApproach = async (approachId: string) => {
  return await api.delete(`/approaches/${approachId}`);
};
