
import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { BooleanToggleComponentProps } from './utility';
import Switch from '@mui/material/Switch';

const BooleanToggleComponent: React.FC<BooleanToggleComponentProps> = ({
  id,
  title,
  description,
  currValue,
  onUpdate,
}) => {

  const handleToggle = (event) => {
    const newValue = event.target.checked;

    onUpdate(id, { state: newValue });
  };

  return (
    <div key={`BooleanToggle-${id}`} className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
      <Switch
        checked={currValue}
        onChange={handleToggle}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  );
};

export default BooleanToggleComponent;
