import { useQuery } from '@tanstack/react-query';
import { getSavedSearchCriteria } from '../../../api/SearchCriteria/getSavedSearchCriteria';

export default function useGetSavedSearchCriteria() {
  const getQuery = useQuery({
    queryKey: [ 'getSavedSearchCriteria' ],
    queryFn: ()=> getSavedSearchCriteria()
  });

  if (getQuery.isError) {
    //   throw new Error(getQuery.error.message);
    console.error('There was an error in getSavedSearchCriteria. Error: ', getQuery.error.message);
  }

  return {
    savedSearchCriteria: getQuery.data?.data|| null, // Access response.data
    errorSavedSearchCriteria: getQuery.isError ? getQuery.error : null, // Access error
    isLoadingSavedSearchCriteria: getQuery.isLoading,
  };
}
