import React, { useEffect, type FC } from 'react';
import CustomListDataViewer from './CustomListDataViewer';

export type MyDataWrapperProps = {
  mapKey: string;
  selectedOption: string;
  propertyDetails: any;
  soldAvmResult: any;
}

const MyDataWrapper: FC<MyDataWrapperProps> = ({
  mapKey,
  propertyDetails,
  soldAvmResult,
  selectedOption
}) => {
  const renderSelectedOption = () => {
    switch (selectedOption) {
    case 'My List Data': {
      return <CustomListDataViewer propertyId={propertyDetails?.id}/>;
    }
    default: {
      return null;
    }
    }
  };

  return (
    <div className='max-h-full'>
      {renderSelectedOption()}
    </div>
  );
};

export default MyDataWrapper;
