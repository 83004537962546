import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { MultiStateSelectSelectorComponentProps } from './utility';
import { msOptionInterface } from '../../../../contexts/Filtering';

const defaultTemplate = (option: msOptionInterface) => {
  return (
    <div className="flex align-items-center gap-2">
      <span>{option.name}</span>
    </div>
  );
};

// The generic MultiStateSelectorComponent component
const MultiStateSelectorComponent: React.FC<MultiStateSelectSelectorComponentProps> = ({
  id,
  title,
  description,
  state,
  options,
  itemTemplate = defaultTemplate,
  selectedLabelName = 'name',
  onUpdate
}) => {
  const handleChange = (e: any) => {
    onUpdate(id, { state: e.value });
  };

  return (
    <MultiSelect
      pt={{ root: { className: 'md:w-full' } }}
      id={id}
      value={state}
      itemTemplate={itemTemplate}
      options={options}
      onChange={handleChange}
      optionLabel={selectedLabelName}
      placeholder="Any"
      className="p-column-filter"
      maxSelectedLabels={3}
    />
  );
};

export default MultiStateSelectorComponent;
