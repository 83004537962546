import React, { useState, FC } from 'react';
import GetMoreDataDropdown from '../GetMoreDataDropdown';

export type GetMoreDataPaidUsersProps = {
  mapKey: string;
  soldAvmResult: any;
  propertyDetails: any;
}

const GetMoreDataPaidUsers: FC<GetMoreDataPaidUsersProps> = ({ mapKey, soldAvmResult, propertyDetails }) => {

  const [ isPaidUsersDropdownOpen, setIsPaidUsersDropwdownOpen ] = useState(false);
  const [ paidUsersSelectedOption, setPaidUsersSelectedOption ] = useState('');

  const optionsListPaidUsers = [
    'Current Owner',
    'Property Taxes Owed',
    'Sales History',
    'Permit Data',
    'Historical Value',
    'Mortgage History',
    'Other Lien Data',
    'Building Data',
    'Financial Estimates',
  ];

  function handlePaidUsersDropdownSelect(value) {
    setPaidUsersSelectedOption(value);
    setIsPaidUsersDropwdownOpen(false);
  }

  return (
    <>
      <div className="max-w-full mt-14 border-t border-gray pl-6 content-center justify-center">
        <GetMoreDataDropdown
          title="More Data Options - Paid Users"
          optionsList={optionsListPaidUsers}
          setIsOpen={setIsPaidUsersDropwdownOpen}
          isOpen={isPaidUsersDropdownOpen}
          handleSelect={handlePaidUsersDropdownSelect}
          selectedOption={paidUsersSelectedOption}
        />
      </div>
    </>
  );
};

export default GetMoreDataPaidUsers;
