import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSavedSearchCriteria } from '../../../api/SearchCriteria/deleteSavedSearchCriteria';

export const useDeleteSavedSearchCriteria = () => {
  const queryClient = useQueryClient();

  const deleteSavedSearchCriteriaMutation = useMutation({
    mutationFn: (searchCriteriaId: string) => deleteSavedSearchCriteria(searchCriteriaId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getSavedSearchCriteria' ] });
    },
  });

  return {
    deleteSavedSearchCriteria: deleteSavedSearchCriteriaMutation.mutateAsync,
    errorDeleteSavedSearchCriteria: deleteSavedSearchCriteriaMutation.isError ? deleteSavedSearchCriteriaMutation.error : null,
    pendingDeleteSavedSearchCriteria: deleteSavedSearchCriteriaMutation.isPending,
  };
};
