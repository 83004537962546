import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createApproach, approachProps } from '../../../api/Approaches/approaches';

export function useCreateApproach() {
  const queryClient = useQueryClient();

  const createApproachMutation = useMutation({
    mutationFn: (body: approachProps) => createApproach(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getApproaches' ] });
    },
  });

  return createApproachMutation;
}
