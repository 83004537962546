import React, { type FC, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DecisionMakingContextProvider } from '../../contexts/DecisionMaking';

import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import FinalDecisionMakingSubHeader from './FinalDecisionMakingSubHeader';
import FinalDecisionMakingContainer from './FinalDecisionMakingContainer';
import { useGetListSorts } from '../../hooks/api/ListSort/useGetListSorts';
import ViewHeader from '../common/ViewHeader';
import { ListSortProps } from '../../utility/types';
import { LoadingSpinner } from '../icons/OurIcons';
import { getKeyValueFromSessionStorage } from '../../utility/utility';

const queryClient = new QueryClient();

export default function FinalDecisionMakingViewer() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <DecisionMakingContextProvider>
          <FinalDecisionMakingViewerChild />
        </DecisionMakingContextProvider>
      </QueryClientProvider>
    </Provider>
  );
};

const FinalDecisionMakingViewerChild: FC = () => {
  const { data: listSorts, isLoading, error } = useGetListSorts();
  const [ activeListSort, setActiveListSort ] = useState<ListSortProps>(null);

  useEffect(() => {
    if (listSorts && listSorts.length > 0) {
      const activeListSortId = getKeyValueFromSessionStorage('DM-settings-storage', 'activeListSort');
      if (activeListSortId) {
        const activeListSort = listSorts?.find((listSort) => listSort.id === activeListSortId);
        if (activeListSort) {
          setActiveListSort(activeListSort);
        }
      }
      else {
        setActiveListSort(listSorts[0]);
      }
    }
  }, [ listSorts ]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    ); // Styled loading state
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-4">
        Error fetching list sorts
      </div>
    ); // Styled error state
  }

  return (
    <div className='w-screen max-h-full top-0 pb-10 text-xs justify-center'>
      <ViewHeader title='Final Decision Making' />
      <FinalDecisionMakingSubHeader
        listSorts={listSorts}
        activeListSort={activeListSort}
        setActiveListSort={setActiveListSort}
      />
      <hr className='w-full border-1 border-solid border-gray-200' />
      <FinalDecisionMakingContainer activeListSort={activeListSort} />
    </div>
  );
};
