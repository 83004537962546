import React, { type FC } from 'react';
import { formatFloat } from '../../../../utility/utility_functions';

export type AfterRepairValuesProps = {
  propertyDetails: any;
  soldAvmResult: any;
}

const lineItemsStyling = 'text-xs pb-3 font-extrabold';
const smallTxtStyling = 'text-xs pt-1 pb-2 whitespace-nowrap';
const avmGridContainerStyling = 'text-center mt-2';
const avmGridStyling = 'grid grid-cols-2 gap-x-5 text-center pt-2';
const avmGridHeaderStyling = 'whitespace-nowrap text-extrabold text-md text-indigo-500';
const avmGridElStyling = 'flex justify-center items-center border-2 rounded-xl mt-2 mb-3';

const AfterRepairValues: FC<AfterRepairValuesProps> = ({ propertyDetails, soldAvmResult }) => {

  const getArvLowPerSqFt = (): string => {
    if (!soldAvmResult?.result?.low_value || !propertyDetails?.sqft) {
      return 'N/A';
    }

    return `$${((soldAvmResult?.result?.low_value / propertyDetails?.sqft).toFixed(0))}`;
  };

  const getArvHighPerSqFt = (): string => {
    if (!soldAvmResult?.result?.high_value || !propertyDetails?.sqft) {
      return 'N/A';
    }

    return `$${((soldAvmResult?.result?.high_value / propertyDetails?.sqft).toFixed(0))}`;
  };

  return (
    <div className='shadow-lg pt-5 px-5 rounded-xl'>
      <div>
        <div className='flex justify-between items-center'>
          <h3 className={avmGridHeaderStyling}>After Repair Values</h3>
        </div>
        <hr className='mt-3' />
      </div>
      <div className={avmGridContainerStyling}>
        <div className={avmGridStyling}>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>ARV Low</h6>
              <div className={lineItemsStyling}>${formatFloat(soldAvmResult?.result?.low_value, 0)}</div>
            </div>
          </div>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>ARV High</h6>
              <div className={lineItemsStyling}>${formatFloat(soldAvmResult?.result?.high_value, 0)}</div>
            </div>
          </div>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>ARV Low $/Sq Ft</h6>
              <div className={lineItemsStyling}>{getArvLowPerSqFt()}</div>
            </div>
          </div>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>ARV High $/Sq Ft</h6>
              <div className={lineItemsStyling}>{getArvHighPerSqFt()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AfterRepairValues;
