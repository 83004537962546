import React, { type FC } from 'react';
import { getAvmCompsForMap, getAvmTopSix } from '../../../../utility/utility_functions';
import AvmMap from '../../../common/AvmMap';
import ActiveAvmCompsTable from '../../../common/ActiveAvmCompsTable';
import PropertySummary from './PropertySummary';
import TopSoldAverages from './TopSoldAverages';
import AfterRepairValues from './AfterRepairValues';
import ActiveCompsAverages from './ActiveCompsAverages';
import SoldAvmCompsTable from '../../../common/SoldAvmCompsTable';
import TopMlsNumbers from './TopMlsNumbers';
import AvmCalculationDates from './AvmCalulationDates';

export type AvmContainerProps = {
  soldAvmResult: any;
  activeAvmResult: any;
  propertyDetails: any;
}

const AvmContainer: FC<AvmContainerProps> = ({ soldAvmResult, activeAvmResult, propertyDetails }) => {

  const compsSixSold = getAvmTopSix(soldAvmResult);
  const compsSixActive = getAvmTopSix(activeAvmResult);
  const compsFifteenSold = soldAvmResult?.comps?.slice(0, 15);
  const compsForMap = getAvmCompsForMap(compsSixActive, compsSixSold);

  return (
    <div className='overflow-x-hidden m-0 px-10'>
      <div className='flex whitespace-nowrap items-center px-3'>
        <div>
          <p className='uppercase text-2xl text-bold tracking-wide text-indigo-500'>Property Estimates & Comps</p>
        </div>
        <div className='w-full h-fit ml-5'>
          <hr />
        </div>
      </div>
      <div className='w-contain pt-4'>
        <div className='grid gap-x-6 grid-cols-1 md:grid-cols-2 mb-5'>
          <PropertySummary propertyDetails={propertyDetails} soldAvmResult={soldAvmResult} />
          <div className='shadow-md p-4 rounded-xl row-span-3'>
            <AvmMap property={propertyDetails} comps={compsForMap} />
          </div>
          <TopSoldAverages soldAvmResult={soldAvmResult} compsSixSold={compsSixSold} compsFifteenSold={compsFifteenSold} />
          <div className='grid gap-x-6 grid-cols-1 md:grid-cols-2'>
            <AfterRepairValues propertyDetails={propertyDetails} soldAvmResult={soldAvmResult} />
            <ActiveCompsAverages activeAvmResult={activeAvmResult} compsSixActive={compsSixActive} />
          </div>
        </div>
      </div>
      <div>
        <h3 className='whitespace-nowrap text-center text-extrabold text-lg mt-8 mb-3 text-indigo-500'>Top 6 Sold Comps</h3>
        <div className='shadow-xl rounded-xl overflow-x-auto w-contain mt-2 mb-10'>
          <SoldAvmCompsTable comps={compsSixSold} />
        </div>
        <div>
          <h3 className='whitespace-nowrap text-center text-extrabold text-lg mt-8 mb-3 text-green-500'>Top 6 Active Comps</h3>
          <div className='shadow-xl rounded-xl overflow-x-auto w-contain mb-10'>
            <ActiveAvmCompsTable comps={compsSixActive} />
          </div>
        </div>
      </div>
      <div>
        <TopMlsNumbers compsSixSold={compsSixSold} compsFifteenSold={compsFifteenSold}  />
        <AvmCalculationDates soldAvmResult={soldAvmResult} activeAvmResult={activeAvmResult} />
      </div>
    </div>
  );
};
export default AvmContainer;
