import { useQuery } from '@tanstack/react-query';
import { getPropertyDetails } from '../../../api/Property/getPropertyDetails';

export default function useGetPropertyDetails(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getPropertyDetails', propertyId ],
    queryFn: () => getPropertyDetails(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    //   throw new Error(getQuery.error.message);
    console.error('There was an error in getPropertyDetails ', getQuery.error.message);
  }

  return {
    propertyDetails: getQuery.data?.data || null, // Access response.data
    errorPropertyDetails: getQuery.isError ? getQuery.error : null, // Access error
    isLoadingPropertyDetails: getQuery.isLoading,
  };
}
