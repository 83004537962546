import { useQuery } from '@tanstack/react-query';
import { getMostRecentAvmResults } from '../../../api/AvmResults/getMostRecentAvmResults';

export default function useGetMostRecentAvmResults(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getMostRecentAvmResults', propertyId ],
    queryFn: () => getMostRecentAvmResults(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    console.error('There was an error in useGetMostRecentAvmResults ', getQuery.error.message);
  }

  return {
    mostRecentAvmResults: getQuery.data?.data || null,
    errorMostRecentAvmResults: getQuery.isError ? getQuery.error : null,
    isLoadingMostRecentAvmResults: getQuery.isLoading,
  };
}