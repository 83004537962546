import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAvmResults } from '../../../api/AvmResults/createAvmResult';

export function useCreateAvmResults() {
  const queryClient = useQueryClient();

  const createAvmResultsMutation = useMutation({
    mutationFn: (propertyId: string) => createAvmResults(propertyId),
    onSuccess: (propertyId) => {
      queryClient.invalidateQueries({ queryKey: [ 'getPropertyAvmResults', propertyId ] });
    },
  });

  return createAvmResultsMutation;
}