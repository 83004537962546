import React, { type FC } from 'react'
import { RadioButtonRowSelectProps } from '../../utility/types'

const notificationMethods = [
  { id: 'email', title: 'Email' },
  { id: 'sms', title: 'Phone (SMS)' },
  { id: 'push', title: 'Push notification' },
]

const RadioButtonRowSelect: FC<RadioButtonRowSelectProps> = ({ title, subtitle, options, onSelect, selected }) => {
  return (
    <div>
      <label className="text-base font-semibold text-gray-900">{title}</label>
      <p className="text-sm text-gray-500">{subtitle}</p>
      <fieldset className="mt-4">
        <legend className="sr-only">{title}</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {options.map((option) => (
            <div key={option} className="flex items-center">
              <input
                id={option}
                name="notification-method"
                type="radio"
                defaultChecked={selected == option}
                onChange={(event) => {
                  onSelect(event.target.id);
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label htmlFor={option} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                {option}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}

export default RadioButtonRowSelect;
