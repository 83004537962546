import React from 'react';
// potentially move to context
import { useAppSettingsContext } from '../../../../contexts/AppSettings';
import { InputSwitch } from 'primereact/inputswitch';

const RowSelectionToggle: React.FC<any> = ({}) => {
  const { isRowSelectionEnabled, toggleRowSelection } = useAppSettingsContext();

  return (
    <div className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
      <div className="flex justify-content-center" style={{ padding: '0 !important' }}>
        <div className="flex justify-content-center align-items-center mb-4 gap-2">
          <InputSwitch inputId="input-rowclick" checked={isRowSelectionEnabled} onChange={(e) => toggleRowSelection(e.value)} />
          <label htmlFor="input-rowclick">Row Click</label>
        </div>
      </div>
    </div>
  );
};

export default RowSelectionToggle;
