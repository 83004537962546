import React, { FC, useState } from 'react';
import { useGetApproaches } from '../../../hooks/api/Approaches/useGetApproaches';
import ApproachFormModel from './ApproachFormModel';

const ListApproaches: FC = () => {
  const [ formModalOpen, setFormModalOpen ] = useState(false);
  const [ currentApproach, setCurrentApproach ] = useState(null);
  const { data: approaches, refetch: refetchApproaches, isLoading } = useGetApproaches();

  const formatApproachType = (approach: any): string => {
    const conditionCount = approach.approach_conditions?.length ?? 0;
    if (conditionCount > 0) {
      return 'Conditioned Approach';
    }

    return 'Unconditional Approach';
  };

  const renderTableBody = () => {
    if (isLoading) {
      return renderApproachLoader();
    }
    if (approaches.length > 0) {
      return renderTable();
    }
    return renderEmptyApproaches();
  };

  const renderApproachLoader = () => {
    return Array.from({ length: 5 }).map((_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: 4 }).map((_, colIndex) => (
          <td key={colIndex} className="py-4 px-3">
            <div className="h-4 bg-gray-200 animate-pulse shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"></div>
          </td>
        ))}
      </tr>
    ));
  };

  const renderTable = () => {
    return (
      <>
        {approaches.map((approach) => (
          <tr
            key={approach.id}
            className="hover:cursor-pointer hover:bg-gray-50"
            onClick={() => {
              setCurrentApproach(approach);
              setFormModalOpen(true);
            }}
          >
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
              {approach.name}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatApproachType(approach)}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{(approach.purchase_percent_of_value * 100).toFixed(0)}%</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{(approach.sale_cost_percent * 100).toFixed(1)}%</td>
          </tr>
        ))}
      </>
    );
  };

  const renderEmptyApproaches = () => {
    return (
      <tr>
        <td colSpan={4} className="bg-white py-32 px-8">
          <div className="text-center justify-center items-center">
            <p className="text-md leading-8 text-gray-600">
              You don't have any approaches yet, create one to get started!
            </p>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="px-2">
      <ApproachFormModel modalOpen={formModalOpen} setModalOpen={setFormModalOpen} existingApproach={currentApproach}/>
      <div className="flex items-center">
        <div className="flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Investment Approaches</h1>
          <p className="mt-2 text-sm text-gray-700">
            Define investment strategies to help you decide which properties match your investing style.
            Each approach will be used to calculate the max bid for properties you're interested in.
          </p>
        </div>
        <div className="mt-4 ml-16 flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              setCurrentApproach(null);
              setFormModalOpen(true);
            }}
          >
            Add Approach
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 overflow-x-auto -mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Purchase Percent of Value
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Sales Cost Percent
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {renderTableBody()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListApproaches;
