import React, { useState, useEffect, type FC, useRef } from 'react';
import { Loader, OpenOnNewPage } from '../icons/OurIcons';

import useGetPropertyDetails from '../../hooks/api/Property/useGetPropertyDetails';
import { getPropertyAddress, getPropertyImages } from '../../utility/utility_functions';

import FavoriteToggle from '../common/FavoriteToggle';
import '../../../assets/stylesheets/property-image-galleria.css';
import ModifyListSortMultiSelect from './ModifyListSortMultiSelect';
import PropertyGalleria from './PropertyGalleria';
import GetMoreDataAllUsers from './get-more-data-all-users/GetMoreDataAllUsers';
import GetMoreDataPaidUsers from './get-more-data-paid-users/GetMoreDataPaidUsers';
import GetMyDataOptionsPaidUsers from './get-my-data-options-paid-users/GetMyDataOptionsPaidUsers';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setMapKey } from '../../redux/state/mapSlice';
import ImagesMapContainer from '../decision-making-page/ImagesMapContainer';
import PropertyCommentsModal from '../common/modals/property-comments/PropertyCommentsModal';
import { Tooltip } from 'primereact/tooltip';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import useGetMostRecentAvmResults from '../../hooks/api/AvmResults/useGetMostRecentAvmResults';
import PropertyDetailsSummary from './PropertyDetailsSummary';

export type PropertyDetailsProps = {
  propertyId: string;
  mapKey: string;
  favorited: boolean;
  pageView?: boolean;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PropertyDetails: FC<PropertyDetailsProps> = ({ propertyId, mapKey }) => {
  const [ activeAvmResult, setActiveAvmResult ] = useState(undefined);
  const [ soldAvmResult, setSoldAvmResult ] = useState(undefined);
  const [ propertyImages, setPropertyImages ] = useState([]);
  const [ isPropertyCommentsModalOpen, setIsPropertyCommentsModalOpen ] = useState(false);

  const galleriaRef = useRef(null);

  const { propertyDetails, isLoadingPropertyDetails } = useGetPropertyDetails(propertyId);
  const { mostRecentAvmResults, isLoadingMostRecentAvmResults, errorMostRecentAvmResults } = useGetMostRecentAvmResults(propertyId);
  const dispatch = useDispatch<AppDispatch>();

  const isLoading = isLoadingMostRecentAvmResults || isLoadingPropertyDetails;

  useEffect(() => {
    dispatch(setMapKey(mapKey));
  }, []);

  useEffect(() => {
    if (mostRecentAvmResults) {
      setActiveAvmResult(mostRecentAvmResults?.active_avm_result);
      setSoldAvmResult(mostRecentAvmResults?.sold_avm_result);
    }

    if (propertyDetails) {
      setPropertyImages(getPropertyImages(propertyDetails));
    }

  }, [ propertyDetails, mostRecentAvmResults ]);

  if (isLoading) {
    return (
      <div className='w-screen content-center px-10'>
        <div className='mx-auto mt-10 w-96'>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className='rounded-3xl'>
      <div className="bg-white py-4 sm:py-4 text-center items-center w-screen sm:items-center">
        <div className="xl:mx-16 max-w-full px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-center justify-center gap-x-32 gap-y-16 sm:gap-y-24 xs:items-center sm:items-center lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div>
              <div className='pb-5 lg:pr-4'>
                <ModifyListSortMultiSelect propertyId={propertyId} />
              </div>
              {!isLoading && <ImagesMapContainer
                avmResult={soldAvmResult}
                galleriaRef={galleriaRef}
                mlsPropertyImages={propertyImages}
                propertyId={propertyId}
                isLoading={isLoading}
                error={errorMostRecentAvmResults?.message}
              />}
            </div>
            <div className="text-base leading-7 text-gray-700 w-full items-center justify-center">
              <div className="flex items-center text-center align-middle justify-center">
                <h3 className="text-3xl font-bold tracking-tight text-indigo-600 sm:text-4xl">
                  Property Details
                </h3>
                <a className="pl-2" href={`/properties/${propertyDetails?.id}`} target='_blank'>
                  <OpenOnNewPage />
                </a>
              </div>
              <div className='flex my-6 text-center items-center align-middle justify-center'>
                <span className='pr-3'>
                  {getPropertyAddress(propertyDetails)}
                </span>
                <FavoriteToggle propertyId={propertyDetails?.id} />
                <div onClick={() => setIsPropertyCommentsModalOpen(true)} className='flex z-10 items-center cursor-pointer ml-1'>
                  <Tooltip target='.pencil-icon' className='bg-grey-500 border border-solid border-1 border-indigo-900 px-2 rounded-lg m-5 text-xs' />
                  <PencilSquareIcon
                    data-pr-tooltip="Property Notes"
                    data-pr-position="bottom"
                    data-pr-at="left+1 bottom"
                    data-pr-my="bottom center-2"
                    className='doc-icon w-7'
                  />
                </div>
              </div>
              <PropertyDetailsSummary soldAvmResult={soldAvmResult} propertyDetails={propertyDetails} />
            </div>
          </div>
        </div>
      </div>
      <div className='pb-52'>
        <GetMoreDataAllUsers soldAvmResult={soldAvmResult} activeAvmResult={activeAvmResult} propertyDetails={propertyDetails}/>
        <GetMoreDataPaidUsers mapKey={mapKey} soldAvmResult={soldAvmResult} propertyDetails={propertyDetails}/>
        <GetMyDataOptionsPaidUsers mapKey={mapKey} soldAvmResult={soldAvmResult} propertyDetails={propertyDetails}/>
      </div>
      <PropertyGalleria
        ref={galleriaRef}
        propertyImages={propertyImages}
      />
      {isPropertyCommentsModalOpen &&
          <PropertyCommentsModal
            isOpen={isPropertyCommentsModalOpen}
            propertyId={propertyId}
            setIsPropertyCommentsModalOpen={setIsPropertyCommentsModalOpen}
          />
      }
    </div>
  );
};

export default PropertyDetails;
