import React, { useState } from 'react';
import DeleteSearchCriteriaConfirmModal from './Components/modals/DeleteSearchCriteriaConfirmModal';
import { useDeleteSavedSearchCriteria } from '../../hooks/api/SearchCriteria/useDeleteSavedSearchCriteria';

const DeleteSearchCriteria = ({ criteriaTitle, criteriaId }) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const { deleteSavedSearchCriteria, errorDeleteSavedSearchCriteria, pendingDeleteSavedSearchCriteria } = useDeleteSavedSearchCriteria();

  const handleOpenDeleteModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle confirm deleting a saved search criteria
  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    // Delete the criteria here
    deleteSavedSearchCriteria(criteriaId);
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="h-fit mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
        onClick={handleOpenDeleteModal}
      >
        Delete Search Criteria
      </button>
      {isModalOpen && (
        <DeleteSearchCriteriaConfirmModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          criteriaTitle={criteriaTitle}
        />
      )}
    </>
  );
};

export default DeleteSearchCriteria;
