import { useMutation } from '@tanstack/react-query';
import { UseUpdateListSortPropertyJoinProps, UpdateListSortPropertyJoinProps, WantedStatusPayloadType2 } from '../../../utility/types';
import { updateListSortPropertyJoin } from '../../../api/ListSortPropertyJoin/updateListSortPropertyJoin';

export const useUpdateListSortPropertyJoin = () => {
  const updateListSortPropertyJoinMutationFunction = ({ listSortId, propertyId, status }: UseUpdateListSortPropertyJoinProps) => {
    const requestBody: WantedStatusPayloadType2 = {
      list_sort_property_join: {
        wanted: status,
        notes: 'none',
      } };
    return  updateListSortPropertyJoin({ requestBody, listSortId, propertyId });
  };

  const updateListSortPropertyJoinMutation = useMutation({
    mutationFn: (props: UseUpdateListSortPropertyJoinProps) => updateListSortPropertyJoinMutationFunction(props)
  });

  return updateListSortPropertyJoinMutation;
};
