import React, { FC, useState } from 'react';
import {
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { classNames } from '../../utility/utility';
import ListApproaches from './approaches/ListApproaches';

// Create a client
const queryClient = new QueryClient();

export type ConfigurationDashboardProps = {
  profile: {
    name: string,
    email: string,
    avatar: string
  }
};

const ConfigurationDashboard: FC<ConfigurationDashboardProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigurationDashboardChild {...props} />
    </QueryClientProvider>
  );
};

const ConfigurationDashboardChild: FC<ConfigurationDashboardProps> = ({ profile }) => {
  const navigation = [
    { name: 'Approaches', icon: ChartPieIcon, component: <ListApproaches /> },
    { name: 'Property Valuations', icon: HomeIcon, component: null },
    { name: 'Team', icon: UsersIcon, component: null },
    { name: 'Fixed Costs', icon: FolderIcon, component: null },
    // { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    { name: 'Taxes', icon: DocumentDuplicateIcon, component: null },
  ];

  const [ activePage, setActivePage ] = useState(navigation[0]);

  const renderActivePage = () => {
    const activeComponent = activePage.component;
    if (activeComponent) {
      return activeComponent;
    }

    return (
      <>
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">{activePage.name}</h2>
            <p className="mt-6 text-md leading-8 text-gray-600">
              Ooooops, this page is still a work in progress... Don't worry though it's coming soon
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="fixed inset-y-0 flex w-72 flex-col z-10 pt-16">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 py-6">
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <h3 className='text-gray-900 text-lg leading-6 font-semibold py-6'>
                    Profile Configuration
                  </h3>
                </li>
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((page) => {
                      const pageIsActive = page.name === activePage.name;

                      return (
                        <li key={page.name} className="hover:cursor-pointer">
                          <a
                            onClick={() => setActivePage(page)}
                            className={classNames(
                              pageIsActive
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            <page.icon
                              className={classNames(
                                pageIsActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {page.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="/users/edit"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={profile.avatar}
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">{profile.name}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="py-4 lg:pl-72 -z-20">
          <div className="px-4 sm:px-6 lg:px-8">
            {renderActivePage()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigurationDashboard;
