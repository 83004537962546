import { useQuery } from '@tanstack/react-query';
import { getPropertyListSortPropertyJoins } from '../../../api/Property/getPropertyListSortPropertyJoins';

export default function useGetPropertyListSortPropertyJoins(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getPropertyListSortPropertyJoins', propertyId ],
    queryFn: () => getPropertyListSortPropertyJoins(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    console.error('There was an error in getPropertyListSortPropertyJoins ', getQuery.error.message);
  }

  return {
    listSortPropertyJoins: getQuery.data?.data || null, // Access response.data
    errorListSortPropertyJoins: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    refetch: getQuery.refetch
  };
}
