import React, { FC, Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useCreateListSort } from '../../hooks/api/ListSort/useCreateListSort';
import DeleteListUploadModal from './DeleteListUploadModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export type ListUploadOptionsDropdownProps = {
  listUpload: any,
  isProcessing: boolean
};

const ListUploadOptionsDropdown: FC<ListUploadOptionsDropdownProps> = ({ listUpload, isProcessing }) => {
  const [ deleteModelOpen, setDeleteModelOpen ] = useState(false);
  const createListSortMutation = useCreateListSort();

  useEffect(() => {
    if (createListSortMutation.isSuccess) {
      const listSortId = createListSortMutation?.data?.data?.list_sort?.id;
      if (listSortId) {
        window.location.href = `/list-sorts/${listSortId}`;
      } else {
        console.error(`List sort was not successfully created: ${createListSortMutation?.data}`);
      }
    }
  }, [ createListSortMutation.isSuccess ]);

  const createListSort = async () => {
    await createListSortMutation.mutateAsync({ list_sort: { title: listUpload.list_name, list_upload: listUpload.id, notes: '' } });
  };

  const renderPrimaryActionButton = () => {
    const buttonClasses = 'rounded-md bg-indigo-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600';

    if (createListSortMutation.isPending || createListSortMutation.isSuccess) {
      return (
        <div className={`animate-pulse hover:cursor-default ${buttonClasses}`}>
          Creating list sort...
        </div>
      );
    }

    if (!listUpload.parsed && !isProcessing) {
      return (
        <a href={`/list-uploads/${listUpload.id}/edit`} className={buttonClasses}>
          Complete list upload
        </a>
      );
    };

    const listSort = listUpload.list_sorts[0] ?? null;
    if (listSort) {
      return (
        <a href={`/list-sorts/${listSort.id}`} className={buttonClasses}>
          Continue list sort
        </a>
      );
    }

    return (
      <button onClick={() => createListSort()} className={buttonClasses}>
        Start list sort
      </button>
    );
  };

  const onDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteModelOpen(true);
  };

  const onDeleteModelClose = (success: boolean) => {
    if (success) {
      window.location.href = '/list-uploads';
    }
    setDeleteModelOpen(false);
  };

  return (
    <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
      <DeleteListUploadModal
        isOpen={deleteModelOpen}
        onClose={onDeleteModelClose}
        listUploadName={listUpload.list_name}
        listUploadId={listUpload.id}
      />
      {renderPrimaryActionButton()}
      <Menu as="div" className="relative ml-3 inline-block text-left">
        <div>
          <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {
                listUpload.parsed &&
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={`/list-uploads/${listUpload.id}.csv`}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex justify-between px-4 py-2 text-sm'
                      )}
                    >
                      <span>Download REIX CSV</span>
                    </a>
                  )}
                </Menu.Item>
              }
              {
                listUpload.parsed &&
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={`/list-uploads/${listUpload.id}/valid`}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex justify-between px-4 py-2 text-sm'
                      )}
                    >
                      <span>ReSync</span>
                    </a>
                  )}
                </Menu.Item>
              }
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onDeleteClick}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full flex justify-between px-4 py-2 text-sm'
                    )}
                  >
                    <span>Delete</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ListUploadOptionsDropdown;
