import React from 'react';
import { BooleanToggleValuesType, BooleanTriStateSelectorValuesType, DateRangeSelectorValuesType, FilterDescriptor, FilterValuesType, MultiSelectorValuesType, RangeValuesType, StringSelectorValuesType, useFilteringContext } from '../../../../contexts/Filtering'; // Adjust path as necessary

import TieredRangeSelectorComponent from './TieredRangeSelectorComponent';
import SearchStringComponent from './SearchStringComponent';
import BooleanTriStateSelectorComponent from './BooleanTriStateSelectorComponent';
import DualValueRangeCard from './DualValueRangeCard';
import RangeSliderComponent from '../../../common/RangeSliderComponent';
import MultiStateSelectorComponent from './MultiStateSelectorComponent';
import BooleanToggleComponent from './BooleanToggleComponent';
import WantedMultiSelector from '../../WantedMultiSelector';
import FavoriteTriStateSelector from '../../FavoriteTriStateSelector';
import { preprocessFilterData } from './utility';
import DateRangeSelector from '../../DateRangeSelector';

interface FilterFactoryProps {
  id: string;
  filterData: FilterDescriptor;
}

// look into usememoing for this or other ways to make it more efficient!
const FilterFactory = ({ id, filterData }: FilterFactoryProps) => {
  const { dispatch } = useFilteringContext();

  // Validate and normalize the filter data before processing
  const safeFilterData = preprocessFilterData(id, filterData);
  if (!safeFilterData) {
    console.error('Invalid filter data provided for ', filterData);
    return <div id={id}>Invalid filter data provided for </div>;
  }

  // Declare the updateFilter function accepting a partial of FilterValuesType
  const updateFilter = (id:string, values: Partial<FilterValuesType>) => {
    dispatch({
      type: 'UPDATE_FILTER',
      id: id,
      values: values
    });
  };

  switch (safeFilterData.filter_type) {
  case 'TieredRangeSelectorFilterType': {
    return <TieredRangeSelectorComponent
      id={id}
      min={safeFilterData.min}
      max={safeFilterData.max}
      title={safeFilterData.title}
      description={safeFilterData.description}
      unit={safeFilterData.unit}
      defaultMin={safeFilterData.defaultMin}
      defaultMax={safeFilterData.defaultMax}
      onUpdate={(id: string, newValues: RangeValuesType ) => updateFilter(id, { min: newValues.min, max: newValues.max })}
    />;
  }
  case 'RangeInputSelectorFilterType': {
    return <DualValueRangeCard
      id={id}
      min={safeFilterData.min}
      max={safeFilterData.max}
      unit={safeFilterData.unit}
      defaultMin={safeFilterData.defaultMin}
      defaultMax={safeFilterData.defaultMax}
      title={safeFilterData.title}
      description={safeFilterData.description}
      onUpdate={(id: string, newValues: RangeValuesType ) => updateFilter(id, { min: newValues.min, max: newValues.max })}
    />;
  }
  case 'RangeSliderSelectorFilterType': {
    return <RangeSliderComponent
      id={id}
      min={safeFilterData.min}
      max={safeFilterData.max}
      unit={safeFilterData.unit}
      defaultMin={safeFilterData.defaultMin}
      defaultMax={safeFilterData.defaultMax}
      title={safeFilterData.title}
      description={safeFilterData.description}
      onUpdate={(id: string, newValues: RangeValuesType ) => updateFilter(id, { min: newValues.min, max: newValues.max })}
    />;
  }
  case 'BooleanTriStateSelectorFilterType': {
    return <BooleanTriStateSelectorComponent
      id={id}
      title={safeFilterData.title}
      description={safeFilterData.description}
      values={safeFilterData.values}
      defaultValues={safeFilterData.defaultValues}
      icons={safeFilterData.icons}
      onUpdate={(id: string, newValues: BooleanTriStateSelectorValuesType ) => updateFilter(id, { state: newValues.state })}
    />;
  }
  case 'StringSelectorFilterType': {
    return ( <SearchStringComponent
      id={id}
      title={safeFilterData.title}
      description={safeFilterData.description}
      query={safeFilterData.query}
      defaultQuery={safeFilterData.defaultQuery}
      onUpdate={(id: string, newValues: StringSelectorValuesType ) => updateFilter(id, { query: newValues.query })}
    />);
  }
  case 'BooleanToggleFilterType': {
    return <BooleanToggleComponent
      id={id}
      title={safeFilterData.title}
      description={safeFilterData.description}
      currValue={safeFilterData.currValue}
      onUpdate={(id: string, newValues: BooleanToggleValuesType ) => updateFilter(id, { state: newValues.state })}
    />;
  }
  case 'MultiSelectorFilterType': {
    return <MultiStateSelectorComponent
      id={id}
      title={safeFilterData.title}
      description={safeFilterData.description}
      state={safeFilterData.currState}
      options={safeFilterData.options}
      defaultValues={safeFilterData.defaultValues || []}
      onUpdate={(id: string, newValues: MultiSelectorValuesType ) => updateFilter(id, { state: newValues.state })}
    />;
  }
  case 'DateRangeSelectorFilterType': {
    // Helen TODO REIX-273 make dateTimes work in ransaq
    return <div>Sorry! Filter is still in development</div>;
    // return <DateRangeSelector
    //   id={id}
    //   title={safeFilterData.title}
    //   description={safeFilterData.description}
    //   startDate={safeFilterData.startDate}
    //   endDate={safeFilterData.endDate}
    //   defaultStartDate={safeFilterData.defaultStartDate}
    //   defaultEndDate={safeFilterData.defaultEndDate}
    //   onUpdate={(id: string, newValues: DateRangeSelectorValuesType ) => updateFilter(id, { startDate: newValues.startDate, endDate: newValues.endDate })}
    // />;
  }
  case 'WantedMultiSelectorFilterType': {
    return <WantedMultiSelector
      id={id}
      title={safeFilterData.title}
      description={safeFilterData.description}
      state={safeFilterData.currState}
      options={safeFilterData.options}
      defaultValues={safeFilterData.defaultValues}
      onUpdate={(id: string, newValues: MultiSelectorValuesType ) => updateFilter(id, { state: newValues.state })}
    />;
  }
  case 'FavoriteTriStateSelectorFilterType': {
    return <FavoriteTriStateSelector
      id={id}
      title={safeFilterData.title}
      description={safeFilterData.description}
      state={safeFilterData.val}
      onUpdate={(id: string, newValues: BooleanTriStateSelectorValuesType ) => updateFilter(id, { state: newValues?.state })}
    />;
  }
  default: {
    return <div id={id}>Unsupported filter type</div>;
  }
  }
};

export default FilterFactory;

