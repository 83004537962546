// features/columns/columnsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPropertyModelColumns } from '../../api/Property/getPropertyModelColumns';
import { RootState } from '../store';
import { ColumnSettingPayloadType, PropertyModelColumnsPayloadType, updateTableColumns } from '../../api/Property/updateTableColumns';
import { resetTableColumns } from '../../api/Property/resetPropertyModelColumns';

export interface TableColumn {
  id: string;
  field: string;
  header: string;
  visible: boolean;
  unit: string;
  unit_type: string;
  order: number;
}

interface ColumnsState {
    columns: TableColumn[];
    originalColumns: TableColumn[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    isPropertyIdColumnVisible: boolean;
    tableSettingsId: string;
  }

export const fetchColumns = createAsyncThunk(
  'columns/fetchColumns',
  async () => {
    const response = await getPropertyModelColumns();
    return response.data;
  }
);

export const resetColumns = createAsyncThunk(
  'columns/resetColumns',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const tableSettingsId = state.columns.tableSettingsId;
    if (!tableSettingsId) {
      throw new Error('Table settings ID not found');
    }
    const response = await resetTableColumns(tableSettingsId);
    if (response.status !== 200) {
      throw new Error('Failed to reset columns');
    }
    return response.data;
  }
);

const initialState: ColumnsState = {
  columns: [],
  originalColumns: [],
  status: 'idle',
  error: null,
  isPropertyIdColumnVisible: false,
  tableSettingsId: '',
};
const columnsSlice = createSlice({
  name: 'columns',
  initialState,
  reducers: {
    toggleColumnVisibility: (state, action: PayloadAction<string[]>) => {
      const newVisibleColumns = action.payload;
      for (const column of state.columns) {
        const field = column.field;
        if (field === 'property_id') {
          column.visible = state.isPropertyIdColumnVisible;
        }
        else if (newVisibleColumns?.includes(field)) {
          column.visible = true;
        } else {
          column.visible = false;
        }
      }
      sendupdateTableColumnsRequest(state.tableSettingsId, state.columns);
    },
    reorderColumns: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
      const { startIndex, endIndex } = action.payload;
      const result = [ ...state.columns ];
      const [ removed ] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      state.columns = result;
      sendupdateTableColumnsRequest(state.tableSettingsId, result);
    },
    setColumns: (state, action: PayloadAction<TableColumn[]>) => {
      state.columns = action.payload;
    },
    setPropertyIdColumnVisibility: (state, action: PayloadAction<boolean>) => {
      state.isPropertyIdColumnVisible = action.payload;
      for (const column of state.columns) {
        if (column.field === 'property_id') {
          column.visible = state.isPropertyIdColumnVisible;
        }
      }
      sendupdateTableColumnsRequest(state.tableSettingsId, state.columns);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColumns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchColumns.fulfilled, (state, action) => {
        const columns = action.payload.columns;
        state.status = 'succeeded';
        state.tableSettingsId = action.payload.table_setting?.id;
        state.columns = columns;
        state.originalColumns = columns;
      })
      .addCase(fetchColumns.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch columns';
      })
      .addCase(resetColumns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetColumns.fulfilled, (state, action) => {
        const columns = action.payload.columns;
        state.status = 'succeeded';
        state.columns = columns;
        state.originalColumns = columns;
      })
      .addCase(resetColumns.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to reset columns';
      });
  }
});

export const selectColumnsLoaded = (state: RootState) => state.columns.status === 'succeeded';

export const { toggleColumnVisibility, reorderColumns, setColumns, setPropertyIdColumnVisibility } = columnsSlice.actions;

export default columnsSlice.reducer;

function sendupdateTableColumnsRequest(tableSettingsId: string, columns: TableColumn[]) {
  const requestColumns: ColumnSettingPayloadType[] = columns.map((c, index) => ({
    order: index+1,
    id: c.id,
    title: c.header,
    database_reference_key: c.field,
    visible: c.visible,
  }));
  const requestBody: PropertyModelColumnsPayloadType = { column_settings_attributes: requestColumns };
  updateTableColumns(tableSettingsId, requestBody);
}
