import React, { type FC } from 'react';
import EstimatedRepairCosts from './EstimatedRepairCosts';
import AvmContainer from './avm/AvmContainer';

export type DataAllUsersWrapperProps = {
  selectedOption: string;
  propertyDetails: any;
  soldAvmResult: any;
  activeAvmResult: any;
}

const DataAllUsersWrapper: FC<DataAllUsersWrapperProps> = ({
  propertyDetails,
  soldAvmResult,
  activeAvmResult,
  selectedOption
}) => {

  return (
    <div className='max-h-full'>
      {selectedOption === 'Estimated Values' ? <AvmContainer soldAvmResult={soldAvmResult} activeAvmResult={activeAvmResult} propertyDetails={propertyDetails} /> : null}
      {selectedOption === 'Estimated Repair Costs' ? <EstimatedRepairCosts propertyDetails={propertyDetails} /> : null}
    </div>
  );
};

export default DataAllUsersWrapper;
