import { useQuery } from '@tanstack/react-query';
import { getFinalDecisionMakingProperties } from '../../../api/FinalDecisionMaking/getFinalDecisionMakingProperties';

export default function useGetFinalDecisionMakingProperties(listSortId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getFinalDecisionMakingProperties', listSortId ],
    queryFn: () => getFinalDecisionMakingProperties(listSortId),
    enabled: !!listSortId
  });

  if (getQuery.isError) {
    console.error('There was an error in getFinalDecisionMakingProperties ', getQuery.error.message);
  }

  return {
    finalDecisionMakingProperties: getQuery.data?.data ? Object.values(getQuery.data.data) : null, // Access response.data
    errorFinalDecisionMakingProperties: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    refetch: getQuery.refetch
  };
}
