import React, { type FC } from 'react';
import { ListSortProps } from '../../utility/types';
import SelectListSortDropdown from '../decision-making-page/SelectListSortDropdown';

export type FinalDecisionMakingSubHeaderProps = {
  listSorts: ListSortProps[];
  activeListSort: ListSortProps;
  setActiveListSort: (ListSortProps) => void;
}

const FinalDecisionMakingSubHeader: FC<FinalDecisionMakingSubHeaderProps> = ({
  listSorts,
  activeListSort,
  setActiveListSort,
}) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 items-center mb-6 mt-4 px-3">
      <div className="flex flex-col lg:flex-row gap-x-5 items-center !align-middle justify-center md:justify-start w-full">
        {activeListSort?.id && (
          <a href={`/list-sorts/decision-making?id=${activeListSort?.id}`} className="text-sm font-semibold ml-2 text-gray-900" >
            <span aria-hidden="true">←</span> Go to decision making
          </a>
        )}
        <SelectListSortDropdown
          listSorts={listSorts}
          onListSortSelect={setActiveListSort}
          activeListSort={activeListSort}
        />
      </div>
    </div>
  );
};

export default FinalDecisionMakingSubHeader;
