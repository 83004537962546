import { useCallback, useMemo } from 'react';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateListSortProps } from '../../../utility/types';
import { updateListSort } from '../../../api/ListSort/updateListSort';

export type UpdateListSortMutationFunctionProps = {
  name: string;
  notes: string;
  list_upload_id: string;
}

export function useUpdateListSort() {
  const queryClient = useQueryClient();

  const updateListSortMutationFunction = ({ name, notes, list_upload_id }: UpdateListSortMutationFunctionProps) => {
    const requestBody: UpdateListSortProps = {
      list_sort: {
        title: name,
        notes: notes
      },
      listSortIdToEdit: list_upload_id
    };
    return  updateListSort(requestBody);
  };

  const updateListSortMutation = useMutation({
    mutationFn: (body: UpdateListSortMutationFunctionProps) => updateListSortMutationFunction(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getListSorts' ] });
    },
  });

  return {
    updateListSort: updateListSortMutation.mutate,
    isSuccess: updateListSortMutation.isSuccess,
    isError: updateListSortMutation.isError,
    isPending: updateListSortMutation.isPending,
    error: updateListSortMutation.error,
    response: updateListSortMutation.data,
  };
}

