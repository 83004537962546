

import React, { type FC } from 'react'
import { BasicTableProps } from '../../utility/types';

const Table: FC<BasicTableProps> = ({ tableData, showID = false }) => {
  const firstRow = tableData[0] ?? {};
  const toTitleCase = (word?: string) => word ? `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}` : 'No data';
  const headers = [];
  const headerKeys = Object.keys(firstRow);
  headers.push(
    <th key={headerKeys[0]} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
      {toTitleCase(headerKeys[0])}
    </th>
  );
  for (let i = 1; i < headerKeys.length; i++) {
    if (headerKeys[i] == 'id' && !showID) { continue; }
    headers.push(
      <th key={headerKeys[i]} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        {toTitleCase(headerKeys[i])}
      </th>
    );
  }
  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {headers}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {tableData.map((data) => {
                  const keys = Object.keys(data);
                  const components = [];
                  const rowId = data[keys[0]];
                  components.push(
                    <td key={rowId} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {rowId}
                    </td>
                  )
                  for (let i = 1; i < keys.length; i++) {
                    if (keys[i] == 'id' && !showID) { continue; }
                    components.push(
                      <td key={String(data[keys[i]])} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{String(data[keys[i]])}</td>
                    );
                  }
                  return (
                    <tr key={rowId}>
                      {components}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table;
