import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MapState {
  key: string;
}

const initialState: MapState = {
  key: ''
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapKey: (state, action: PayloadAction<string>) => {
      state.key = action.payload;
    }
  }
});

export const { setMapKey } = mapSlice.actions;

export default mapSlice.reducer;