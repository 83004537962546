import React, { FC, useState } from 'react';
import { useCreateApproach } from '../../../hooks/api/Approaches/useCreateApproach';
import { useUpdateApproach } from '../../../hooks/api/Approaches/useUpdateApproach';
import { LoadingSpinner, TrashIcon } from '../../icons/OurIcons';
import { useDeleteApproach } from '../../../hooks/api/Approaches/useDeleteApproach';
import ConfirmationPage from './ConfirmationPage';
import { classNames } from '../../../utility/utility';
import ErrorBanner from '../../common/ErrorBanner';
import ApproachConditionsTable from './conditions/ApproachConditionsTable';

export type ApproachFormProps = {
  existingApproach?: any | null,
  onCancel: () => void,
  onSave: () => void
};

const ApproachForm: FC<ApproachFormProps> = ({ onCancel, onSave, existingApproach = null }) => {
  const [ showConfirmationPage, setShowConfirmationPage ] = useState(false);

  const { isPending: isLoadingCreate, isSuccess: isCreateSuccess, error: createError, mutateAsync: createApproach } = useCreateApproach();
  const { isPending: isLoadingUpdate, isSuccess: isUpdateSuccess, error: updateError, mutateAsync: updateApproach } = useUpdateApproach();
  const { isPending: isLoadingDelete, isSuccess: isDeleteSuccess, error: deleteError, mutateAsync: deleteApproach } = useDeleteApproach();

  // If loading or closing modal show loading spinner
  const isLoading = isLoadingCreate || isLoadingUpdate || isLoadingDelete || isCreateSuccess || isUpdateSuccess || isDeleteSuccess;
  const error = createError || updateError || deleteError;
  const existingApproachId = existingApproach?.id;

  const [ formData, setFormData ] = useState({
    name: existingApproach?.name ?? '',
    purchase_percent_of_value: existingApproach?.purchase_percent_of_value ?? 0.80,
    sale_cost_percent: existingApproach?.sale_cost_percent ?? 0.07,
    fixed_cost_calculation: existingApproach?.fixed_cost_calculation ?? 'System Calculation',
    priority_liens_calculation: existingApproach?.priority_liens_calculation ?? 'System Calculation',
    approach_conditions: existingApproach?.approach_conditions ?? []
  });

  const formatFormDataToRequest = () => {
    return {
      approach: {
        name: formData.name,
        purchase_percent_of_value: formData.purchase_percent_of_value,
        sale_cost_percent: formData.sale_cost_percent,
        fixed_cost_calculation: formData.fixed_cost_calculation,
        priority_liens_calculation: formData.priority_liens_calculation,
        approach_conditions: formData.approach_conditions
      }
    };
  };

  const handleSubmit = async () => {
    if (existingApproachId) {
      await updateApproach({ approachId: existingApproachId, requestBody: formatFormDataToRequest() });
    } else {
      await createApproach(formatFormDataToRequest());
    }
    onSave();
  };

  const handleDelete = async () => {
    await deleteApproach(existingApproachId);
    onSave();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const renderApproachText = () => {
    if (existingApproachId) {
      return 'Update existing investment approach.';
    }
    return 'Create a new investment approach.';
  };

  const renderErrorBanner = () => {
    // Need to handle errors here. This does not work yet
    var errorsObject = (error as any)?.response?.data?.errors;
    if (!errorsObject) {
      errorsObject = { request: [ `has failed (${error})` ] };
    }

    return (
      <div className='mt-4'>
        <ErrorBanner errors={errorsObject} />
      </div>
    );
  };

  if (showConfirmationPage) {
    return <ConfirmationPage
      onCancel={() => {setShowConfirmationPage(false);}}
      onConfirm={() => {
        handleDelete();
        setShowConfirmationPage(false);
      }}
      confirmationText={`Confirm you want to delete this approach: ${existingApproach.name}`}
      confirmationButtonText='Delete'
    />;
  }

  return (
    <form>
      <div id="approach-conditions-content" className="space-y-12 px-4">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Investment Approach</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {renderApproachText()}
              </p>
            </div>
            { existingApproachId &&
              <div className="text-red-400 hover:cursor-pointer">
                <a onClick={() => setShowConfirmationPage(true)}>
                  <TrashIcon height={24} width={24}/>
                </a>
              </div>
            }
          </div>
          { error && renderErrorBanner()}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Approach Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Normal Approach"
                  onChange={handleInputChange}
                  value={formData.name}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="purchase_percent_of_value" className="block text-sm font-medium leading-6 text-gray-900">
                Purchase Percent of Value
              </label>
              <div className="mt-2">
                <select
                  id="purchase_percent_of_value"
                  name="purchase_percent_of_value"
                  autoComplete="purchase_percent_of_value"
                  onChange={handleInputChange}
                  value={formData.purchase_percent_of_value}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={0.30}>30%</option>
                  <option value={0.35}>35%</option>
                  <option value={0.40}>40%</option>
                  <option value={0.45}>45%</option>
                  <option value={0.50}>50%</option>
                  <option value={0.55}>55%</option>
                  <option value={0.60}>60%</option>
                  <option value={0.65}>65%</option>
                  <option value={0.70}>70%</option>
                  <option value={0.75}>75%</option>
                  <option value={0.80}>80%</option>
                  <option value={0.85}>85%</option>
                  <option value={0.90}>90%</option>
                  <option value={0.95}>95%</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="sale_cost_percent" className="block text-sm font-medium leading-6 text-gray-900">
                Sales Cost Percent
              </label>
              <div className="mt-2">
                <select
                  id="sale_cost_percent"
                  name="sale_cost_percent"
                  autoComplete="sale_cost_percent"
                  onChange={handleInputChange}
                  value={formData.sale_cost_percent}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={0.0}>0%</option>
                  <option value={0.005}>0.5%</option>
                  <option value={0.01}>1.0%</option>
                  <option value={0.015}>1.5%</option>
                  <option value={0.02}>2.0%</option>
                  <option value={0.025}>2.5%</option>
                  <option value={0.03}>3.0%</option>
                  <option value={0.035}>3.5%</option>
                  <option value={0.04}>4.0%</option>
                  <option value={0.045}>4.5%</option>
                  <option value={0.05}>5.0%</option>
                  <option value={0.055}>5.5%</option>
                  <option value={0.06}>6.0%</option>
                  <option value={0.065}>6.5%</option>
                  <option value={0.07}>7.0%</option>
                  <option value={0.075}>7.5%</option>
                  <option value={0.08}>8.0%</option>
                  <option value={0.085}>8.5%</option>
                  <option value={0.09}>9.0%</option>
                  <option value={0.095}>9.5%</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="fixed_cost_calculation" className="block text-sm font-medium leading-6 text-gray-900">
                Fixed Cost Calculation
              </label>
              <p className="mt-1 text-sm leading-6 text-gray-600">How should fixed costs be factored into max bid calculation?</p>
              <div className="mt-2">
                <select
                  id="fixed_cost_calculation"
                  name="fixed_cost_calculation"
                  autoComplete="fixed_cost_calculation"
                  onChange={handleInputChange}
                  value={formData.fixed_cost_calculation}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option>Do Not Use</option>
                  <option>System Calculation</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="priority_liens_calculation" className="block text-sm font-medium leading-6 text-gray-900">
                Priority Liens Calculation
              </label>
              <p className="mt-1 text-sm leading-6 text-gray-600">How should priority liens be factored into max bid calculation?</p>
              <div className="mt-2">
                <select
                  id="priority_liens_calculation"
                  name="priority_liens_calculation"
                  autoComplete="priority_liens_calculation"
                  onChange={handleInputChange}
                  value={formData.priority_liens_calculation}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option>Do Not Use</option>
                  <option>System Calculation</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="approach_conditions" className="block text-sm font-medium leading-6 text-gray-900">
                Approach Conditions
              </label>
              <p className="mt-1 text-sm leading-6 text-gray-600">Add filters to limit which properties this approach is applicable to.</p>
              <div className="mt-2">
                <ApproachConditionsTable
                  approachConditions={formData.approach_conditions}
                  onChange={(approach_conditions) => {
                    setFormData({ ...formData, ['approach_conditions']: approach_conditions });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={isLoading}
          type="button"
          className={classNames(
            'rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
            isLoading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-500 hover:cursor-pointer'
          )}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default ApproachForm;
