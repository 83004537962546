import React from 'react';
import { CheckIcon_active, MinusIcon, XCircleIcon_active } from '../icons/OurIcons';
import { WantedMultiSelectorProps } from './Components/filters/utility';
import MultiStateSelectorComponent from './Components/filters/MultiStateSelectorComponent';

const renderWantedStatusIcon = (value) => {
  switch (value) {
  case 1:
    return <MinusIcon />;
  case 2:
    return <CheckIcon_active color='ForestGreen'/>;
  case 3:
    return <XCircleIcon_active color='DarkRed'/>;
  default:
    return <> {value}</>;
  }
};

const wantedStatusItemTemplate = (option: any) => {
  return (
    <div className="flex align-items-center gap-2">
      {renderWantedStatusIcon(option.iconValue)}
      <span>{option.title}</span>
    </div>
  );
};

const wantedData = {
  title: 'Sorted (Wanted) Status',
  id: 'wanted',
  // states: wantedFilterStates,
  itemTemplate: wantedStatusItemTemplate
};

const WantedMultiSelector: React.FC<WantedMultiSelectorProps> = ({
  id,
  title,
  description,
  state,
  options,
  defaultValues,
  onUpdate
}) => {
  return (
    <div key={wantedData.id} >
      <MultiStateSelectorComponent
        id={wantedData.id}
        title={wantedData.title}
        description={description}
        state={state}
        itemTemplate={wantedData.itemTemplate}
        options={options}
        defaultValues={defaultValues}
        selectedLabelName = 'selectedLabelName'
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default WantedMultiSelector;
