import { useQuery } from '@tanstack/react-query';
import { getPropertyComments } from '../../../api/Property/getPropertyComments';

export default function useGetPropertyComments(property_id: string) {
  const getQuery = useQuery({
    queryKey: [ 'getComments', property_id ],
    queryFn: () => getPropertyComments(property_id),
    enabled: !!property_id
  });

  if (getQuery.isError) {
    console.error('There was an error in getPropertyComments ', getQuery.error.message);
  }

  return {
    commentData: getQuery.data?.data || null,
    errorComment: getQuery.isError ? getQuery.error : null,
    isLoadingComment: getQuery.isLoading,
    isSuccessComment: getQuery.isSuccess || null,
    refetchCommentData: getQuery.refetch
  };
}