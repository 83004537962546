import React, { FC, useState } from 'react';
import CreateListSortModal from './CreateListSortModal';
import DeleteListSortModal from './DeleteListSortModal';
import EditListSortModal from './EditListSortModal';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import ListSortLineItem from './ListSortLineItem';
import { useGetListSorts } from '../../hooks/api/ListSort/useGetListSorts';
import { Loader } from '../icons/OurIcons';
import { setKeyValueInSessionStorage } from '../../utility/utility';

// Create a client
const queryClient = new QueryClient();

export type ListViewerProps = {
  rootUrl: string;
  mlsDataSources: string[];
}

const ListSortsViewer: FC<ListViewerProps> = (props) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <ListSortsViewerChild {...props} />
    </QueryClientProvider>
  );
};

const ListSortsViewerChild: FC<ListViewerProps> = ({ rootUrl, mlsDataSources }) => {
  const [ listSortIdToDelete, setListSortIdToDelete ] = useState({ id: 'id', name: 'name' });
  const [ listSortIdToEdit, setListSortIdToEdit ] = useState({ id: 'id', name: 'name', notes: '', uploadName: '' });
  const [ isCreateNewListSortModalOpen, setIsCreateNewListSortModalOpen ] = useState(false);  // to creat a new one
  const [ isEditListSortModalOpen, setIsEditListSortModalOpen ] = useState(false);  // to edit an existing one
  const [ isConfirmDeleteListSortModalOpen, setIsConfirmDeleteListSortModalOpen ] = useState(false);

  const { data: listSorts, refetch: refetchGetListSorts, ...getListSorts } = useGetListSorts();

  /* ----- Button Click to Open Modal Functionality ----- */
  const handleNewListButtonClick = () => {
    setIsCreateNewListSortModalOpen(true);
  };

  const handleEditListSortButtonClick = (idParam, listSortToEditName, listSortToEditNotes, listSortToEditUploadName) => {
    setIsEditListSortModalOpen(true);
    setListSortIdToEdit({ id: idParam, name: listSortToEditName, notes: listSortToEditNotes, uploadName: listSortToEditUploadName });
  };

  const handleDeleteListSortButtonClick = (idParam, listSortToDeleteName) => {
    setIsConfirmDeleteListSortModalOpen(true);
    setListSortIdToDelete({ id: idParam, name: listSortToDeleteName });
  };

  const handleContinueListSortButtonClick = (idParam) => {
    setKeyValueInSessionStorage('DM-settings-storage', 'activeListSort', idParam);
    window.location.href = `${rootUrl}list-sorts/${idParam}`;
  };

  /* ------- Close modal functionality ------- */
  const handleCreateListSortModalClose = () => {
    setIsCreateNewListSortModalOpen(false);
  };

  const handleDeleteListSortModalClose = () => {
    setIsConfirmDeleteListSortModalOpen(false);
    setListSortIdToDelete({ id: '', name: '' });
  };

  const handleEditListSortModalClose = () => {
    setListSortIdToEdit({ id: '', name: '', notes: '', uploadName: '' });
    setIsEditListSortModalOpen(false);
  };
  /* ------------------------------------ */

  return (
    <>
      {isCreateNewListSortModalOpen && <CreateListSortModal
        isOpen={isCreateNewListSortModalOpen}
        onClose={handleCreateListSortModalClose}
        mlsDataSources={mlsDataSources}
      />}
      {isConfirmDeleteListSortModalOpen && <DeleteListSortModal
        isOpen={isConfirmDeleteListSortModalOpen}
        onClose={handleDeleteListSortModalClose}
        listSortName={listSortIdToDelete.name}
        listSortId={listSortIdToDelete.id}
      />}
      {isEditListSortModalOpen && <EditListSortModal
        isOpen={isEditListSortModalOpen}
        onClose={handleEditListSortModalClose}
        listSortEditableProperties={{ name: listSortIdToEdit.name, notes: listSortIdToEdit.notes, list_upload_name: listSortIdToEdit.uploadName, list_upload_id: listSortIdToEdit.id }}
      />}
      <div className="px-0 lg:px-0 xs:px-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">List Sorts</h1>
            <p className="mt-2 text-sm text-gray-700">
              Filter properties using the complete MLS or utilize your own property list that you've uploaded
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 flex sm:flex-none">
            <a
              href={`${rootUrl}list-uploads`}
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Manage Lists
            </a>
            <button
              type="button"
              className="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleNewListButtonClick}
            >
              Start a List Sort
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Not Sorted
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Sorted - Yes
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Sorted - No
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Mock Ready
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Completed
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {getListSorts.isPending ?
                    <div className="w-20 absolute top-1/2 left-[48%]">
                      <Loader />
                    </div> :
                    listSorts?.map((ls) =>
                      (<ListSortLineItem
                        key={ls.id}
                        listSort={ls}
                        handleContinueListSortButtonClick={handleContinueListSortButtonClick}
                        handleEditListSortButtonClick={handleEditListSortButtonClick}
                        handleDeleteListSortButtonClick={handleDeleteListSortButtonClick} />))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListSortsViewer;
