import React, { type FC } from 'react';
import PropertyDetails from './PropertyDetails';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import { enableMapSet } from 'immer';
enableMapSet();

export type PropertyDetailPageProps = {
  propertyId: string;
  mapKey: string;
  favorited: boolean;
}

const queryClient = new QueryClient();

const PropertyDetailPage: FC<PropertyDetailPageProps> = ({ propertyId, favorited, mapKey }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <div className='w-full max-w-full'>
          <PropertyDetails
            mapKey={mapKey}
            propertyId={propertyId}
            favorited={favorited}
            pageView={true}
          />
        </div>
      </QueryClientProvider>
    </Provider>
  );
};

export default PropertyDetailPage;
