import React, { FC, useState } from 'react';
import { useGetApproaches } from '../../../hooks/api/Approaches/useGetApproaches';
import ApproachFormModel from './ApproachFormModel';

export type ConfirmationPageProps = {
  onCancel: () => void,
  onConfirm: () => void,
  confirmationText?: string,
  confirmationButtonText?: string
};

const ConfirmationPage: FC<ConfirmationPageProps> = ({ onCancel, onConfirm, confirmationText = '', confirmationButtonText = 'Confirm' }) => {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Are you sure?
          </h2>
          { confirmationText &&
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {confirmationText}
            </p>
          }

          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              onClick={onCancel}
              className="text-sm font-semibold leading-6 text-gray-900 hover:cursor-pointer">
              Cancel
            </a>
            <a
              onClick={onConfirm}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:cursor-pointer"
            >
              {confirmationButtonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
