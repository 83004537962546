import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteListSort } from '../../../api/ListSort/deleteListSort';

export function useDeleteListSort() {
  const queryClient = useQueryClient();

  const deleteListSortMutation = useMutation({
    mutationFn: (listSortId: string) => deleteListSort(listSortId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getListSorts' ] });
    },
  });

  return deleteListSortMutation;
}
