import React, { createContext, useState, ReactNode, useContext } from 'react';

export type PropertyDecisionCountsType = {
  wantedPropertiesCount: number;    /* Wanted properties */
  unwantedPropertiesCount: number;  /* Unwanted properties */
  decidedPropertiesCount: number;   /* Sorted properties (Wanted+unwanted) = total decided */
  undecidedPropertiesCount: number;  /* Unsorted properties */
};

// Define the shape of the context
type PropertiesViewerContextType = {
  activeListSortId: string;
  setActiveListSortId: (value: string) => void;

  criteriaMatchedPropertiesCount: number;
  setCriteriaMatchedPropertiesCount: (value: number) => void;

  propertyDecisionCounts: PropertyDecisionCountsType
  setPropertyDecisionCounts: any;

  totalPropertiesCount: number;
  setTotalPropertiesCount: (value: number) => void;

  /* Mock Ready properties */
  mockReadyPropertiesCount: number;
  setMockReadyPropertiesCount: (value: number) => void;

  /* Completed properties */
  completedPropertiesCount: number;
  setCompletedPropertiesCount: (value: number) => void;
}

const workflowStatesStartingValues = {
  notSorted: 0,
  sorted: 0,
  mockReady: 0,
  completed: 0
};

type PropertiesViewerContextProviderProps = {
  children: ReactNode;
  listSortId?: string;
}

const PropertyViewerContext = createContext<PropertiesViewerContextType | undefined>(undefined);

export function PropertyViewerContextProvider({ listSortId = null, children }: PropertiesViewerContextProviderProps) {
  const [ activeListSortId, setActiveListSortId ] = useState(listSortId); // If this is 'property' it means we are just browsing properties
  const [ criteriaMatchedPropertiesCount, setCriteriaMatchedPropertiesCount ] = useState(0);
  const [ propertyDecisionCounts, setPropertyDecisionCounts ] = useState({
    wantedPropertiesCount: 0,
    unwantedPropertiesCount: 0,
    decidedPropertiesCount: 0,
    undecidedPropertiesCount: 0,
  });
  const [ totalPropertiesCount, setTotalPropertiesCount ] = useState(0);
  const [ mockReadyPropertiesCount, setMockReadyPropertiesCount ] = useState(0);
  const [ completedPropertiesCount, setCompletedPropertiesCount ] = useState(0);

  const contextValue: PropertiesViewerContextType = {
    activeListSortId, setActiveListSortId,
    criteriaMatchedPropertiesCount, setCriteriaMatchedPropertiesCount,
    propertyDecisionCounts, setPropertyDecisionCounts,
    totalPropertiesCount, setTotalPropertiesCount,
    mockReadyPropertiesCount, setMockReadyPropertiesCount,
    completedPropertiesCount, setCompletedPropertiesCount
  };

  return (
    <PropertyViewerContext.Provider value={contextValue}>
      {children}
    </PropertyViewerContext.Provider>
  );
};

// Custom hook to access the context
export function usePropertyViewerContext() {
  const context = useContext(PropertyViewerContext);
  if (!context) {
    throw new Error('usePropertyViewerContext must be used within a PropertyViewerContext');
  }
  return context;
}

export default PropertyViewerContext;
