import { api } from '../configs/axiosConfigs';

export type PropertyModelColumnsPayloadType = {
  column_settings_attributes: ColumnSettingPayloadType[];
};

export type ColumnSettingPayloadType = {
  id: string;
  order: number;
  title: string;
  database_reference_key: string;
  visible: boolean;
}

export const updateTableColumns = async (tableSettingsId: string, requestBody: PropertyModelColumnsPayloadType) => {
  if (!tableSettingsId) {
    return new Error('Table settings ID is required');
  }
  try {
    await api.put(`/table_settings/${tableSettingsId}.json`, { table_setting: requestBody });
  } catch (error) {
    console.error('Error updating', error.response.data);
  }
};
