export type ListColumnPropTypes = {
    field: string;
    header: string;
    body: any;
    style: any;
    unit?: any;
};
const ListColumns: ListColumnPropTypes[] = [
  {
    field: 'fullAddress',
    header: 'Address',
    body: null,
    style: { textAlign: 'left' },
  },
  {
    field: 'sqft',
    header: 'Sq Ft',
    body: null,
    style: { textAlign: 'right' },
  },
  {
    field: 'bedrooms',
    header: 'Beds',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'bathrooms',
    header: 'Baths',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'stories',
    header: 'Stories',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'year_built',
    header: 'Year Built',
    body: null,
    style: { textAlign: 'center' },
  },
  // Remaining fields not listed in the instructions are placed here at the end
  {
    field: 'status',
    header: 'MLS Status',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'garage_spaces',
    header: 'Garage Spaces',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'property_class',
    header: 'Property Class',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'pool',
    header: 'Pool',
    body: null,
    style: { textAlign: 'center' },
  },
  {
    field: 'street',
    header: 'Street',
    body: null,
    style: { textAlign: 'left' },
  },
  {
    field: 'state',
    header: 'State',
    body: null,
    style: { textAlign: 'center' },
  },
];
export default ListColumns;
