import React, { useState, FC } from 'react';
import GetMoreDataDropdown from '../GetMoreDataDropdown';
import MyDataWrapper from './MyDataWrapper';

export type GetMyDataOptionsPaidUsersProps = {
  mapKey: string;
  soldAvmResult: any;
  propertyDetails: any;
}

const GetMyDataOptionsPaidUsers: FC<GetMyDataOptionsPaidUsersProps> = ({ mapKey, soldAvmResult, propertyDetails }) => {

  const [ isMyDataDropdownOpen, setIsMyDataDropwdownOpen ] = useState(false);
  const [ myDataSelectedOption, setMyDataSelectedOption ] = useState('');

  const optionsListMyData = [
    'Decision Making',
    'My List Data',
    'My Pictures',
    'Repair Estimate',
    'Personalized Comp Data',
    'On-Site Data',
    'Make Offer',
    'My Loan',
    'Property Notes',
    'Other Property Docs',
    'Agent/Broker Only Tab - Listing History'
  ];

  function handleMyDataDropdownSelect(value) {
    setMyDataSelectedOption(value);
    setIsMyDataDropwdownOpen(false);
  }

  return (
    <>
      <div className="max-w-full mt-14 border-t border-gray pl-6 content-center justify-center">
        <GetMoreDataDropdown
          title="My Data Options - Paid Users"
          optionsList={optionsListMyData}
          setIsOpen={setIsMyDataDropwdownOpen}
          isOpen={isMyDataDropdownOpen}
          handleSelect={handleMyDataDropdownSelect}
          selectedOption={myDataSelectedOption}
        />
      </div>
      <div>
        <MyDataWrapper
          mapKey={mapKey}
          selectedOption={myDataSelectedOption}
          propertyDetails={propertyDetails}
          soldAvmResult={soldAvmResult}
        />
      </div>
    </>
  );
};

export default GetMyDataOptionsPaidUsers;
