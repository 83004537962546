import React, { type FC, useState, useRef, useEffect } from 'react';

import PropertySummary from './PropertySummary';
import ValuationsContainer from './valuations/ValuationsContainer';
import ImagesMapContainer from './ImagesMapContainer';
import RepairCosts from './RepairCosts';
import ApproachSelect from './approach-select/ApproachSelect';
import DecisionMakingPropertyHeader from './DecisionMakingPropertyHeader';

import { enableMapSet } from 'immer';
import QuickNetSheetModal from './quick-net-sheet/QuickNetSheetModal';
import PropertyCommentsModal from '../common/modals/property-comments/PropertyCommentsModal';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchLspjs, updateDecisionsAsync } from '../../redux/state/lspjSlice';
import { useSelector } from 'react-redux';
import useGetEstimatedRepairCosts from '../../hooks/api/EstimatedRepairCosts/useGetEstimatedRepairCosts';
import { AvmComp, ListSortProps } from '../../utility/types';
import { getAvmCompsForMap, getAvmTopSix, getPropertyImages } from '../../utility/utility_functions';
import useGetPropertyDetails from '../../hooks/api/Property/useGetPropertyDetails';
import SoldAvmCompsTable from '../common/SoldAvmCompsTable';
import ActiveAvmCompsTable from '../common/ActiveAvmCompsTable';
import useGetMostRecentAvmResults from '../../hooks/api/AvmResults/useGetMostRecentAvmResults';

enableMapSet();

export type DecisionMakingContainerProps = {
  propertyId: string;
  activeListSort: ListSortProps;
}

const DecisionMakingContainer: FC<DecisionMakingContainerProps> = ({
  propertyId,
  activeListSort
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const galleriaRef = useRef(null);
  const [ mlsPropertyImages, setMlsPropertyImages ] = useState([]);
  const [ isQuickNetModalOpen, setIsQuickNetModalOpen ] = useState(false);
  const [ isPropertyCommentsModalOpen, setIsPropertyCommentsModalOpen ] = useState(false);
  const [ soldAvmResultsVisible, setSoldAvmResultsVisible ] = useState(false);
  const [ activeAvmResultsVisible, setActiveAvmResultsVisible ] = useState(false);
  const [ activeAvmResult, setActiveAvmResult ] = useState('');
  const [ soldAvmResult, setSoldAvmResult ] = useState('');
  const [ promptSaveChanges, setPromptSaveChanges ] = useState(true); // Make this more specific in the future

  const { propertyDetails, isLoadingPropertyDetails } = useGetPropertyDetails(propertyId);
  const { mostRecentAvmResults, isLoadingMostRecentAvmResults, errorMostRecentAvmResults } = useGetMostRecentAvmResults(propertyId);

  const soldCompsSix: AvmComp[] = getAvmTopSix(soldAvmResult);
  const activeCompsSix: AvmComp[] = getAvmTopSix(activeAvmResult);
  const avmCompsForMap = getAvmCompsForMap(activeCompsSix, soldCompsSix);

  const [ decisionFormData, setDecisionFormData ] = useState({
    approach_id: null,
    valuations_option: null,
    estimated_value: null,
    estimated_value_per_sqft: null,
    fix_costs: null,
    max_bid: null,
    max_bid_per_sqft: null,
    estimated_profit: null,
    roi: null,
    user_estimated_value: null,
    user_estimated_value_sqft: null,
    user_fixed_costs: null,
    wanted: null
  });

  const { estimatedRepairCosts } = useGetEstimatedRepairCosts(propertyId);

  useEffect(() => {
    dispatch(fetchLspjs(activeListSort?.id));
  }, [ dispatch, activeListSort?.id, propertyId ]);

  const lspjState = useSelector((state: RootState) => state.lspj.lspjs[propertyId]);
  const isLspjLoading = useSelector((state: RootState) => state.lspj.loading);

  useEffect(() => {
    if (lspjState) {
      setDecisionFormData(prev => ({
        approach_id: lspjState?.approach_id ?? prev.approach_id,
        valuations_option: lspjState?.valuations_option ?? prev.valuations_option,
        estimated_value: lspjState?.estimated_value ?? prev.estimated_value,
        estimated_value_per_sqft: lspjState?.estimated_value_per_sqft ?? prev.estimated_value_per_sqft,
        fix_costs: lspjState?.fix_costs ?? prev.fix_costs,
        max_bid: lspjState?.max_bid ?? prev.max_bid,
        max_bid_per_sqft: lspjState?.max_bid_per_sqft ?? prev.max_bid_per_sqft,
        estimated_profit: lspjState?.estimated_profit ?? prev.estimated_profit,
        roi: lspjState?.roi ?? prev.roi,
        user_estimated_value: lspjState?.user_estimated_value ?? prev.user_estimated_value,
        user_estimated_value_sqft: lspjState?.user_estimated_value_sqft ?? prev.user_estimated_value_sqft,
        user_fixed_costs: lspjState?.user_fixed_costs ?? prev.user_fixed_costs,
        wanted: lspjState?.status ?? prev.wanted
      }));
    }
  }, [ lspjState, activeListSort?.id, propertyId, estimatedRepairCosts ]);

  useEffect(() => {
    if (propertyDetails) {
      setMlsPropertyImages(getPropertyImages(propertyDetails));
    }
  }, [ propertyDetails, propertyId ]);

  useEffect(() => {
    if (mostRecentAvmResults) {
      setActiveAvmResult(mostRecentAvmResults?.active_avm_result);

      setSoldAvmResult(mostRecentAvmResults?.sold_avm_result);
    }
  }, [ mostRecentAvmResults ]);

  const updateFormData = (fieldName: string, value: any) => {
    setDecisionFormData(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const formatFormDataForRequest = () => {
    return {
      wanted: decisionFormData?.wanted,
      notes: '',
      approach_id: decisionFormData?.approach_id,
      valuations_option: decisionFormData?.valuations_option,
      estimated_value: decisionFormData?.estimated_value,
      estimated_value_per_sqft: decisionFormData?.estimated_value_per_sqft,
      fix_costs: decisionFormData?.fix_costs,
      max_bid: decisionFormData?.max_bid,
      max_bid_per_sqft: decisionFormData?.max_bid_per_sqft,
      estimated_profit: decisionFormData?.estimated_profit,
      roi: decisionFormData?.roi,
      user_estimated_value: decisionFormData?.user_estimated_value,
      user_estimated_value_sqft: decisionFormData?.user_estimated_value_sqft,
      user_fixed_costs: decisionFormData?.user_fixed_costs,
      meta: {
        id: lspjState?.meta?.id,
        notes: lspjState?.meta?.notes,
      },
      status: decisionFormData?.wanted,
      loading: lspjState?.loading,
      error: lspjState?.error
    };
  };

  const handleSubmitDecision = async () => {

    await dispatch(updateDecisionsAsync({
      listSortId: activeListSort?.id,
      propertyId: propertyId,
      list_sort_property_join: formatFormDataForRequest()
    }));

    setIsQuickNetModalOpen(false);
  };

  return (
    <div >
      <div>
        <DecisionMakingPropertyHeader
          propertyId={propertyId}
          setIsQuickNetModalOpen={setIsQuickNetModalOpen}
          setIsPropertyCommentsModalOpen={setIsPropertyCommentsModalOpen}
          savedWantedStatus={lspjState?.status}
          onWantedStatusChange={(wantedStatus) => {
            updateFormData('wanted', wantedStatus);
            updateFormData('status', wantedStatus);
          }}
          promptSaveChanges={promptSaveChanges}
        />
        <div id='DecisionMakingContainer-container' className='w-screen max-h-full top-0 pb-10 text-xs'>
          <div className='grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-x-3 rounded-xl sm:text-center h-full'>
            <div className='h-full flex flex-col'>
              <div id='valuations-container' className='rounded-xl shadow-2xl h-contain overflow-y-scroll'>
                <ValuationsContainer
                  propertyDetails={propertyDetails}
                  soldAvmResult={soldAvmResult}
                  activeAvmResult={activeAvmResult}
                  soldCompsSix={soldCompsSix}
                  onEstimatedValueChange={(estimatedValue, estimatedValuePerSqft, activeRow, userEstimated, userEstimatedSqft) => {
                    updateFormData('estimated_value', estimatedValue);
                    updateFormData('estimated_value_per_sqft', estimatedValuePerSqft);
                    updateFormData('valuations_option', activeRow);
                    updateFormData('user_estimated_value', userEstimated);
                    updateFormData('user_estimated_value_sqft', userEstimatedSqft);
                  }}
                  savedArvEstimate={lspjState?.estimated_value}
                  savedArvSqft={lspjState?.estimated_value_per_sqft}
                  savedUserArvEstimate={lspjState?.user_estimated_value}
                  savedUserArvSqft={lspjState?.user_estimated_value_sqft}
                  isLoading={isLspjLoading || isLoadingMostRecentAvmResults|| isLoadingPropertyDetails}
                  savedValuationsRow={lspjState?.valuations_option}
                />
              </div>
            </div>
            <div className='w-full h-fill'>
              <div id='approach-select-container' className='rounded-xl shadow-2xl h-contain'>
                <ApproachSelect
                  propertyData={propertyDetails}
                  avmResult={soldAvmResult}
                  estimatedValue={decisionFormData?.estimated_value}
                  savedApproachId={lspjState?.approach_id}
                  isLoading={isLspjLoading || isLoadingMostRecentAvmResults || isLoadingPropertyDetails}
                  onApproachSelect={(selectedApproach) => {
                    updateFormData('approach_id', selectedApproach?.approach?.id);
                    updateFormData('max_bid', selectedApproach?.max_bid);
                    updateFormData('max_bid_per_sqft', selectedApproach?.max_bid_per_sqft);
                    updateFormData('estimated_profit', selectedApproach?.estimated_profit);
                    updateFormData('roi', selectedApproach?.roi);
                  }}
                  fixedCosts={decisionFormData?.fix_costs}
                />
              </div>
              <div id='repair-costs-container' className='rounded-xl shadow-2xl h-contain'>
                <RepairCosts
                  onRepairCostUpdate={(repairCost, userFixedCosts) => {
                    updateFormData('fix_costs', repairCost);
                    updateFormData('user_fixed_costs', userFixedCosts);
                  }}
                  estimatedRepairCosts={estimatedRepairCosts}
                  savedFixedCosts={lspjState?.fix_costs}
                  savedUserFixedCosts={lspjState?.user_fixed_costs}
                />
              </div>
            </div>
            <div className='w-full h-full'>
              <div id='property-summary-container' className='rounded-xl shadow-2xl'>
                <PropertySummary propertyData={propertyDetails} listSort={activeListSort}/>
              </div>
              <div className='rounded-xl shadow-2xl'>
                <div id='images-map-container' className='w-full'>
                  <ImagesMapContainer
                    avmResult={soldAvmResult}
                    avmCompsForMap={avmCompsForMap}
                    galleriaRef={galleriaRef}
                    mlsPropertyImages={mlsPropertyImages}
                    propertyId={propertyId}
                    isLoading={isLspjLoading || isLoadingMostRecentAvmResults|| isLoadingPropertyDetails}
                    error={errorMostRecentAvmResults?.message}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isPropertyCommentsModalOpen &&
          <PropertyCommentsModal
            isOpen={isPropertyCommentsModalOpen}
            propertyId={propertyId}
            setIsPropertyCommentsModalOpen={setIsPropertyCommentsModalOpen}
          />
        }
        {isQuickNetModalOpen &&
          <QuickNetSheetModal
            isOpen={isQuickNetModalOpen}
            propertyDetails={propertyDetails}
            setModalOpen={setIsQuickNetModalOpen}
            handleSubmitDecision={handleSubmitDecision}
            decisionFormData={decisionFormData}
          />
        }
      </div>
      {/* todo: break this into footer */}
      <div id='sold-avm-results'>
        {soldAvmResultsVisible && <div className='shadow-xl rounded-xl overflow-scroll w-contain'>
          <SoldAvmCompsTable comps={soldCompsSix} />
        </div>}
        <button
          className='mt-3 rounded bg-indigo-600 py-1 font-semibold text-white w-full h-10 shadow-md hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          onClick={() => setSoldAvmResultsVisible(prev => !prev)}>
          {soldAvmResultsVisible ? 'Close AVM Top 6 Sold Comps' : 'View AVM Top 6 Sold Comps'}
        </button>
      </div>
      <div className='mt-3'>
        {activeAvmResultsVisible && <div className='shadow-xl rounded-xl overflow-scroll w-contain mt-5'>
          <ActiveAvmCompsTable comps={activeCompsSix} />
        </div>}
        <button
          className='mt-3 rounded bg-green-500 py-1 font-semibold text-white w-full h-10 shadow-md hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500'
          onClick={() => setActiveAvmResultsVisible(prev => !prev)}>
          {activeAvmResultsVisible ? 'Close AVM Top 6 Active Comps' : 'View AVM Top 6 Active Comps'}
        </button>
      </div>
    </div>
  );
};

export default DecisionMakingContainer;
