import { FC } from 'react';
import React from 'react';
import { IconHouseFill, Loader } from '../icons/OurIcons';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { maintainFocus, setFocusedIndex } from '../../redux/state/avmMapSlice';

export type AvmCompMapMarkerProps = {
  index?: number;
  loading: boolean;
  isActive: boolean;
}

const AvmCompMapMarker: FC<AvmCompMapMarkerProps> = ({ index, loading, isActive }) => {
  const markerStyling = 'flex items-center justify-center rounded-full drop-shadow fill-white text-white cursor-pointer';
  const unselected = `${markerStyling} w-6 h-6 ${isActive ? 'bg-green-500' : 'bg-indigo-500'} text-lg`;
  const selected = `${markerStyling} w-8 h-8 ${isActive ? 'bg-green-800' : 'bg-indigo-800'} text-2xl`;

  const dispatch = useDispatch<AppDispatch>();
  const focusedIndex = useSelector((state: RootState) => state.avmMap.focusedIndex);
  const keepFocus = useSelector((state: RootState) => state.avmMap.maintainFocus);

  const clearEmphasis = () => {
    if (!keepFocus) {
      dispatch(setFocusedIndex(null));
    }
  };

  const setEmphasisHover = () => {
    if (!keepFocus) {
      dispatch(setFocusedIndex({ index, isActive }));
    }
  };

  const setEmphasisClick = () => {
    if (!keepFocus) {
      dispatch(maintainFocus());
    }
    else if (index !== focusedIndex?.index || isActive !== focusedIndex?.isActive) {
      dispatch(setFocusedIndex({ index, isActive }));
    }
  };

  const getMarkerContent = () => {
    return index !== undefined ? <span className="pt-px">{index + 1}</span> : <IconHouseFill className={'w-3/4 pb-px'}/>;
  };

  return index === focusedIndex?.index && isActive === focusedIndex?.isActive ?
    <div className={selected} onMouseLeave={clearEmphasis} onClick={setEmphasisClick}>
      {getMarkerContent()}
      {loading && <div className="absolute w-[55px] bottom-[-11px] right-[-11px]">
        <Loader />
      </div>}
    </div>
    : <div className={unselected} onMouseEnter={setEmphasisHover} onClick={setEmphasisClick}>
      {getMarkerContent()}
    </div>;
};

export default AvmCompMapMarker;