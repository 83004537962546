import React, { type FC } from 'react';
import { FinalDecisionMakingProperty, ListSortProps } from '../../utility/types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../../app/assets/stylesheets/fdm-property-list-styles.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeflex/primeflex.css';                                   // css utility
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import { IconHouse } from '../icons/OurIcons';
import PropertyDetailsButton from '../property-list-viewer/buttons/PropertyDetailsButton';
import baseURL from '../../data/environmentVariables';

type FinalDecisionMakingTableProps = {
  properties: FinalDecisionMakingProperty[];
}

const FinalDecisionMakingTable: FC<FinalDecisionMakingTableProps> = ({ properties }) => {
  return (
    <div className='w-screen max-h-full top-0 pb-10 text-xs justify-center'>
      {properties?.length > 0 ? (
        <div id="fdm-properties-list" className="card text-center  ">
          <DataTable
            id="fdm-properties-data-table"
            value={properties}
            sortMode="multiple"
            tableStyle={{ minWidth: '50rem' }}
            size="normal"
            removableSort
            pt={{
              paginator: { pageButton: { className: 'rounded-full' } },
              header: { className: 'fdm-table-header ' },
              headerRow: { className: 'fdm-table-header-row ' },
              table: { className: 'fdm-table-table ' },
              footer: { className: 'fdm-table-footer ' },
              row: {
                root: {
                  className: 'fdm-table-row ',
                }
              }
            }}
          >
            <Column
              field="propertyDetails"
              key="propertyDetails"
              header={() => <IconHouse />}
              bodyStyle={{ textAlign: 'center' }}
              body={(rowData) => (
                <PropertyDetailsButton
                  key={rowData.propertyId}
                  rowData={rowData}
                  onClick={() => {window.open(`${baseURL}/properties/${rowData.propertyId}`, '_blank');}}
                  isActive={false}
                />
              )}
            />
            <Column field="customListSortNumber" header="Sort" sortable style={{ width: '5%' }}></Column>
            <Column field="address" header="Address" sortable style={{ minWidth: 300 }}></Column>
            <Column field="arvSelected" header="Estimated Value" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="approachName" header="Approach Name" sortable style={{ minWidth: '75px' }}></Column>
            <Column field="maxBid" header="Max Bid" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="repairEstimate" header="Repair Estimate" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="profitOnMaxBid" header="Profit on Max Bid" sortable style={{ minWidth: '100px' }}></Column>
            <Column field="roiPercent" header="ROI Percent" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="sqFt" header="Square Feet" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="yearBuilt" header="Year Built" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="bedrooms" header="Bedrooms" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="bathrooms" header="Bathrooms" sortable style={{ minWidth: '25%' }}></Column>
            <Column field="acreage" header="Acreage" sortable style={{ minWidth: '25%' }}></Column>
          </DataTable>
        </div>
      ) : (
        <div className='flex justify-center items-center h-full m-5 text-lg text-gray-500'>
          No properties in this list are ready for Final Decision Making
        </div>
      )}
    </div>
  );
};

export default FinalDecisionMakingTable;
