import React, { useState, FC } from 'react';
import DataAllUsersWrapper from './DataAllUsersContainer';
import GetMoreDataDropdown from '../GetMoreDataDropdown';

export type GetMoreDataAllUsersProps = {
  soldAvmResult: any;
  activeAvmResult: any;
  propertyDetails: any;
}

const GetMoreDataAllUsers: FC<GetMoreDataAllUsersProps> = ({ soldAvmResult, activeAvmResult, propertyDetails }) => {

  const [ isAllUsersDropdownOpen, setIsAllUsersDropwdownOpen ] = useState(false);
  const [ allUsersSelectedOption, setAllUsersSelectedOption ] = useState('');

  const optionsListAllUsers = [
    'Estimated Values',
    'Estimated Repair Costs',
    'Value Ranges',
    'Rental Estimate',
    'Tax Assessment',
    'For Sale Nearby',
    'Neighborhood & HOA',
    'FEMA',
    'Parcel Information',
    'School Data',
    'Listing Agent',
    'Services',
    'Monthly Costs'
  ];

  function handleAllUsersDropdownSelect(value) {
    setAllUsersSelectedOption(value);
    setIsAllUsersDropwdownOpen(false);
  }

  return (
    <>
      <div className="max-w-full mt-10 border-t border-gray pl-6 content-center justify-center">
        <GetMoreDataDropdown
          title="More Data Options - All Users"
          optionsList={optionsListAllUsers}
          setIsOpen={setIsAllUsersDropwdownOpen}
          isOpen={isAllUsersDropdownOpen}
          handleSelect={handleAllUsersDropdownSelect}
          selectedOption={allUsersSelectedOption}
        />
      </div>
      <div>
        <DataAllUsersWrapper
          selectedOption={allUsersSelectedOption}
          propertyDetails={propertyDetails}
          soldAvmResult={soldAvmResult}
          activeAvmResult={activeAvmResult}
        />
      </div>
    </>
  );
};

export default GetMoreDataAllUsers;
