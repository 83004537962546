import React from 'react';

const BulkActionButtons = ({ selectedRows, listSortId, handleMarkAllAsWanted, handleMarkAllAsUnwanted, handleMarkAllAsUndecided }) => {
  if (selectedRows.length <= 1 || !listSortId) return null;

  return (
    <div>
      <button
        className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        onClick={handleMarkAllAsWanted}
      >
          Mark all {selectedRows.length} as wanted
      </button>
      <button
        className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        onClick={handleMarkAllAsUnwanted}
      >
          Mark all {selectedRows.length} as do not want
      </button>
      <button
        className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        onClick={handleMarkAllAsUndecided}
      >
          Mark all {selectedRows.length} as undecided
      </button>
    </div>
  );
};

export default BulkActionButtons;
