import React from 'react';
import { CheckIcon_active, Loader, XCircleIcon_active, MinusIcon } from '../../icons/OurIcons';
import { AppDispatch, RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LspjDataProps, toggleLspjAsync } from '../../../redux/state/lspjSlice';

const WantedButton = ({ id }: { id: string }) => {
  const lspjState: LspjDataProps = useSelector((state: RootState) => state?.lspj?.lspjs?.[id]) ?? { status: null, error: null, loading: null };
  const dispatch = useDispatch<AppDispatch>();
  if (!id) {
    return <></>;
  }

  let status = null, error = null, loading = null;
  if (lspjState) {
    status = lspjState.status;
    error = lspjState.error;
    loading = lspjState.loading;
  }

  const wantedStatusIcon = (status) => {
    if (loading) {
      return <Loader width={20} height={20} />;
    }
    if (status === false) {
      return <XCircleIcon_active />;
    }
    if (status === true) {
      return <CheckIcon_active />;
    }
    return <MinusIcon />;
  };

  return (
    <button
      className="whitespace-nowrap py-2 text-sm text-gray-500 cursor-pointer"
      onClick={() => dispatch(toggleLspjAsync(id))}
      disabled={loading}
    >
      {wantedStatusIcon(status)}
    </button>
  );
};

export default WantedButton;
