import React, { FC, Fragment, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDeleteListUpload } from '../../hooks/api/ListUpload/useDeleteListUpload';

interface DeleteListUploadModalProps {
  isOpen: boolean;
  onClose: (success: boolean) => void;
  listUploadName: string;
  listUploadId: string;
}

const DeleteListUploadModal: FC<DeleteListUploadModalProps> = ({
  isOpen,
  onClose,
  listUploadName,
  listUploadId,
}) => {
  const deleteListUploadMutation = useDeleteListUpload();

  // Use a useEffect to handle the modal closing logic
  useEffect(() => {
    if (deleteListUploadMutation.isSuccess) {
      onClose(true);
    }
  }, [ deleteListUploadMutation.isSuccess, onClose ]);

  const handleConfirm = () => {
    deleteListUploadMutation.mutate(listUploadId);
  };

  const deleteButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={deleteButtonRef} onClose={() => onClose(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h2" className="text-xl font-semibold mb-4">
                        Delete '{listUploadName}'?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500"> This cannot be undone and will delete related list sorts.</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={handleConfirm}
                      ref={deleteButtonRef}
                      disabled={deleteListUploadMutation.isPending || deleteListUploadMutation.isSuccess}
                    >
                    Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => onClose(false)}
                    >
                    Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteListUploadModal;
