import React, { forwardRef, useState } from 'react';
import placeholder from 'images/placeholder.webp';
import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';

interface PropertyGalleriaProps {
  propertyImages: Array<Object>;
}

const PropertyGalleria = forwardRef<any, PropertyGalleriaProps>(({ propertyImages }, ref) => {
  const [ activeGalleriaIndex, setActiveGalleriaIndex ] = useState(0);

  const galleriaResponsiveOptions: GalleriaResponsiveOptions[] = [
    {
      breakpoint: '1500px',
      numVisible: 7
    },
    {
      breakpoint: '1024px',
      numVisible: 6
    },
    {
      breakpoint: '768px',
      numVisible: 4
    },
    {
      breakpoint: '560px',
      numVisible: 3
    }
  ];

  const galleriaItemTemplate = (image: { image_url?: string; description?: string }) => {
    return <img src={image?.image_url ?? placeholder} alt={image?.description ?? 'Property Image'} className='rounded-xl h-full w-contain xs:w-1/2 xs:h-1/2' />;
  };

  const galleriaThumbnailTemplate = (image: { image_url?: string; description?: string }) => {
    return <img src={image?.image_url ?? placeholder} alt={image?.description ?? 'Property Image'} className='block rounded-xl p-1 max-h-20 w-36' />;
  };

  const caption = (item) => item?.date ? 
    <div className="relative bottom-24 text-xl font-bold text-center text-white">
      <p className="w-[7%] ml-[47%] bg-slate-700/60">{item?.date}</p>
    </div> 
    : '';

  return (
    <Galleria
      ref={ref}
      value={propertyImages}
      activeIndex={activeGalleriaIndex}
      onItemChange={(e) => setActiveGalleriaIndex(e.index)}
      responsiveOptions={galleriaResponsiveOptions}
      item={galleriaItemTemplate}
      caption={caption}
      autoPlay
      circular
      transitionInterval={3000}
      showItemNavigators
      showThumbnailNavigators
      showThumbnails
      numVisible={7}
      fullScreen
      thumbnail={galleriaThumbnailTemplate}
      className='max-w-screen max-h-contain bg-gray-900/90'
    />
  );
});

export default PropertyGalleria;
