import React, { type FC, useState, useEffect, useRef } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeflex/primeflex.css';                                   // css utility
import { Listbox } from '@headlessui/react';
import { ListSortProps } from '../../utility/types';
import { LoadingSpinner } from '../icons/OurIcons';

type SelectListSortDropdownProps = {
  listSorts: ListSortProps[];
  onListSortSelect: (listSort: ListSortProps) => void;
  activeListSort: ListSortProps;
};

const SelectListSortDropdown: FC<SelectListSortDropdownProps> = ({ listSorts, onListSortSelect, activeListSort }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Event listener to detect clicks outside the dropdown to close it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleListSortChange = (listSortId: any) => {
    if (!listSortId) {
      return;
    }
    const listSort = listSorts?.find((listSort) => listSort.id == listSortId);
    setIsOpen(false);
    onListSortSelect(listSort);
  };

  return (
    <div id="select-list-sort-container" className="flex">
      <div className="inline-block">
        <Listbox
          as="div"
          className="flex space-y-1 items-center z-10"
          value={activeListSort?.id || 'Select List Sort'}
          onChange={handleListSortChange}
          ref={dropdownRef}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="whitespace-nowrap block text-base text-gray-700 pr-3 text-nowrap">
                Select List Sort:
              </Listbox.Label>
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    id="select-list-sort-button"
                    className="cursor-default relative w-48 rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-indigo-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {activeListSort ?
                      (<span className="block truncate">
                        {activeListSort.title}
                      </span>)
                      : (
                        <span className="block truncate text-center">
                          Please select a list sort
                        </span>)
                    }
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                {/* Listbox.Options */}
                {isOpen && (
                  <Listbox.Options
                    static
                    className="absolute bg-white max-h-60 w-full rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 border z-10"
                  >
                    {listSorts.map((listSort) => {
                      return (
                        <Listbox.Option key={listSort.id} value={listSort.id}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? 'text-white bg-indigo-600'
                                  : 'text-gray-900'
                              } cursor-default select-none relative py-2 pl-8 pr-4`}
                            >
                              <span
                                className={`${
                                  activeListSort?.id === listSort.id ? 'font-semibold' : 'font-normal'
                                } block truncate`}
                              >
                                {listSort.title}
                              </span>
                              {activeListSort?.id === listSort.id && (
                                <span
                                  className={`${
                                    active ? 'text-white' : 'text-indigo-600'
                                  } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                >
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                )}
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export default SelectListSortDropdown;
