import React, { type FC } from 'react';
import { classNames } from '../../../utility/utility';
import { CalculatedApproach } from './calculatedApproach';

export type ApproachTableProps = {
  calculatedApproaches: CalculatedApproach[],
  selectedApproachIndex: number,
  setSelectedApproachIndex: (number) => void,
};

const ApproachTable: FC<ApproachTableProps> = ({ calculatedApproaches, selectedApproachIndex, setSelectedApproachIndex }) => {

  const renderTableRow = (approach: CalculatedApproach, index: number) => {
    const selected = index === selectedApproachIndex;
    return (
      <tr
        className={classNames(
          selected ? 'bg-indigo-600 bg-opacity-25' : 'hover:bg-gray-100',
          'py-2 hover:cursor-pointer'
        )}
        onClick={() => {setSelectedApproachIndex(index);}}
        key={approach?.approach?.id}
      >
        <td className='font-medium text-gray-900 py-2 border-b border-gray-200'>
          {approach?.approach?.name}
        </td>
        <td className='text-gray-500 py-2 border-b border-gray-200'>
          {approach?.max_bid_string}
        </td>
        <td className='text-gray-500 py-2 border-b border-gray-200'>
          {approach?.max_bid_per_sqft_string}
        </td>
        <td className='text-gray-500 py-2 border-b border-gray-200'>
          {approach?.estimated_profit_string}
        </td>
        <td className='text-gray-500 py-2 border-b border-gray-200'>
          {approach?.roi_string}
        </td>
      </tr>
    );
  };
  return (
    <div className='mx-2 rounded-lg border max-h-38 overflow-auto'>
      <table className='min-w-full divide-y divide-gray-300 text-center'>
        <thead className='divide-y divide-gray-300'>
          <tr className='sticky top-0 bg-indigo-600 text-white text-center whitespace-nowrap divide-x divide-gray-300'>
            <th className={'py-2 px-3 text-center'}>
              Name
            </th>
            <th className={'py-2 px-3 text-center'}>
              Max Bid
            </th>
            <th className={'py-2 px-3 text-center'}>
              Max Bid $/Sqft
            </th>
            <th className={'py-2 px-3 text-center'}>
              Profit
            </th>
            <th className={'py-2 px-3 text-center'}>
              ROI
            </th>
          </tr>
        </thead>
        <tbody className=''>
          {calculatedApproaches.map((approach, index) => {
            return renderTableRow(approach, index);
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ApproachTable;
