import React, { FC, useState } from 'react';
import { TitleProps } from '../utility/types';

const Title: FC<TitleProps> = ({ title, subtitle, isLoggedIn }) => {
  const [ inputValue, setInputValue ] = useState('');
  const [ isAllowedToEnter, setIsAllowedToEnter ] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsAllowedToEnter(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (inputValue.toLowerCase() === 'hotel emma') {
      setIsAllowedToEnter(true);
    }
  };
  return (
    <>
      <h1 className="font-bold text-4xl text-blue-500">{title}</h1>
      <h2 className="p-1" onClick={handleSubmit}>{subtitle}</h2>
      <div className="flex justify-center mt-2">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Where did it begin"
          className="mr-2 p-1"
        />
      </div>
      {isAllowedToEnter && (
        <div className="flex justify-center mt-2">
          <div>
            {isLoggedIn ? (
              <a href="/properties" className="ml-2 rounded-lg py-3 px-5 bg-blue-500 text-white inline-block font-medium hover:bg-slate-600">
                Go look at properties
              </a>
            ) : (
              <a href="/users/sign_in" className="ml-2 rounded-lg py-3 px-5 bg-blue-500 text-white inline-block font-medium hover:bg-slate-600">
                Go login
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Title;
