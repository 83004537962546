import { useMutation, useQueryClient } from '@tanstack/react-query';
import createSavedSearchCriteria from '../../../api/SearchCriteria/createSavedSearchCriteria';
import { useFilteringContext } from '../../../contexts/Filtering';

export type CreateSavedSearchCriteriaWrapperProps = {
  newSearchCriteriaName: string;
}
export const useCreateSavedSearchCriteria = () => {
  const queryClient = useQueryClient();
  const { ransackObj }= useFilteringContext();

  function createSavedSearchCriteriaWrapper({ newSearchCriteriaName }:CreateSavedSearchCriteriaWrapperProps) {
    const q = ransackObj['q'] || {};
    const w = ransackObj['w'] || {};
    const s = ransackObj['s'] || {};
    const m = ransackObj['m'] || {};
    const c = ransackObj['c'] || {};
    const title = newSearchCriteriaName || ransackObj['title'] || 'empty';

    // TODO: Need to add zip
    // sorting will be on the columns object

    // Perhaps we do not need to add all of these if there are no values?
    // look into how this will affect the search query in the controller in rails
    const requestBody = JSON.stringify({
      search_criteria: {
        title: title,
        // q
        min_bedrooms: q['bedrooms_gteq'] || q['bedrooms_eq'],
        max_bedrooms: q['bedrooms_lteq'] || q['bedrooms_eq'],
        min_bathrooms: q['bathrooms_gteq'] || q['bathrooms_eq'],
        max_bathrooms: q['bathrooms_lteq'] || q['bathrooms_eq'],
        min_stories: q['stories_gteq'] || q['stories_eq'],
        max_stories: q['stories_lteq'] || q['stories_eq'],
        min_acreage: q['acreage_gteq'] || q['acreage_eq'],
        max_acreage: q['acreage_lteq'] || q['acreage_eq'],
        min_initial_ltv: q['initial_ltv_gteq'] || q['initial_ltv_eq'],
        max_initial_ltv: q['initial_ltv_lteq'] || q['initial_ltv_eq'],
        min_initial_loan_value: q['initial_loan_value_gteq'] || q['initial_loan_value_eq'],
        max_initial_loan_value: q['initial_loan_value_lteq'] || q['initial_loan_value_eq'],
        min_tax_appraisal_value: q['tax_appraisal_value_gteq'] || q['tax_appraisal_value_eq'],
        max_tax_appraisal_value: q['tax_appraisal_value_lteq'] || q['tax_appraisal_value_eq'],
        min_sqft: q['sqft_gteq'] || q['sqft_eq'],
        max_sqft: q['sqft_lteq'] || q['sqft_eq'],
        min_year_built: q['year_built_gteq'] || q['year_built_eq'],
        max_year_built: q['year_built_lteq'] || q['year_built_eq'],
        min_initial_equity: q['initial_equity_gteq'] || q['initial_equity_eq'],
        max_initial_equity: q['initial_equity_lteq'] || q['initial_equity_eq'],
        min_initial_loan_takeout: q['initial_loan_takeout_gteq'] || q['initial_loan_takeout_eq'],
        max_initial_loan_takeout: q['initial_loan_takeout_lteq'] || q['initial_loan_takeout_eq'],
        min_arv_estimate_value: q['arv_estimate_value_gteq'] || q['arv_estimate_value_eq'],
        max_arv_estimate_value: q['arv_estimate_value_lteq'] || q['arv_estimate_value_eq'],
        min_list_price: q['list_price_gteq'] || q['list_price_eq'],
        max_list_price: q['list_price_lteq'] || q['list_price_eq'],
        min_list_price_per_sqft: q['list_price_per_sqft_gteq'] || q['list_price_per_sqft_eq'],
        max_list_price_per_sqft: q['list_price_per_sqft_lteq'] || q['list_price_per_sqft_eq'],
        min_garage_spaces: q['min_garage_spaces_gteq'] || q['min_garage_spaces_eq'],
        max_garage_spaces: q['max_garage_spaces_lteq'] || q['max_garage_spaces_eq'],
        min_number_of_units: q['number_of_units_gteq'] || q['number_of_units_eq'],
        max_number_of_units: q['number_of_units_lteq'] || q['number_of_units_eq'],
        min_assessed_value: q['assessed_value_gteq'] || q['assessed_value_eq'],
        max_assessed_value: q['assessed_value_lteq'] || q['assessed_value_eq'],
        min_lot_size_acres: q['lot_size_acres_gteq'] || q['lot_size_acres_eq'],
        max_lot_size_acres: q['lot_size_acres_lteq'] || q['lot_size_acres_eq'],
        min_view_count: q['view_count_gteq'] || q['view_count_eq'],
        max_view_count: q['view_count_lteq'] || q['view_count_eq'],
        min_total_tax_amount: q['total_tax_amount_gteq'] || q['total_tax_amount_eq'],
        max_total_tax_amount: q['total_tax_amount_lteq'] || q['total_tax_amount_eq'],
        min_cumulative_days_on_market: q['cumulative_days_on_market_gteq'] || q['cumulative_days_on_market_eq'],
        max_cumulative_days_on_market: q['cumulative_days_on_market_lteq'] || q['cumulative_days_on_market_eq'],
        min_last_sold_date: q['last_sold_date_gteq'] || q['last_sold_date_eq'],
        max_last_sold_date: q['last_sold_date_lteq'] || q['last_sold_date_eq'],
        min_on_market_date: q['on_market_date_gteq'] || q['on_market_date_eq'],
        max_on_market_date: q['on_market_date_lteq'] || q['on_market_date_eq'],

        // c //<--custom filters (Require special handling in the filter service)
        min_potential_equity: c['potential_equity_gteq'] || c['potential_equity_eq'],
        max_potential_equity: c['potential_equity_lteq'] || c['potential_equity_eq'],
        min_avm_result: c['min_avm_result_gteq'] || c['min_avm_result_eq'],
        max_avm_result: c['min_avm_result_lteq'] || c['min_avm_result_eq'],
        min_avm_result_per_sqft: c['min_avm_result_per_sqft_gteq'] || c['min_avm_result_per_sqft_eq'],
        max_avm_result_per_sqft: c['min_avm_result_per_sqft_lteq'] || c['min_avm_result_per_sqft_eq'],

        // w
        favorite: w['favorite'],
        wanted: w['wanted'],

        // sort: s, // <--will be on the column in REIX-273

        // strings:
        clerk_number: q['clerk_number_cont'],
        full_address: q['address_search_string_cont'],
        city: q['city_cont'],
        subdivision: q['subdivision_cont'],
        tax_id: q['tax_id_cont'],
        zillow_url: q['zillow_url_cont'],

        // add new migration REIX-273
        // state: q['state_cont'],
        // zipcode: q['zip_cont'],
        // country: q['country_cont'],

        // m's (but arent hooked up yet)
        data_source: [],
        how_sold: [],
        property_class: [],
        recent_rehab: [],
        sale_or_rent: [],

        status: m['status'],
      }
    });

    return createSavedSearchCriteria(requestBody);
  }

  const createSavedSearchCriteriaMutation = useMutation({
    mutationFn: (props: CreateSavedSearchCriteriaWrapperProps) => createSavedSearchCriteriaWrapper(props),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getSavedSearchCriteria' ] });
    },
  });

  return {
    createSavedSearchCriteria: createSavedSearchCriteriaMutation.mutateAsync,
    errorCreateSavedSearchCriteria: createSavedSearchCriteriaMutation.isError ? createSavedSearchCriteriaMutation.error : null,
    pendingCreateSavedSearchCriteria: createSavedSearchCriteriaMutation.isPending,
  };
};
