import React, { FC, useEffect, useRef } from 'react';
import { FavoriteIcon_active, FavoriteIcon_inactive, Loader } from '../icons/OurIcons';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { toggleFavoriteAsync } from '../../redux/state/favoriteSlice';

 type FavoriteToggleProps= {
    propertyId: string;
    styleClassNames?: string;
 }

const FavoriteToggle: FC<FavoriteToggleProps> = ({ propertyId, styleClassNames = '' }) => {
  // Connect react from redux using useSelector
  const isFavorite = useSelector((state: RootState) => state?.favorite?.favorites[propertyId])  ;
  // Dispatch actions because react cannot connect to redux
  const dispatch = useDispatch<AppDispatch>();
  const status = isFavorite ? true : false;
  const loading = isFavorite ? isFavorite.loading : false;
  const error = isFavorite ? isFavorite.error : null;
  const toast = useRef(null);

  const showError = () => {
    toast.current.show({ severity:'error', summary: 'Error', detail:'There was a problem favoriting this property.', life: 3000 });
  };

  useEffect(() => {
    if (error) {
      console.error('There is an error in updating the favorite status. error: ', error);
      showError();
    }
  }, [ error ]);

  if (loading) {
    return (
      <div className={`w-8 cursor-pointer ${styleClassNames} `} >
        <Loader />
      </div>);
  }

  return (
    <>
      <Toast ref={toast} />
      <div
        key={propertyId}
        className={`cursor-pointer ${styleClassNames} flex justify-center`}
        id={`favorite-toggle-${propertyId}`}
        onClick={()=>{
          dispatch(toggleFavoriteAsync({ propertyId, currentState: status }));
        }}
      >
        {isFavorite ? <FavoriteIcon_active /> : <FavoriteIcon_inactive />}
      </div>
    </>
  );
};

export default FavoriteToggle;
