import { useQuery } from '@tanstack/react-query';
import { getPropertyListUploadsProperties } from '../../../api/Property/getPropertyListUploadsProperties';

export default function useGetPropertyListUploadsProperties(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getPropertyListUploadsProperties', propertyId ],
    queryFn: () => getPropertyListUploadsProperties(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    console.error('There was an error in getPropertyListUploadsProperties ', getQuery.error.message);
  }

  return {
    listUploadsProperties: getQuery.data?.data?.list_uploads_properties ?? [],
    errorListUploadsProperties: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    refetch: getQuery.refetch
  };
}
