import { api } from '../configs/axiosConfigs';

export type GetListSortPropertiesType = {
  listSortId?: string;
  ransackObj: any;
  rows?: number;
  page?: number;
  sortMeta?: Array<{ field: string; order: 0 | 1 | -1 }>;
}

// NOTE: This route goes through the list sort controller and returns properties
export const getPropertiesForListSort = async({ listSortId, ransackObj, rows, page, sortMeta }: GetListSortPropertiesType) => {
  let urlParams = '';
  if (rows !== null  && page !== null )
    urlParams += `&per_page=${rows}&page=${page}`;
  return await api.post(`list-sorts/${listSortId}/properties?${urlParams}`, ransackObj);
};
