
import React, { type FC } from 'react';
import { PropertiesVisualizerProps } from '../utility/types';
import List from './property-list-viewer/PropertyList';
import PropertiesMap from './property-map-viewer/PropertiesMap';

const PropertiesVisualizer: FC<PropertiesVisualizerProps> = ({ isMapView, listSortId }) => {
  return (
    <div className="right-div flex-1">
      {
        isMapView ?
          <PropertiesMap listSortId={listSortId} /> :
          <List  listSortId={listSortId} />
      }
    </div>
  );
};

export default PropertiesVisualizer;
