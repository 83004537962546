import React, { useState, type FC, useEffect, SyntheticEvent } from 'react';

import { ArrowIcon, LoadingSpinner } from '../icons/OurIcons';
import placeholder from 'images/placeholder.webp';
import PropertyGalleria from '../property-detail-page/PropertyGalleria';

type UserImagesViewerProps = {
  propertyImages: any;
  galleriaRef: any;
}

const UserImagesViewer: FC<UserImagesViewerProps> = ({ propertyImages, galleriaRef }) => {
  const [ imageUrl, setImageUrl ] = useState('');
  const [ allImageUrls, setAllImageUrls ] = useState([]);
  const [ photoIndex, setPhotoIndex ] = useState(0);
  const [ showArrows, setShowArrows ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    if (propertyImages) {
      const urls = propertyImages.map(image => image?.image_url);
      setAllImageUrls(urls);
      setImageUrl(urls[0]);
      setIsLoading(false);
    }
  }, [ propertyImages ]);

  const toggleArrowDisplay = (enable: boolean) => {
    const multipleImages = allImageUrls?.length > 1;
    setShowArrows(enable && multipleImages);
  };

  const nextPhoto = (event) => {
    setIsLoading(true);
    event.stopPropagation();
    getPhoto(photoIndex + 1, 0);
    setIsLoading(false);
  };

  const prevPhoto = (event) => {
    setIsLoading(true);
    event.stopPropagation();
    getPhoto(photoIndex - 1, allImageUrls?.length - 1);
    setIsLoading(false);
  };

  const getPhoto = (index, reset) => {
    setIsLoading(true);
    let nextIndex = index;
    const imageUrl = allImageUrls[nextIndex];

    if (imageUrl) {
      setImageUrl(imageUrl);
    } else {
      nextIndex = reset;
      setImageUrl(allImageUrls[nextIndex]);
    }
    setPhotoIndex(nextIndex);
    setIsLoading(false);
  };

  if (!imageUrl) {
    return (
      <div className="relative overflow-y-hidden h-96 rounded-xl align-middle pt-36 shadow-2xl sm:px-12 lg:max-w-full lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
        You haven't uploaded any images for this property!
      </div>
    );
  }

  return (
    <div onMouseEnter={() => toggleArrowDisplay(true)} onMouseLeave={() => toggleArrowDisplay(false)}>
      <div className="relative overflow-y-hidden h-96 rounded-xl pt-64 shadow-2xl sm:px-12 lg:max-w-full lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
        {
          isLoading ?
            <div role="status">
              <LoadingSpinner />
            </div>
            : <img
              className="absolute inset-0 flex min-h-full min-w-contain"
              src={imageUrl}
              alt="Property Images"
              onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement;
                target.src = placeholder;
              }}
              onClick={() => galleriaRef.current.show()}
            />
        }
        <div className={`absolute w-8 top-24 right-5 cursor-pointer ${showArrows ? 'visible' : 'invisible'}`} onClick={nextPhoto}>
          <ArrowIcon color='white' />
        </div>
        <div className={`absolute w-8 top-24 left-5 rotate-180 cursor-pointer ${showArrows ? 'visible' : 'invisible'}`} onClick={prevPhoto}>
          <ArrowIcon color='white' />
        </div>
      </div>
      <PropertyGalleria
        ref={galleriaRef}
        propertyImages={propertyImages}
      />
    </div>
  );
};

export default UserImagesViewer;
