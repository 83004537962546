import { BulkOperationListSortPropertyJoinProps } from '../../utility/types';
import { api } from '../configs/axiosConfigs';

export const bulkWantedListSortPropertyJoin = async ({ requestBody, listSortId }: BulkOperationListSortPropertyJoinProps) => {
  return await api.post(`/list-sorts/${listSortId}/properties/bulk_wanted`, requestBody);
};

export const bulkUnwantedListSortPropertyJoin = async ({ requestBody, listSortId }: BulkOperationListSortPropertyJoinProps) => {
  return await api.post(`/list-sorts/${listSortId}/properties/bulk_unwanted`, requestBody);
};

export const bulkUndecidedListSortPropertyJoin = async ({ requestBody, listSortId }: BulkOperationListSortPropertyJoinProps) => {
  return await api.post(`/list-sorts/${listSortId}/properties/bulk_undecided`, requestBody);
};
