import heic2any from 'heic2any';
import { AvmComp, AvmCompForMap, FinalDecisionMakingProperty, PropertyDataProps } from './types';

export function getListSortIdFromUrl() {
  const pathname = window.location.pathname;
  if (!pathname.includes('list-sorts')) {
    return null; // Or handle the case where 'list-sorts' is not in the pathname
  }
  // Extract the specific part of the pathname
  const parts = pathname.split('/');    // Split the pathname by '/'
  return parts[parts.length - 1];       // Get the last part as the ID
};

export function dateConverterYYYYMMDD(origDate) {
  // Parse the original date string
  const originalDate = new Date(origDate);

  // Get year, month, and day from the parsed date
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');

  // Construct the formatted date string
  return `${year}/${month}/${day}`;
};

export function formatFloat(value: number | string | null, precision: number=2): string {
  if (value == null) return 'N/A';
  try {
    let floatValue: string;

    if (typeof value !== 'string') {
      floatValue = value.toString();
    } else {
      floatValue = value.replace(',', '');
    }

    if (!floatValue) {
      return 'N/A';
    }

    const formattedValue = parseFloat(floatValue).toFixed(precision);

    // if # is larger than NNN.NN or NNN
    // format with commas, ensure no decimals
    if (formattedValue.indexOf('.') >= 4 || (!formattedValue.includes('.') && formattedValue.length > 3)) {
      return Intl.NumberFormat('en-US').format(parseInt(formattedValue));
    }

    return formattedValue;
  } catch (error) {
    return 'Invalid input';
  }
};

// format date mm/dd/yyyy
export function formatDate(dateString: string): string {
  if (!dateString) return 'N/A';

  let newDate = new Date(dateString);
  let formattedDateString = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear();

  return formattedDateString;
};

export function checkIfPropertyIsOnMarket(value: string): string {
  switch (value) {
  case 'Active':
  case 'New':
  case 'Back on Market':
  case 'Extended':
  case 'Price Change':
  case 'Active Option':
  case 'Active RFR':
  case 'Pending':
  case 'Pending SB':
    return 'Yes';
  default:
    return 'No';
  }
};

export function getPropertyAddress(propertyDetails): string {
  return propertyDetails?.address?.street + ', ' + propertyDetails?.address?.city + ', ' + propertyDetails?.address?.state + ', ' + propertyDetails?.address?.zipcode ?? 'N/A';
};

export function getAvmTopSix(avmResult): AvmComp[] {
  return avmResult?.comps?.slice(0, 6).sort((comp1, comp2) => comp1.score_comp_value - comp2.score_comp_value);
};

export function getSoldSixAvgCdom(avmSix): string {
  let cdomArr = avmSix?.map((a) => a.property?.last_sold_listing?.cumulative_days_on_market).filter((a) => a.property?.last_sold_listing?.cumulative_days_on_market !== null);
  let avgCdom = cdomArr ? (cdomArr.reduce((accumulator, currentVal) => accumulator + currentVal) / 6).toFixed(0) : 'N/A';
  return avgCdom;
};

export function getActiveSixAvgCdom(avmSix): string {
  let cdomArr = avmSix?.map((a) => a.property?.cumulative_days_on_market).filter((a) => a !== null);
  let avgCdom = cdomArr ? (cdomArr.reduce((accumulator, currentVal) => accumulator + currentVal) / 6).toFixed(0) : 'N/A';
  return avgCdom;
};

export function getFifteenAvgScoreComp(avmFifteen): string {
  let compsArr = avmFifteen?.map((a) => a?.score_comp_value).filter((a) => a != null);
  let avgScoreComp = compsArr.length > 0 ? (compsArr.reduce((accumulator, currentVal) => accumulator + currentVal) / 15).toFixed(0) : 'N/A';
  return avgScoreComp;
};

export function getFifteenAvgCdom(avmFifteen): string {
  let cdomArr = avmFifteen?.map((a) => a.property?.last_sold_listing?.cumulative_days_on_market).filter((cdom) => cdom != null);
  let avgCdom = cdomArr && cdomArr.length > 0 ? (cdomArr.reduce((accumulator, currentVal) => accumulator + currentVal) / 15).toFixed(0) : 'N/A';
  return avgCdom;
};

// Return array of property image objects
export const getPropertyImages = (property) => {
  const propertyImages = [ ...property?.most_recent_property_listing_images ];
  if (property?.street_view_photo?.image_url) {
    propertyImages.push(property.street_view_photo);
  }
  return propertyImages;
};

// Return array of image urls
export const getPropertyImagesUrls = (property) => {
  return getPropertyImages(property).map(image => image.image_url);
};

export const getPricePerSqFt = (property): number => {
  let pricePerSqft = null;

  if (property.list_price_per_sqft) {
    pricePerSqft = property.list_price_per_sqft;
  }
  else if (property.list_price && property.sqft) {
    pricePerSqft = (property.list_price / property.sqft).toFixed(2);
  }

  return pricePerSqft;
};

export const isPropertyActive = (property: PropertyDataProps) => {
  return !!property && (property?.status !== 'Expired' && property?.status !== 'Cancelled' && property?.status !== 'Sold');
};

export const formatPropertiesForFDMTable = (properties: any[]): FinalDecisionMakingProperty[] => {
  return properties.map((property, index) => ({
    propertyId: property.property_id,
    customListSortNumber: property.order || index + 1,
    maxBid: `$${formatFloat(property.max_bid)}`,
    repairEstimate: `$${formatFloat(property.fix_costs)}`,
    arvSelected: `$${formatFloat(property.estimated_value)}`,
    propertyListingId: property.id,
    profitOnMaxBid: `$${formatFloat(property.estimated_profit)}`,
    roiPercent: `${formatFloat(property.roi)}%`,
    approachName: property.approach?.name,
    address: property.property.address?.full_address,
    sqFt: formatFloat(property.property.sqft, 0),
    yearBuilt: property.property.year_built,
    bedrooms: property.property.bedrooms,
    bathrooms: property.property.bathrooms,
    acreage: formatFloat(property.property.lot_size_acres, 3),
  }));
};

const convertToMapComps = (comps: AvmComp[], isActive: boolean): AvmCompForMap[] => {
  if (!comps) return [];
  return comps.map(comp => {
    const mapComp = comp as AvmCompForMap;
    mapComp.isActive = isActive;
    return mapComp;
  });
};

export const getAvmCompsForMap = (activeComps: AvmComp[], inactiveComps: AvmComp[]): AvmCompForMap[] => {
  const mapInactiveComps = convertToMapComps(inactiveComps, false);
  const mapActiveComps = convertToMapComps(activeComps, true);
  return mapInactiveComps.concat(mapActiveComps);
};

// Helper function for converHeicToJpeg() to resize image using canvas
const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      // Calculate new dimensions while maintaining aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height = Math.floor((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.floor((width * maxHeight) / height);
          height = maxHeight;
        }
      }

      // Set the canvas to the new dimensions
      canvas.width = width;
      canvas.height = height;

      // Draw the image on canvas with the new dimensions
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Canvas is empty'));
          }
        }, 'image/jpeg', 0.8); // Set image quality to 80%
      } else {
        reject(new Error('Canvas context not available'));
      }
    };

    img.onerror = (err) => {
      reject(err);
    };
  });
}; 

export const convertHeicToJpeg = async (file: File): Promise<File> => {
  try {
    const convertedBlob = await heic2any({
      blob: file,
      toType: 'image/jpeg',
    });

    const convertedFile = new File(
      [ convertedBlob as Blob ],
      file.name.replace('.HEIC', '.jpg').replace('.heic', '.jpg'),
      { type: 'image/jpeg' }
    );

    const resizedBlob = await resizeImage(convertedFile, 800, 800);
    const resizedFile = new File([ resizedBlob ], convertedFile.name, {
      type: 'image/jpeg',
    });

    return resizedFile;
  } catch (error) {
    console.error('Error converting HEIC to JPEG:', error);
    throw error;
  }
};

/**
 * Formats a number to a string with two decimal places and commas for thousands.
 * @param {number | undefined} num - The number to be formatted.
 * @param {string} prefix - Prefix to add before the number (e.g., '$').
 * @param {string} suffix - Suffix to add after the number (e.g., '%').
 * @returns {string} - The formatted string.
 */
// function formatNumber(num: number | undefined, prefix = '', suffix = ''): string {
//   if (num === undefined) {
//     return prefix + '0.00' + suffix;  // Default to '0.00' if the number is undefined.
//   }
//   const rounded = num.toFixed(2); // Ensure two decimal places.
//   const parts = rounded.split('.');
//   const integerPart = parseInt(parts[0], 10).toLocaleString(); // Add commas.
//   return `${prefix}${integerPart}.${parts[1]}${suffix}`;
// }
