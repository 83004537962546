// FilteringContext.tsx
import React, { createContext, useContext, useState, useEffect, useReducer, useMemo } from 'react';
import TieredRangeSelectorComponent from '../components/propertySearch/Components/filters/TieredRangeSelectorComponent';
import DualValueRangeCard from '../components/propertySearch/Components/filters/DualValueRangeCard';
import MultiStateSelectorComponent from '../components/propertySearch/Components/filters/MultiStateSelectorComponent';
import RangeSliderComponent from '../components/common/RangeSliderComponent';
import BooleanToggleComponent from '../components/propertySearch/Components/filters/BooleanToggleComponent';
import SearchStringComponent from '../components/propertySearch/Components/filters/SearchStringComponent';
import { getDefaultFilters } from '../api/SearchCriteria/getDefaultFilters';
import BooleanTriStateSelectorComponent from '../components/propertySearch/Components/filters/BooleanTriStateSelectorComponent';
import WantedMultiSelector from '../components/propertySearch/WantedMultiSelector';
import FavoriteTriStateSelector  from '../components/propertySearch/FavoriteTriStateSelector';
import { generateRansackObject } from './ransackGenerator';
import DateRangeSelector from '../components/propertySearch/DateRangeSelector';
import { WantedMultiSelectorProps, BooleanTriStateSelectorComponentProps, MultiStateSelectSelectorComponentProps, BooleanToggleComponentProps, DateRangeSelectorProps, FavoriteTriStateSelectorProps, RangeSelectorComponentProps, SearchStringComponentProps } from '../components/propertySearch/Components/filters/utility';
import { convertCriteriaToFilterDescriptor } from './convertCriteriaToFilterDescriptor';
import { TableColumn } from '../redux/state/columnsSlice';
import { deleteKeyValueFromSessionStorage, getKeyValueFromSessionStorage, setKeyValueInSessionStorage } from '../utility/utility';

interface NumericRangeSelectorValuesType {
  min: number;
  max: number;
  step: number;
}

export interface DateRangeSelectorValuesType {
  startDate: string;
  endDate: string;
}

export interface RangeValuesType {
  min: number;
  max: number;
}

export interface msOptionInterface {
  name: string;
  code: string;
  selectedLabelName?: string;
  valueString?: string;
}
export interface TieredRangeSelectorValuesType extends RangeValuesType {}
export interface RangeInputSelectorValuesType extends RangeValuesType {}
export interface RangeSliderSelectorValuesType extends RangeValuesType {}
export interface BooleanTriStateSelectorValuesType {  state: true | false | null; }
export interface MultiSelectorValuesType { state: msOptionInterface[]; }
export interface StringSelectorValuesType { query: string; }
export interface BooleanToggleValuesType { state: boolean; }

// Above are all of the possible "values" types that a filter can have
export type FilterValuesType = TieredRangeSelectorValuesType | RangeInputSelectorValuesType | RangeSliderSelectorValuesType | BooleanTriStateSelectorValuesType | MultiSelectorValuesType | StringSelectorValuesType | BooleanToggleValuesType | DateRangeSelectorValuesType;

export interface FilterMetadata {
  unit?: string;
  triStateFilterMeta?:
  {
    icons?: {                   // Customizable icons for different states
      active: JSX.Element;
      inactive: JSX.Element;
      indeterminate: JSX.Element;
    }
  }
  multiSelectStateMeta?: {
    maxSelectedLabels: number;
    options: { name: string, code: string }[];
  }
}

export type FilterDescriptor = {
  options: any;
  id: string; //to identify the filter in sql
  title: string; // Title to display for the filter
  description?: string; // Optional description of the filter to display
  filter_type: keyof typeof FilterType ;
  values: FilterValuesType;
  defaultValues: FilterValuesType; // default values for this filter
  metadata: FilterMetadata;
  ransack_key: string;
  unit?: string;
  unit_type?: string;
};

// creates a mapping of string constants for the filter types
export const FilterType = {
  BooleanToggleFilterType: 'booleanToggle',
  StringSelectorFilterType: 'stringSelector',
  RangeInputSelectorFilterType: 'rangeInputSelector',
  TieredRangeSelectorFilterType: 'tieredRangeSelector',
  DateRangeSelectorFilterType: 'dateRangeSelector',
  MultiSelectorFilterType: 'multiSelector',

  RangeSliderSelectorFilterType: 'rangeSliderSelector',
  BooleanTriStateSelectorFilterType: 'booleanTriStateSelector',
  WantedMultiSelectorFilterType: 'wantedMultiSelector',
  FavoriteTriStateSelectorFilterType: 'favoriteTriStateSelector',
} as const;

// This registry maps each filter type (defined in FilterTypes) to a specific React component that is designed to handle that filter's UI
export const FilterComponentRegistry: Record<keyof typeof FilterType, React.ComponentType<any>> = {
  TieredRangeSelectorFilterType: TieredRangeSelectorComponent as React.ComponentType<RangeSelectorComponentProps>,
  RangeInputSelectorFilterType: DualValueRangeCard as React.ComponentType<RangeSelectorComponentProps>,
  RangeSliderSelectorFilterType: RangeSliderComponent as React.ComponentType<RangeSelectorComponentProps>,
  BooleanTriStateSelectorFilterType: BooleanTriStateSelectorComponent as React.ComponentType<BooleanTriStateSelectorComponentProps>,
  MultiSelectorFilterType: MultiStateSelectorComponent as React.ComponentType<MultiStateSelectSelectorComponentProps>,
  StringSelectorFilterType: SearchStringComponent as React.ComponentType<SearchStringComponentProps>,
  BooleanToggleFilterType: BooleanToggleComponent as React.ComponentType<BooleanToggleComponentProps>,
  WantedMultiSelectorFilterType: WantedMultiSelector as React.ComponentType<WantedMultiSelectorProps>,
  FavoriteTriStateSelectorFilterType: FavoriteTriStateSelector as React.ComponentType<FavoriteTriStateSelectorProps>,
  DateRangeSelectorFilterType: DateRangeSelector as React.ComponentType<DateRangeSelectorProps>,
};

/**
 *
 * The purpose of this context is to handle the filter parameters. When a filter is changed, this context handles the generation
 * of the search string in the property context so it can make the calls to get the updated properties from the db
 *
 */
const initialState = {
  filters: {}, // Initial empty state
  activeFilterIds: new Set<string>(),
};

type Action =
  | { type: 'UPDATE_FILTER'; id: string; values: Partial<FilterValuesType> }
  | { type: 'LOAD_FILTERS_FROM_DB'; payload: Record<string, FilterDescriptor> }
  | { type: 'SET_INITIAL_FILTERS_FROM_SESSION'; payload: Record<string, FilterDescriptor> }
  | { type: 'RESET_FILTERS' }
  | { type: 'LOAD_CRITERIA_FROM_SAVED'; criteria: Record<string, any> };

// Define the state type
interface State {
  filters: Record<string, FilterDescriptor>;
  activeFilterIds: Set<string>;
}

function isFilterActive(currentValues: FilterValuesType, defaultValues: FilterValuesType): boolean {
  return JSON.stringify(currentValues) !== JSON.stringify(defaultValues);
}

// Reducer function to handle state changes
function filterReducer(state: State, action: Action): State {
  switch (action.type) {
  case 'LOAD_FILTERS_FROM_DB': {
    return {
      ...state,
      filters: { ...action.payload, ...state.filters }
    };
  };
  case 'LOAD_CRITERIA_FROM_SAVED':
  {
    let nextFilters =Object.keys(state.filters).reduce((acc, filterId) => {
      acc[filterId] = {
        ...state.filters[filterId],
        values: state.filters[filterId].defaultValues,
      };
      return acc;
    }, {} as Record<string, FilterDescriptor>);

    const { newActiveFilterIds: newActiveFilterIds3, newFilters } = convertCriteriaToFilterDescriptor(action.criteria, nextFilters);
    const newActiveFilterIds2 = new Set(newActiveFilterIds3);
    sessionStorage.setItem('table-filter-settings-storage', JSON.stringify({ filters: newFilters }));

    return {
      ...state,
      filters: newFilters,
      activeFilterIds: newActiveFilterIds2,
    };
  }
  case 'SET_INITIAL_FILTERS_FROM_SESSION':
    return {
      activeFilterIds: new Set(Object.keys(action.payload)),
      filters: { ...state.filters, ...action.payload }
    };
  case 'UPDATE_FILTER':
    {
      if (!state.filters[action.id]) {
        return state;
      }
      const updatedValues = { ...state.filters[action.id].values, ...action.values };
      const filterIsActive = isFilterActive(updatedValues, state.filters[action.id].defaultValues);
      const newActiveFilterIds = new Set(state.activeFilterIds);
      if (filterIsActive) {
        newActiveFilterIds.add(action.id);
      } else {
        newActiveFilterIds.delete(action.id);
      }

      // Filter out non-active filters and prepare for saving to session storage
      let saveFilters = Array.from(newActiveFilterIds);
      let newSaveFilters: Record<string, FilterDescriptor> = {};
      if (saveFilters.length > 0) {
        for (let entry of saveFilters) {
          if (entry === action.id) {
            newSaveFilters[entry] = {
              ...state.filters[entry],
              values: updatedValues,
            };
          }
          else {
            newSaveFilters[entry] = state.filters[entry];
          }
        }
      }
      sessionStorage.setItem('table-filter-settings-storage', JSON.stringify({ filters: newSaveFilters }));

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: {
            ...state.filters[action.id],
            values: updatedValues,
          },
        },
        activeFilterIds: newActiveFilterIds,
      };
    };
  case 'RESET_FILTERS': {
    sessionStorage.setItem('table-filter-settings-storage', JSON.stringify({ filters: {} }));
    return {
      ...state,
      filters: Object.keys(state.filters).reduce((acc, filterId) => {
        acc[filterId] = {
          ...state.filters[filterId],
          values: state.filters[filterId].defaultValues,
        };
        return acc;
      }, {} as Record<string, FilterDescriptor>),
      activeFilterIds: new Set(),
    };
  }
  default:
    throw new Error('Unhandled action type');
  }
}

// Look at making this more granular https://chat.openai.com/c/e356740e-a849-4fa7-8d65-b122eae97e09 "but these are gointo be dyamically created"

type SortingType = { field: string; direction: 'asc' | 'desc'| null } | null;
const FilteringContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>
  isFilteringActive: boolean;
  appliedFiltersString: string;
  ransackObj: any;
  handleSort: (col: TableColumn | null | SortingType) => void;
  sorting: SortingType;
  lassoPolygons: any;
  setLassoPolygons: (polygon) => void;
  activeFilters: Set<string>;
    } | undefined>(undefined);

// Context Provider Component
export const FilteringProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ state, dispatch ] = useReducer(filterReducer, initialState);
  const [ isFilteringActive, setIsFilteringActive ] = useState(true);
  const [ appliedFiltersString, setAppliedFiltersString ] = useState('');
  const [ ransackObj, setRansackObj ] = useState<any>({ state: 'unset' });
  const [ sorting, setSorting ] = useState<SortingType>(null);
  const [ lassoPolygons, setLassoPolygons ] = useState(null);
  const [ initialFiltersRetrievedFromDB, setInitialFiltersRetrievedFromDB ] = useState(false);

  useEffect(() => {
    const storedState = sessionStorage.getItem('table-filter-settings-storage');

    const fetchFilters = async () => {
      const initialFilters = await getDefaultFilters();
      dispatch({ type: 'LOAD_FILTERS_FROM_DB', payload: initialFilters });

      setInitialFiltersRetrievedFromDB(true);
    };

    fetchFilters();
    //  Helen TODO REIX-273 TODO: save string to cache and set appliedFiltersString if needed
    if (storedState) {
      const parsedState = JSON.parse(storedState) as { filters: Record<string, FilterDescriptor> };
      if (parsedState.filters) {
        const filters = Object.values(parsedState.filters);
        const saveFilters: Record<string, FilterDescriptor> = filters?.reduce((acc: Record<string, FilterDescriptor>, filter: FilterDescriptor) => {
          if (JSON.stringify(filter.values) !== JSON.stringify(filter.defaultValues)) {
            acc[filter.id] = filter;
          }
          return acc;
        }, {} as Record<string, FilterDescriptor>);

        dispatch({ type: 'SET_INITIAL_FILTERS_FROM_SESSION', payload: saveFilters });
      }
    }
    const sorting = getKeyValueFromSessionStorage('DM-settings-storage', 'sorting');
    if (sorting) {
      handleSort(sorting);
    }
  }, []);

  const handleSort = (col: SortingType) => {
    if (!col || !col.field) {
      deleteKeyValueFromSessionStorage('DM-settings-storage', 'sorting');
      setSorting(null);
      return;
    }

    if (col.field === 'fullAddress') {
      return;
    }

    if (col.field === 'removeSort') {
      deleteKeyValueFromSessionStorage('DM-settings-storage', 'sorting');
      setSorting(null);
      return;
    }

    if (col.direction) {
      setKeyValueInSessionStorage('DM-settings-storage', 'sorting', JSON.stringify({ field: col.field, direction: col.direction }));
      setSorting({ field: col.field, direction: col.direction });
      return;
    }
    // if we dont have a direction, we need to toggle the sorting
    if (sorting && sorting.field === col.field) {
      if (sorting.direction === 'asc') {
        setKeyValueInSessionStorage('DM-settings-storage', 'sorting', JSON.stringify({ field: col.field, direction: 'desc' }));
        setSorting({ field: col.field, direction: 'desc' });
      } else {
        deleteKeyValueFromSessionStorage('DM-settings-storage', 'sorting');
        setSorting(null);
      }
    } else {
      setKeyValueInSessionStorage('DM-settings-storage', 'sorting', JSON.stringify({ field: col.field, direction: 'desc' }));
      setSorting({ field: col.field, direction: 'asc' });
    }
  };

  const value = useMemo(() => {
    let ransackLocal = {};
    if (initialFiltersRetrievedFromDB) {
      ransackLocal = generateRansackObject(state.filters, sorting, lassoPolygons);
      setRansackObj(ransackLocal);
    }
    else {
      ransackLocal = { state: 'unset' };
    }

    return (
      { state,
        dispatch,
        isFilteringActive: state.activeFilterIds.size > 0,
        appliedFiltersString,
        ransackObj: ransackLocal,
        handleSort,
        sorting,
        lassoPolygons,
        setLassoPolygons,
        activeFilters: state.activeFilterIds,
      });
  }, [ state, dispatch, isFilteringActive, appliedFiltersString, sorting, initialFiltersRetrievedFromDB ]);

  return <FilteringContext.Provider value={value}>{children}</FilteringContext.Provider>;
};

export const useFilteringContext = () => {
  const context = useContext(FilteringContext);
  if (!context) {
    throw new Error('useFilteringContext must be used within a FilteringProvider');
  }
  return context;
};

