export type ApproachCondition = {
  id: string,
  comparison_attribute: string,
  comparison_type: string,
  comparison_value: number,
  order: number
}

export class ComparisonConditions {
  propertyDetails?: any;
  fixedCosts?: number;
  avmResults?: any;

  constructor(propertyDetails?: any, fixedCosts?: number, avmResults?: any) {
    this.propertyDetails = propertyDetails;
    this.fixedCosts = fixedCosts;
    this.avmResults = avmResults;
  }

  comparisonAttributes: Record<string, { display: string, objectParse: () => number }> = {
    'bedrooms': { display: 'Bedrooms', objectParse: () => this.propertyDetails?.bedrooms },
    'bathrooms': { display: 'Bathrooms', objectParse: () => this.propertyDetails?.bathrooms },
    'sqft': { display: 'Sqft', objectParse: () => this.propertyDetails?.sqft },
    'stories': { display: 'Stories', objectParse: () => this.propertyDetails?.stories },
    'year built': { display: 'Year built', objectParse: () => this.propertyDetails?.year_built },
    'garage spaces': { display: 'Garage spaces', objectParse: () => this.propertyDetails?.garage_spaces },
    'lot size in acres': { display: 'Lot size in acres', objectParse: () => this.propertyDetails?.lot_size_acres },
    'fixed costs': { display: 'Fixed costs', objectParse: () => this.fixedCosts },
    'estimated value': { display: 'Estimated value', objectParse: () => this.avmResults?.result?.estimated_value },
    'potential equity': { display: 'Potential equity', objectParse: () => this.avmResults?.result?.potential_equity },
    'avm confidence value': { display: 'AVM confidence value', objectParse: () => this.avmResults?.result?.confidence_value }
  };

  comparisonTypes: Record<string, { display: string, compareValue: (propertyValue: number, comparisonValue: number) => boolean }> = {
    '<': { display: '< (Less than)', compareValue: (propertyValue: number, comparisonValue: number): boolean => propertyValue < comparisonValue },
    '<=': { display: '<= (Less than or equal to)', compareValue: (propertyValue: number, comparisonValue: number): boolean => propertyValue <= comparisonValue },
    '=': { display: '= (Equal to)', compareValue: (propertyValue: number, comparisonValue: number): boolean => propertyValue == comparisonValue },
    '>=': { display: '>= (Greater than or equal to)', compareValue: (propertyValue: number, comparisonValue: number): boolean => propertyValue >= comparisonValue },
    '>': { display: '> (Greater than)', compareValue: (propertyValue: number, comparisonValue: number): boolean => propertyValue > comparisonValue }
  };

  filterApplicableApproaches(approaches: any[]): any[] {
    if (!this.propertyDetails || !this.avmResults || this.fixedCosts == null) {
      throw new ReferenceError('The following values need to be defined: propertyDetails, avmResults, fixedCosts');
    }
    const applicableApproaches = [];

    for (var approach of approaches) {
      // It's a normal approach!
      if (approach.approach_conditions.length == 0) {
        applicableApproaches.push(approach);
        continue;
      }

      // It's a conditional approach!
      const property_fits_all_conditions = this.property_fits_all_conditions(approach.approach_conditions);
      if (property_fits_all_conditions) {
        applicableApproaches.push(approach);
      }
    }

    return applicableApproaches;
  }

  private property_fits_all_conditions(conditions: ApproachCondition[]): boolean {
    for (var condition of conditions) {
      const property_fits_condition = this.property_fits_condition(condition);
      if (!property_fits_condition) {
        return false;
      }
    }
    return true;
  }

  private property_fits_condition(condition: ApproachCondition): boolean {
    const comparisonAttributeKey = condition.comparison_attribute;
    const comparisonAttribute = this.comparisonAttributes[comparisonAttributeKey];
    const comparisonTypeKey = condition.comparison_type;
    const comparisonType = this.comparisonTypes[comparisonTypeKey];
    const propertyValue = comparisonAttribute.objectParse();
    const comparisonValue = condition.comparison_value;

    return comparisonType.compareValue(propertyValue, comparisonValue);
  }
}
