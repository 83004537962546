import React, { type FC, useState, useEffect } from 'react';
import ValuationsTable from './ValuationsTable';
import ValuationsUserInput from './ValuationsUserInput';
import { LoadingSpinner } from '../../icons/OurIcons';

type ValuationsContainerProps = {
  propertyDetails: any;
  soldAvmResult: any;
  activeAvmResult: any;
  soldCompsSix: any[];
  isLoading: boolean;
  savedValuationsRow: string;
  savedArvEstimate?: number;
  savedArvSqft?: number;
  savedUserArvEstimate?: number;
  savedUserArvSqft?: number;
  onEstimatedValueChange: (
    estimatedValue: number,
    estimatedValuePerSqft: number,
    activeRow: string,
    userEstimated: number,
    userEstimatedSqft: number
  ) => void;
}

const ValuationsContainer: FC<ValuationsContainerProps> = ({
  propertyDetails,
  soldAvmResult,
  activeAvmResult,
  isLoading,
  soldCompsSix,
  onEstimatedValueChange,
  savedArvEstimate,
  savedArvSqft,
  savedValuationsRow,
  savedUserArvEstimate,
  savedUserArvSqft
}) => {
  const [ selectedInput, setSelectedInput ] = useState('');
  const [ inputValue, setInputValue ] = useState<number>(null);
  const [ arvEstimate, setArvEstimate ] = useState<number>(null);
  const [ arvSqft, setArvSqft ] = useState<number>(null);
  const [ userEstimate, setUserEstimate ] = useState<number>(null);
  const [ userEstimateSqft, setUserEstimateSqft ] = useState<number>(null);
  const [ activeValuationsRow, setActiveValuationsRow ] = useState<string | null>('');

  useEffect(() => {
    if (savedArvEstimate && savedArvSqft) {
      const parsedSavedArvEstimate = isNaN(Number(savedArvEstimate)) ? null : Number(savedArvEstimate);
      const parsedSavedArvSqft = isNaN(Number(savedArvSqft)) ? null : Number(savedArvSqft);
      setArvEstimate(parsedSavedArvEstimate);
      setArvSqft(parsedSavedArvSqft);
    } else {
      setArvEstimate(soldAvmResult?.result?.estimated_value);
      setArvSqft(soldAvmResult?.result?.estimated_dollar_per_sqft);
    }

    if (savedUserArvEstimate) {
      setUserEstimate(savedUserArvEstimate);
      setUserEstimateSqft(savedUserArvSqft);
    } else {
      setUserEstimate(null);
      setUserEstimateSqft(null);
      setInputValue(null);
    }

    if (savedValuationsRow) {
      setActiveValuationsRow(savedValuationsRow);
    } else {
      setActiveValuationsRow('');
    }

    setSelectedInput('');
  }, [ savedArvEstimate, savedArvSqft, isLoading, savedValuationsRow, savedUserArvEstimate, savedUserArvSqft ]);

  useEffect(() => {
    onEstimatedValueChange(
      arvEstimate,
      arvSqft,
      activeValuationsRow,
      userEstimate,
      userEstimateSqft
    );
  }, [ arvEstimate, arvSqft, activeValuationsRow, userEstimate, userEstimateSqft ]);

  const ValuationsContent = () => {
    if (isLoading) {
      return (
        <div className='flex items-center justify-center h-96'>
          <LoadingSpinner />
        </div>
      );
    }
    // Need better loading and error states here. Ask hannah for help.
    if (!soldAvmResult) {
      return (
        <div className='flex items-center justify-center h-96'>
          <div className='text-center'>No valuation data available</div>
        </div>
      );
    }
    return (
      <div>
        <div className='rounded-xl mx-auto mt-3'>
          <ValuationsTable
            propertyDetails={propertyDetails}
            soldAvmResult={soldAvmResult}
            activeAvmResult={activeAvmResult}
            selectedInput={selectedInput}
            inputValue={inputValue}
            soldCompsSix={soldCompsSix}
            setArvEstimate={setArvEstimate}
            setArvSqft={setArvSqft}
            activeRow={activeValuationsRow}
            setActiveRow={setActiveValuationsRow}
            userEstimate={userEstimate}
            userEstimateSqft={userEstimateSqft}
            setUserEstimateSqft={setUserEstimateSqft}
            setUserEstimate={setUserEstimate}
          />
        </div>
        <ValuationsUserInput
          propertyDetails={propertyDetails}
          soldAvmResult={soldAvmResult}
          selectedInput={selectedInput}
          setSelectedInput={setSelectedInput}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setArvEstimate={setArvEstimate}
          setArvSqft={setArvSqft}
          arvEstimate={arvEstimate}
          arvSqft={arvSqft}
          setActiveRow={setActiveValuationsRow}
          setUserEstimate={setUserEstimate}
          setUserEstimateSqft={setUserEstimateSqft}
        />
      </div>
    );
  };

  return (
    <div className='w-full content-center justify-content-center'>
      <div>
        <h3 className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left m-3'>Valuations</h3>
        <hr className='mt-3 mb-4 mx-3' />
      </div>
      <ValuationsContent />
    </div>
  );
};

export default ValuationsContainer;
