import React, { type FC } from 'react';
import { formatFloat, getActiveSixAvgCdom } from '../../../../utility/utility_functions';

export type ActiveCompsAveragesProps = {
  activeAvmResult: any;
  compsSixActive: Array<object>;
}

const lineItemsStyling = 'text-xs pb-3 font-extrabold';
const smallTxtStyling = 'text-xs pt-1 pb-2 whitespace-nowrap';
const avmGridContainerStyling = 'text-center mt-2';
const avmGridStyling = 'grid grid-cols-2 gap-x-5 text-center pt-2';
const avmGridHeaderStyling = 'whitespace-nowrap text-extrabold text-md text-indigo-500';
const avmGridElStyling = 'flex justify-center items-center border-2 rounded-xl mt-2 mb-3';

const ActiveCompsAverages: FC<ActiveCompsAveragesProps> = ({ activeAvmResult, compsSixActive }) => {

  return (
    <div className='shadow-lg pt-5 px-5 rounded-xl'>
      <div>
        <div className='flex justify-between items-center'>
          <h3 className={avmGridHeaderStyling}>Active Comp Averages</h3>
        </div>
        <hr className='mt-3' />
      </div>
      <div className={avmGridContainerStyling}>
        <div className={avmGridStyling}>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>List Price</h6>
              <div className={lineItemsStyling}>${formatFloat(activeAvmResult?.result?.closest_avg_sold_price, 0)}</div>
            </div>
          </div>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>$/Sq Ft</h6>
              <div className={lineItemsStyling}>${formatFloat(activeAvmResult?.result?.closest_avg_dollar_per_sqft, 0)}</div>
            </div>
          </div>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>Score Comp</h6>
              <div className={lineItemsStyling}>{activeAvmResult?.result?.confidence_value ?? 'N/A'}</div>
            </div>
          </div>
          <div className={avmGridElStyling}>
            <div className='pt-2'>
              <h6 className={smallTxtStyling}>CDOM</h6>
              <div className={lineItemsStyling}>{getActiveSixAvgCdom(compsSixActive)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ActiveCompsAverages;
