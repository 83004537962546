import { FC, useEffect, useState } from 'react';
import { useFavoriteApi } from '../../hooks/api/Favorite/useFavoriteApi';
import { FavoriteIcon_active, FavoriteIcon_inactive } from '../icons/OurIcons';
import React from 'react';

type FavoriteIconProps = {
  propertyDetails: any
}

const FavoriteIcon: FC<FavoriteIconProps> = ({ propertyDetails }) => {
  const [ favorited, setFavorited ] = useState(false);

  useEffect(() => {
    if (propertyDetails) {
      const favoriteLength= propertyDetails?.property_favorite_flag?.length ?? 0;
      setFavorited(favoriteLength > 0);
    }
  }, [ propertyDetails ]);

  const { deleteFavorite, createFavorite, errorFavorite, pendingFavorite, responseFavorite } = useFavoriteApi();

  const toggleFavorite = () => {
    if (favorited) {
      deleteFavorite(propertyDetails?.id);
      setFavorited(false);
    }
    else {
      createFavorite(propertyDetails?.id);
      setFavorited(true);
    }
  };

  if (!propertyDetails) {
    return;
  }

  return (
    <>
      <span className="absolute w-10 cursor-pointer pl-3" onClick={toggleFavorite}>
        {favorited ? <FavoriteIcon_active /> : <FavoriteIcon_inactive />}
      </span>
    </>
  );
};

export default FavoriteIcon;
