import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteApproach } from '../../../api/Approaches/approaches';

export function useDeleteApproach() {
  const queryClient = useQueryClient();

  const deleteApproachMutation = useMutation({
    mutationFn: (approachId: string) => deleteApproach(approachId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getApproaches' ] });
    },
  });

  return deleteApproachMutation;
}
