import React, { FC } from 'react';
import { CalculatedApproach } from './calculatedApproach';

export type ApproachViewerProps = {
  selectedApproach: CalculatedApproach
}

const ApproachViewer: FC<ApproachViewerProps> = ({ selectedApproach }) => {
  const renderGridItem = (title: string, value: string) => {
    return (
      <div className='flex justify-center items-center border-2 rounded-xl p-3 mb-5'>
        <div>
          <p className='text-xs'>{title}</p>
          <p className='pt-1 text-base font-bold'>{value ?? null}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <h3 className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left m-3 overflow-x-clip'>
        Approach Selected: {selectedApproach?.approach.name ?? 'None'}
      </h3>
      <div className='grid grid-cols-2 mx-5 gap-x-5 text-center'>
        {renderGridItem('Max Bid', selectedApproach?.max_bid_string ?? '-')}
        {renderGridItem('Max Bid $/Sqft', selectedApproach?.max_bid_per_sqft_string ?? '-')}
        {renderGridItem('Estimated Profit', selectedApproach?.estimated_profit_string ?? '-')}
        {renderGridItem('ROI', selectedApproach?.roi_string ?? '-')}
      </div>
    </>
  );
};

export default ApproachViewer;
