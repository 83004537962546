import { useQuery } from '@tanstack/react-query';
import { getListSorts } from '../../../api/ListSort/getListSorts';

export function useGetListSorts() {
  const getQuery = useQuery({
    queryKey: [ 'getListSorts' ],
    queryFn: () => getListSorts()
  });

  if (getQuery.isError) {
    console.error('There was an error in getTotalProperties ', getQuery);
  }

  return {
    data: getQuery.data?.data || null, // Access response.data
    error: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    isPending: getQuery.isPending,
    refetch: getQuery.refetch
  };
}
