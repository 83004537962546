import React, { type FC } from 'react';

const NoListSortsModal: FC = () => {

  return (
    <div className='w-contain text-center my-auto px-10 py-7 bg-white rounded-xl shadow-[5px_5px_30px_20px_rgba(0,0,0,0.1)]'>
      <div className='justify-content-center gap-2'>
        <span className='font-bold text-xl white-space-nowrap'>No List Sorts Exist</span>
      </div>
      <p className='pt-5'>A list sort must be created before you can make decisions!</p>
      <div className='mt-7'>
        <a href='/list-sorts' className='rounded bg-indigo-600 px-2 py-1 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
          Go to List Sorts
        </a>
      </div>
    </div>
  );
};

export default NoListSortsModal;