import { FC, useEffect, useState } from 'react';
import placeholder from 'images/placeholder.webp';
import React from 'react';
import FavoriteToggle from '../FavoriteToggle';
import { ArrowIcon } from '../../icons/OurIcons';

type PropertyQuickViewModalBodyProps = {
  propertyImages: any[];
  propertyId: string;
  handleClose?: () => void;
};

const PropertyQuickViewModalBody: FC<PropertyQuickViewModalBodyProps> = ({ propertyImages, propertyId, handleClose }) => {
  const [ image, setImage ] = useState(null);
  const [ photoIndex, setPhotoIndex ] = useState(0);

  useEffect(() => {
    setImage(propertyImages[0] || { image_url: placeholder });
    setPhotoIndex(0);
  }, [ propertyImages ]);

  const nextPhoto = (event) => {
    event.stopPropagation();
    getPhoto(photoIndex + 1, 0);
  };

  const prevPhoto = (event) => {
    event.stopPropagation();
    getPhoto(photoIndex - 1, propertyImages.length - 1);
  };

  const getPhoto = (index, reset) => {
    let nextIndex = index;
    const propertyImage = propertyImages[nextIndex];

    if (propertyImage) {
      setImage(propertyImage);
    } else {
      nextIndex = reset;
      setImage(propertyImages[nextIndex] || { image_url: placeholder });
    }
    setPhotoIndex(nextIndex);
  };

  return (
    <div id='image-and-control-container' className="relative mx-auto max-w-3xl rounded-t-lg overflow-hidden">
      <div className='bg-gray-100 flex justify-center'>
        <img src={image?.image_url} className="w-80 max-h-80 rounded-t-lg cursor-pointer"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = e.target as HTMLImageElement;
            target.src = placeholder;
          }}
          onClick={() => window.open(`/properties/${propertyId}`, '_blank')}
        />
      </div>

      {handleClose && (
        <button id='mini-modal-popup-close-button-container'
          className="absolute left-0 top-0 m-2 cursor-pointer"
          onClick={(event) => { event.stopPropagation(); handleClose(); }}>
          X
        </button>
      )}
      {
        image?.date && <p className={'absolute bottom-3 right-3 text-xl text-white bg-slate-700/60'}>{image.date}</p>
      }

      <div id='mini-modal-popup-favorite-toggle-container' className="absolute right-0 top-0 m-1 cursor-pointer">
        <FavoriteToggle propertyId={propertyId} />
      </div>

      <div id='picture-left-arrow-container' className="absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer">
        <div className='w-8 rotate-180' onClick={prevPhoto}>
          <ArrowIcon className="stroke-[0.5] hover:stroke-[.75]" />
        </div>
      </div>

      <div id='picture-right-arrow-container' className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer">
        <div className='w-8' onClick={nextPhoto}>
          <ArrowIcon className="stroke-[0.5] hover:stroke-[.75]" />
        </div>
      </div>
    </div>
  );
};

export default PropertyQuickViewModalBody;