import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ListSortProps } from '../../utility/types';
import { createListSortPropertyJoin } from '../../api/ListSortPropertyJoin/createListSortPropertyJoin';
import { updateListSortPropertyJoin } from '../../api/ListSortPropertyJoin/updateListSortPropertyJoin';
import { deleteListSortPropertyJoin } from '../../api/ListSortPropertyJoin/deleteListSortPropertyJoin';
import { getListSortPropertyJoinForListSort } from '../../api/ListSortPropertyJoin/getListSortPropertyJoinForListSort';
import { bulkUndecidedListSortPropertyJoin, bulkUnwantedListSortPropertyJoin, bulkWantedListSortPropertyJoin } from '../../api/ListSortPropertyJoin/bulkListSortPropertyJoin';
import { AxiosResponse } from 'axios';

export interface LspjDataProps {
    status: boolean;
    loading: boolean;
    error: string | null;
    meta?: {
        id: string;
        notes: string;
    };
    approach_id?: string;
    valuations_option?: string;
    estimated_value?: number;
    estimated_value_per_sqft?: number;
    fix_costs?: number;
    max_bid?: number;
    max_bid_per_sqft?: number;
    estimated_profit?: number;
    roi?: number;
    user_estimated_value?: number;
    user_estimated_value_sqft?: number;
    user_fixed_costs?: number;
}
interface LspjState {
  listSortId: string | null;
  lspjs: {
    [propertyId: string]: LspjDataProps
  };
  error: string | null;
  loading: boolean;
  total_sorted_property: number;
  total_unwanted_property_joins: number;
  total_wanted_property_joins: number;
  listSort: ListSortProps | null;
}

interface UpdateDecisionsProps {
  listSortId: string;
  propertyId: string;
  list_sort_property_join: {
    wanted: boolean;
    notes: string;
    approach_id: string;
    valuations_option: string;
    estimated_value: number;
    estimated_value_per_sqft: number;
    fix_costs: number;
    max_bid: number;
    max_bid_per_sqft: number;
    estimated_profit: number;
    roi: number;
    user_estimated_value: number;
    user_estimated_value_sqft: number;
    user_fixed_costs: number;
    status: boolean;
    loading: boolean;
    error: string;
    meta?: {
      id: string;
      notes: string;
    };
  };
}

const initialState: LspjState = {
  listSortId: null,
  lspjs: {},
  error: null,
  loading: false,
  total_sorted_property: 0,
  total_unwanted_property_joins: 0,
  total_wanted_property_joins: 0,
  listSort: null,
};

const lspjSlice = createSlice({
  name: 'lspj',
  initialState,
  reducers: {
    clearLspjs: (state) => {
      state.lspjs = {};
      state.listSortId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLspjs.pending, (state, action) => {
      state.loading = true;
    })
      .addCase(fetchLspjs.fulfilled, (state, action) => {
        state.loading = false;
        const lspjs = Object.keys(action.payload?.list_sort_property_joins || {}).reduce((acc, key) => {
          const item = action.payload.list_sort_property_joins[key];
          acc[item.property_id] = {
            status: item.wanted,
            loading: false,
            error: null,
            meta: {
              id: item.id,
              notes: item.notes
            },
            approach_id: item.approach_id,
            valuations_option: item.valuations_option,
            estimated_value: item.estimated_value,
            estimated_value_per_sqft: item.estimated_value_per_sqft,
            fix_costs: item.fix_costs,
            max_bid: item.max_bid,
            max_bid_per_sqft: item.max_bid_per_sqft,
            estimated_profit: item.estimated_profit,
            roi: item.roi,
            user_estimated_value: item.user_estimated_value,
            user_estimated_value_sqft: item.user_estimated_value_sqft,
            user_fixed_costs: item.user_fixed_costs
          };
          return acc;
        }, {});
        state.lspjs = lspjs;
        state.listSortId = action.payload?.list_sort.id;
        state.listSort = action.payload?.list_sort;
        state.total_sorted_property = action.payload?.total_sorted_property;
        state.total_unwanted_property_joins = action.payload?.total_unwanted_property_joins;
        state.total_wanted_property_joins = action.payload?.total_wanted_property_joins;
      })
      .addCase(fetchLspjs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch lspjs';
        console.error('Failed to fetch lspjs', action.error);
      })
      .addCase(toggleLspjAsync.pending, (state, action) => {
        if (!state.lspjs[action.meta.arg]) {
          state.lspjs[action.meta.arg] = {
            loading: true,
            status: null,
            error: null,
            meta: {
              id: action.meta.arg,
              notes: '',
            }
          };
        }
        else {
          state.lspjs[action.meta.arg].loading = true;
          state.lspjs[action.meta.arg].error = null;
        }
      })
      .addCase(toggleLspjAsync.fulfilled, (state, action) => {
        const actionType = action.payload?.action;
        if (actionType === 'create') {
          state.lspjs[action.meta.arg].status = action.payload?.listSortPropertyJoin.wanted;
          state.lspjs[action.meta.arg].loading = false;
          state.lspjs[action.meta.arg].error = null;
        }
        else if (actionType === 'update') {
          state.lspjs[action.meta.arg].status = action.payload?.listSortPropertyJoin.wanted;
          state.lspjs[action.meta.arg].loading = false;
          state.lspjs[action.meta.arg].error = null;
        }
        else if (actionType === 'destroy') {
          delete state.lspjs[action.meta.arg];
        }
      })
      .addCase(toggleLspjAsync.rejected, (state, action) => {
        state.lspjs[action.meta.arg].loading = false;
        state.lspjs[action.meta.arg].error = action.error.message || 'Failed to toggle lspj';
      })
      .addCase(toggleBulkLspjs.fulfilled, (state, action) => {
        const properties = action.meta.arg.propertyIds;
        if (action.payload?.new_status === 'undecided') {
          properties.forEach(propertyId => delete state.lspjs[propertyId]);
        }
        else {
          properties.forEach(propertyId => {
            state.lspjs[propertyId] = {
              loading: false,
              status: action.payload?.new_status === 'wanted' ? true : false,
              error: null,
              meta: {
                id: propertyId,
                notes: 'todo',
              }
            };
          });
        }
      })
      .addCase(toggleBulkLspjs.rejected, (state, action) => {
        const properties = action.meta.arg.propertyIds;
        properties.forEach(propertyId => {
          state.lspjs[propertyId] = {
            loading: false,
            status: null,
            error: action.error.message || 'Failed to toggle bulk lspjs',
            meta: {
              id: propertyId,
              notes: 'todo',
            }
          };
        });
        state.error = action.error.message || 'Failed to toggle bulk lspjs';
      })
      .addCase(toggleBulkLspjs.pending, (state, action) => {
        const properties = action.meta.arg.propertyIds;
        properties.forEach(propertyId => {
          state.lspjs[propertyId] = {
            loading: true,
            status: null,
            error: null,
            meta: {
              id: propertyId,
              notes: 'todo',
            }
          };
        });
      })
      .addCase(updateDecisionsAsync.pending, (state, action) => {
        const response = action.meta.arg.list_sort_property_join;
        const propertyId = action.meta.arg.propertyId;
        if (state.lspjs[propertyId]) {
          state.lspjs[propertyId] = {
            loading: true,
            status: response.wanted,
            error: null
          };
        }
      })
      .addCase(updateDecisionsAsync.fulfilled, (state, action) => {
        const { propertyId, list_sort_property_join } = action.meta.arg;
        state.lspjs[propertyId] = {
          ...state.lspjs[propertyId],
          meta: {
            ...list_sort_property_join.meta,
          },
          ...list_sort_property_join,
          loading: false,
          error: null,
        };
      })
      .addCase(updateDecisionsAsync.rejected, (state, action) => {
        const propertyId = action.meta.arg.propertyId;
        state.lspjs[propertyId].loading = false;
        state.lspjs[propertyId].error = action.error.message || 'Failed to update property join decisions';
      });
  }
});

export const fetchLspjs = createAsyncThunk(
  'lspj/fetchLspj',
  async (listSortId: string, { getState }) => {
    if (!listSortId) {
      return;
    }
    const response = await getListSortPropertyJoinForListSort(listSortId);
    return response.data;
  });

export const toggleBulkLspjs = createAsyncThunk(
  'lspj/toggleBulkLspjs',
  async ({ listSortId, propertyIds, action }: {listSortId: string, propertyIds: string[], action: 'wanted' | 'not_wanted' | 'delete'}, { getState }) => {
    let response;
    if (action === 'wanted') {
      response = await bulkWantedListSortPropertyJoin({ listSortId, requestBody: {
        list_sort_property_join: {
          propertyIds: propertyIds
        }
      } });
    }
    if (action === 'not_wanted') {
      response = await bulkUnwantedListSortPropertyJoin({ listSortId, requestBody: {
        list_sort_property_join: {
          propertyIds: propertyIds
        }
      } });
    }
    if (action === 'delete') {
      response = await bulkUndecidedListSortPropertyJoin({ listSortId, requestBody: {
        list_sort_property_join: {
          propertyIds: propertyIds
        }
      } });
    }
    return response.data;
  }
);

export const toggleLspjAsync = createAsyncThunk(
  'lspj/toggle',
  async (propertyId: string, { getState }) => {
    const allState: any = getState();
    const state = allState?.lspj as LspjState;
    const { listSortId, lspjs } = state;
    if (!listSortId || !lspjs) {
      console.error('listSortId or lspjs not found');
      throw new Error('listSortId or lspjs not found');
    }
    const propId = propertyId;
    const currentState = lspjs[propId]?.status;

    let newStatus;
    if (currentState === true) {
      newStatus = false;
    } else if (currentState === false) {
      newStatus = null;
    } else {
      newStatus = true;
    }

    try {
      let response;
      if (newStatus === true) {
        response = await createListSortPropertyJoin({ listSortId, propertyId: propId, requestBody: {
          list_sort_property_join: {
            wanted: true,
            notes: '',
          } } });
      } else if (newStatus === false) {
        // If our currentWantedStatus is not null, and our new status is not null, we need a patch/put/update
        response = await updateListSortPropertyJoin({ listSortId, propertyId: propId, requestBody: {
          list_sort_property_join: {
            wanted: false,
            notes: '',
          }
        } });
        return response.data;
      } else if (newStatus === null){
        // If our newStatus is null, then we need a delete request
        response = await deleteListSortPropertyJoin({ listSortId, propertyId: propId });
      } else {
        console.error('\tWe are here by mistake, return');
        return;
      }
      return response.data;
    }
    catch (e) {
      console.error('ERROR with listsortpropertyjoin calls ', e);
    }
  }
);

export const updateDecisionsAsync = createAsyncThunk(
  'lspj/updateDecisions',
  async ({ listSortId, propertyId, list_sort_property_join }: UpdateDecisionsProps, { getState }) => {
    try {
      const allState: any = getState();
      const state = allState?.lspj as LspjState;
      const currentState = state?.lspjs[propertyId];

      const mergedData = {
        ...currentState,
        ...list_sort_property_join,
      };

      const requestBody = {
        list_sort_property_join: {
          ...mergedData,
        },
      };

      let response;

      if (currentState) {
        response = await updateListSortPropertyJoin({
          listSortId,
          propertyId,
          requestBody
        });
      } else {
        response = await createListSortPropertyJoin({
          listSortId,
          propertyId,
          requestBody,
        });
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export default lspjSlice.reducer;
function getState() {
  throw new Error('Function not implemented.');
}

