
import React from 'react';
import { useFilteringContext, FilterDescriptor, StringSelectorValuesType } from '../../contexts/Filtering';
import { Button } from 'primereact/button';

import FilterFactory from '../propertySearch/Components/filters/FilterFactory';
import FullAddressSearch from '../propertySearch/Components/FullAddressSearch';

const Header = ({ }) => {
  const { state, dispatch, isFilteringActive } = useFilteringContext();

  return (
    <div id="property-list-viewer-header" className="flex justify-between items-center h-18">
      {/* <FilterFactory id={id} filterData={filter} /> */}
      <FullAddressSearch />
      <Button
        type="button"
        disabled={!isFilteringActive}
        label="Clear Filters"
        onClick={() => dispatch({ type: 'RESET_FILTERS' })}
        className={` rounded text-sm font-semibold text-white shadow-sm ${
          isFilteringActive
            ? 'hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-70 border-transparent'
        }`}
      />
    </div>
  );
};

export default Header;
