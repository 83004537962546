import React, { type FC } from 'react';
import { checkIfPropertyIsOnMarket, formatFloat, getPricePerSqFt, getPropertyAddress } from '../../utility/utility_functions';
import { LoadingSpinner } from '../icons/OurIcons';

type PropertySummaryProps = {
  propertyData: any;
  listSort?: any;
}

const lineItemsStyling = 'pt-2 pb-3 text-sm font-bold';
const smallTxtStyling = 'text-xs whitespace-nowrap';

const PropertySummary: FC<PropertySummaryProps> = ({ propertyData, listSort = null }) => {

  if (!propertyData) {
    return (
      <div role="status">
        <LoadingSpinner />
      </div>
    );
  }

  const getCustomListId = (): string => {
    const listUploadId = listSort?.list_upload_id;
    if (!listUploadId) {
      return 'N/A';
    }
    const allCustomListUploadData = propertyData.list_uploads_properties_data;
    const listUploadDataForThisList = allCustomListUploadData.filter((listUploadData) => listUploadData.list_upload_id == listUploadId ).at(0);
    return listUploadDataForThisList?.auction_number ?? 'N/A';
  };

  return (
    <div className='px-4 pb-7 rounded-xl mb-3 overflow-y-scroll'>
      <div>
        <div className='flex justify-between mt-3 items-center'>
          <h3 className='whitespace-nowrap text-extrabold text-lg text-indigo-500'>Property Summary</h3>
          {propertyData && propertyData.id &&
            <a
              href={`/properties/${propertyData?.id}`}
              target='_blank'
              className="rounded whitespace-nowrap bg-indigo-600 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              View Property Details
            </a>
          }
        </div>
        <hr className='mt-3' />
        <p className='text-xs mt-5 font-bold text-center'>{getPropertyAddress(propertyData)}</p>
      </div>
      <div className='grid grid-cols-3 gap-x-6 pt-5 rounded-xl text-center m-auto justify-center'>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Currently on Market</h6>
          <li className={lineItemsStyling}>{checkIfPropertyIsOnMarket(propertyData?.status)}</li>
          <hr />
          <h6 className={smallTxtStyling}>Year Build</h6>
          <li className={lineItemsStyling}>{propertyData?.year_built ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Neighborhood</h6>
          <li className={lineItemsStyling}>{propertyData?.subdivision ?? 'N/A'}</li>
        </ul>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Sq Ft</h6>
          <li className={lineItemsStyling}>{propertyData?.sqft ? formatFloat(propertyData?.sqft, 0) : 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Beds</h6>
          <li className={lineItemsStyling}>{propertyData?.bedrooms ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Baths</h6>
          <li className={lineItemsStyling}>{propertyData?.bathrooms ?? 'N/A'}</li>
        </ul>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Stories</h6>
          <li className={lineItemsStyling}>{propertyData?.stories ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Garage</h6>
          <li className={lineItemsStyling}>{propertyData?.garage_spaces ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Acres</h6>
          <li className={lineItemsStyling}>{propertyData?.lot_size_acres ?? 'N/A'}</li>
        </ul>
      </div>
      <ul className='grid grid-cols-3 gap-x-6 text-center'>
        <div>
          <hr/>
          <h6 className={smallTxtStyling}>Custom List ID</h6>
          <li className={lineItemsStyling}>{getCustomListId()}</li>
        </div>
        {checkIfPropertyIsOnMarket(propertyData?.status) === 'Yes' && 
          <div>
            <hr />
            <h6 className={smallTxtStyling}>List Price</h6>
            <li className={lineItemsStyling}>{propertyData?.list_price ? `$${formatFloat(propertyData?.list_price, 0)}` : '-'}
            </li>
          </div>
        }
        {checkIfPropertyIsOnMarket(propertyData?.status) === 'Yes' && 
          <div>
            <hr />
            <h6 className={smallTxtStyling}>List Price $/Sq Ft</h6>
            <li className={lineItemsStyling}>{getPricePerSqFt(propertyData) ? `$${formatFloat(getPricePerSqFt(propertyData), 2)}` : '-'}</li>
          </div>
        }
      </ul>
    </div>
  );
};

export default PropertySummary;
