import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateApproach, approachProps } from '../../../api/Approaches/approaches';

export type UpdateApproachMutationProps = {
  approachId: string,
  requestBody: approachProps
}

export function useUpdateApproach() {
  const queryClient = useQueryClient();

  const updateApproachMutationFunction = (approachId: string, body: approachProps) => {
    return updateApproach(approachId, body);
  };

  const updateApproachMutation = useMutation({
    mutationFn: (props: UpdateApproachMutationProps) => updateApproachMutationFunction(props.approachId, props.requestBody),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getApproaches' ] });
    },
  });

  return updateApproachMutation;
}

