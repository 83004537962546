import React, { type FC } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getPropertyAddress } from '../../../utility/utility_functions';
import QuickNetSheetTable from './QuickNetSheetTable';
import { IconX } from '../../icons/OurIcons';

export type QuickNetSheetModalProps = {
  isOpen: boolean;
  propertyDetails: any;
  decisionFormData: any;
  setModalOpen: (boolean) => void;
  handleSubmitDecision: () => void;
};

const QuickNetSheetModal: FC<QuickNetSheetModalProps> = ({ isOpen, propertyDetails, decisionFormData, setModalOpen, handleSubmitDecision }) => {

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-50'
        onClose={setModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed inset-0 z-10 w-full overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='justify-center relative transform overflow-auto rounded-lg bg-white px-7 pb-4 pt-5 m-5 shadow-xl transition-all w-full md:w-1/2 2xl:w-1/3'>
                <div>
                  <div className='float-right end-0 right-0 cursor-pointer' onClick={() => {
                    setModalOpen(false);
                  }}>
                    <IconX  />
                  </div>
                  <div>
                    <h3 className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left'>Quick Net Sheet</h3>
                    <hr className='mt-3 mb-4' />
                  </div>
                  <p className='text-sm mt-5 text-center font-bold'>{getPropertyAddress(propertyDetails)}</p>
                </div>
                <div className='w-contain mt-5 flex flex-col items-center mx-10'>
                  <div>
                    <QuickNetSheetTable
                      propertyDetails={propertyDetails}
                      decisionFormData={decisionFormData}
                    />
                  </div>
                </div>
                <div className='flex justify-center items-center mt-6'>
                  <button type='submit' onClick={handleSubmitDecision} className='rounded bg-indigo-600 py-1 font-semibold text-white w-16 h-8 shadow-md hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                    OK
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default QuickNetSheetModal;
