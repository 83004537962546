import React, { type FC } from 'react';
import { formatFloat, checkIfPropertyIsOnMarket, getPropertyAddress } from '../../../../utility/utility_functions';

export type PropertySummaryProps = {
  propertyDetails: any;
  soldAvmResult: any;
}

const lineItemsStyling = 'text-sm pb-3 font-extrabold';
const smallTxtStyling = 'text-xs pt-1 pb-2 whitespace-nowrap';
const avmGridHeaderStyling = 'whitespace-nowrap text-extrabold text-md text-indigo-500';

const PropertySummary: FC<PropertySummaryProps> = ({ propertyDetails, soldAvmResult }) => {

  const getPoolDetails = (): string => {
    return propertyDetails?.pool?.includes('INGRN') || propertyDetails?.pool?.includes('POOL') ? 'Yes' : 'No';
  };

  return (
    <div className='shadow-lg pt-5 px-5 rounded-xl'>
      <div>
        <div className='flex justify-between items-center md:text-center'>
          <h3 className={avmGridHeaderStyling}>Property Summary</h3>
        </div>
        <hr className='mt-3' />
        <p className='text-xs my-5 font-bold text-center'>{getPropertyAddress(propertyDetails)}</p>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-x-8 rounded-xl text-center'>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Currently on Market</h6>
          <li className={lineItemsStyling}>{checkIfPropertyIsOnMarket(propertyDetails?.status)}</li>
          <hr />
          <h6 className={smallTxtStyling}>Last MLS Number</h6>
          <li className={lineItemsStyling}>{propertyDetails?.last_mls_id ?? 'No MLS history'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Year Build</h6>
          <li className={lineItemsStyling}>{propertyDetails?.year_built ?? 'N/A'}</li>
        </ul>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Estimated $ / Sq Ft</h6>
          <li className={lineItemsStyling}>{soldAvmResult?.result?.estimated_dollar_per_sqft ? `${'$'}` + formatFloat(soldAvmResult?.result?.estimated_dollar_per_sqft, 2) : 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Estimated Value</h6>
          <li className={lineItemsStyling}>{soldAvmResult?.result?.estimated_value ? `${'$'}` + formatFloat(soldAvmResult?.result?.estimated_value, 0) : 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Tax Assessment</h6>
          <li className={lineItemsStyling}>N/A</li>
        </ul>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Sq Ft</h6>
          <li className={lineItemsStyling}>{propertyDetails?.sqft ? formatFloat(propertyDetails?.sqft, 0) : 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Beds</h6>
          <li className={lineItemsStyling}>{propertyDetails?.bedrooms ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Baths</h6>
          <li className={lineItemsStyling}>{propertyDetails?.bathrooms ?? 'N/A'}</li>
        </ul>
        <ul>
          <hr />
          <h6 className={smallTxtStyling}>Stories</h6>
          <li className={lineItemsStyling}>{propertyDetails?.stories ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Acres</h6>
          <li className={lineItemsStyling}>{propertyDetails?.lot_size_acres ?? 'N/A'}</li>
          <hr />
          <h6 className={smallTxtStyling}>Pool</h6>
          <li className={lineItemsStyling}>{getPoolDetails()}</li>
        </ul>
      </div>
    </div>
  );
};
export default PropertySummary;
