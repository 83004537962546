import { formatFloat } from '../../../utility/utility_functions';

export class CalculatedApproach {
  static SYSTEM_CALCULATION_KEY = 'System Calculation';

  priorityLeins: number;
  proratedTaxes: number;
  max_bid: number;
  max_bid_per_sqft: number;
  estimated_profit: number;
  roi: number;
  approach: any;
  fixedCosts: number;
  estimatedValue: number;
  totalCosts: number;
  userProvidedValuation?: number;
  userProvidedFixedCosts?: number;

  constructor(approach: any, propertyDetails: any, fixedCosts: number, estimatedValue: number) {
    // Be careful with order, these all depend on approach
    this.approach = approach;

    this.estimatedValue = estimatedValue;
    this.fixedCosts = fixedCosts;
    this.priorityLeins = this.calculate_priority_leins(propertyDetails);
    this.proratedTaxes = this.calculate_prorated_taxes(propertyDetails);
    this.totalCosts = this.calculate_total_costs(this.fixedCosts, this.priorityLeins, this.proratedTaxes);

    this.max_bid = this.calculate_max_bid(this.estimatedValue, this.totalCosts);
    this.max_bid_per_sqft = this.calculate_max_bid_per_sqft(propertyDetails, this.max_bid);
    this.estimated_profit = this.calculate_estimated_profit(this.estimatedValue, this.max_bid, this.totalCosts);
    this.roi = this.calculate_roi(this.estimated_profit, this.max_bid, this.totalCosts);
  }

  formatDollarString(dollarValue: number, precision = 2) {
    return `\$${formatFloat(dollarValue, precision)}`;
  };

  get max_bid_string(): string {
    return this.formatDollarString(this.max_bid);
  }

  get max_bid_per_sqft_string(): string {
    return this.formatDollarString(this.max_bid_per_sqft);
  }

  get estimated_profit_string(): string {
    return this.formatDollarString(this.estimated_profit);
  }

  get roi_string(): string {
    return `${this.roi.toFixed(1)}%`;
  }

  calculate_total_costs(fixedCosts: number, priorityLeins: number, proratedTaxes: number): number {
    return fixedCosts + priorityLeins + proratedTaxes;
  }

  calculate_priority_leins(propertyDetails: any): number {
    if (this.approach.priority_liens_calculation === CalculatedApproach.SYSTEM_CALCULATION_KEY) {
      // TODO: Need to figure out how to get priority liens data
      return 0;
    }
    return 0;
  }

  calculate_prorated_taxes(propertyDetails: any): number {
    // TODO: need to calculate prorated taxes
    // ((Tax Value * Annual Tax Rate) / 12) * (Number of past tax months + number of prorated tax months)
    return 0;
  }

  calculate_max_bid(estimatedValue: number, totalCosts: number): number {
    const avm_minus_sales_cost = estimatedValue * (1 - this.approach.sale_cost_percent);
    const avm_purchase_percent_of_value = estimatedValue * (1 - this.approach.purchase_percent_of_value);
    return avm_minus_sales_cost - avm_purchase_percent_of_value - totalCosts;
  }

  calculate_max_bid_per_sqft(propertyDetails: any, max_bid: number): number {
    return max_bid / propertyDetails.sqft;
  }

  calculate_estimated_profit(estimatedValue: number, maxBid: number, totalCosts: number): number {
    const avm_minus_sales_cost = estimatedValue * (1 - this.approach.sale_cost_percent);
    return avm_minus_sales_cost - maxBid -totalCosts;
  }

  calculate_roi(estimatedProfit: number, maxBid: number, totalCosts: number): number {
    const denominator = maxBid + totalCosts;
    if (denominator == 0) return 0;
    return (estimatedProfit / denominator) * 100;
  }
}
