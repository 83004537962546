// // src/redux/reducers/favoriteReducer.ts
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TOGGLE_FAVORITE } from '../actions/types';
import { RootState } from '../store';
import { createFavorite } from '../../api/Favorite/createFavorite';
import { deleteFavorite } from '../../api/Favorite/deleteFavorite';
import { fetchFavorites } from '../../api/Favorite/fetchFavorites';

interface FavoriteState {
  favorites: { [propertyId: string]: {
    status: boolean;  //should always be true if it exists in the favorites slice
    loading: boolean;
    error: string | null;
  } },
  error: string | null;
}

const initialState: FavoriteState = { favorites: {}, error: null };

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(toggleFavoriteAsync.pending, (state, action) => {
      const propertyId: string = action.meta.arg.propertyId;
      state.favorites[propertyId] = { status: false, loading: true, error: null };
    })
      .addCase(toggleFavoriteAsync.fulfilled, (state, action) => {
        const propertyId: string = action.meta.arg.propertyId;
        const actionType = action.payload?.action;
        if (actionType === 'delete') {
          delete state.favorites[propertyId];
        } else {
          state.favorites[propertyId] = { status: true, loading: false, error: null };
        }
        state.error = null;
      })
      .addCase(toggleFavoriteAsync.rejected, (state, action) => {
        const propertyId: string = action.meta.arg.propertyId;
        state.favorites[propertyId] = { ...state.favorites[propertyId], loading: false, error: action.error.message || 'Failed to toggle favorite status' };
        state.error = action.error.message || 'Failed to toggle favorite status';
      })
      .addCase(fetchFavoritesThunk.pending, (state) => {
        // Do we need this because we are awaiting the fetchFavoritesThunk
        state.error = null; // Optionally reset or set loading state
      })
      .addCase(fetchFavoritesThunk.fulfilled, (state, action) => {
        action.payload?.property_ids.forEach(favorite => {
          state.favorites[favorite] = { status: true, loading: false, error: null };
        });
      })
      .addCase(fetchFavoritesThunk.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch favorites';
      });
  }
});
export const toggleFavoriteAsync = createAsyncThunk(
  'favorite/toggle',
  async ({ propertyId, currentState }: { propertyId: string; currentState: boolean }, { getState }) => {
    try {
      let response;
      if (currentState) {
        response = await deleteFavorite(propertyId);
      } else {
        response = await createFavorite(propertyId);
      }
      return response.data; // Return the propertyId on successful deletion
    } catch (error) {
      throw new Error(error.message || 'Failed to toggle favorite status');
    }
  }
);

export const fetchFavoritesThunk = createAsyncThunk('favorite/fetch', async () => {
  try {
    const response = await fetchFavorites();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch favorites');
  }
});

export default favoriteSlice.reducer;
