import { useMutation } from '@tanstack/react-query';
import { UseCreateListSortPropertyJoinProps, CreateListSortPropertyJoinProps, WantedStatusPayloadType2 } from '../../../utility/types';
import { createListSortPropertyJoin } from '../../../api/ListSortPropertyJoin/createListSortPropertyJoin';

export const useCreateListSortPropertyJoin = () => {
  const createListSortPropertyJoinMutationFunction = ({ listSortId, propertyId, status }: UseCreateListSortPropertyJoinProps) => {
    const requestBody: WantedStatusPayloadType2 = {
      list_sort_property_join: {
        wanted: status,
        notes: 'none',
      } };
    return  createListSortPropertyJoin({ requestBody, listSortId, propertyId });
  };

  const createListSortPropertyJoinMutation = useMutation({
    mutationFn: (props: UseCreateListSortPropertyJoinProps) => createListSortPropertyJoinMutationFunction(props)
  });

  return createListSortPropertyJoinMutation;
};
