import React, { useEffect, useState, type FC } from 'react';
import BasicTable from '../common/BasicTable';
import { formatAddress } from '../../utility/address';
import LoadingTablePlaceholder from '../common/LoadingTablePlaceholder';
import { LinkedPropertyMapperProps } from '../../utility/types';

const LinkedPropertiesTable: FC<LinkedPropertyMapperProps> = ({ listUploadId, basePath }) => {
  const [ loading, setLoading ] = useState(true);
  const [ properties, setProperties ] = useState([]);
  // Need to migrate this one
  useEffect(() => {
    fetch(`${basePath}/${listUploadId}/properties`)
      .then(res => res.json())
      .then(
        (result) => {
          setProperties(result);
          setLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
  }, []);

  const formatProperties = (): {}[] => {
    return properties?.map(property => {
      let lastUpdated;
      if (property.last_sync_datetime) {
        lastUpdated = new Date(property.last_sync_datetime);
      }

      return {
        'Address': formatAddress(property.address),
        'Status': property.status,
        'Last updated': lastUpdated ?? null
      };
    });
  };

  const formattedProperties = formatProperties();
  if (loading) {
    return <LoadingTablePlaceholder />;
  }
  return <BasicTable tableData={formattedProperties} />;
};

export default LinkedPropertiesTable;
